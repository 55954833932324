import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import * as moment from 'moment';
import Snack from "../../../components/Snack/Snack";
import MaterialTable, {MTableToolbar} from "material-table";
import axios from 'axios';
import {API_URL, PUBLIC_URL, tableIcons} from "../../../config";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {Add, Edit, AssistantPhoto} from "@material-ui/icons";
import {useSelector} from "react-redux";
import RefreshIcon from '@material-ui/icons/Refresh';
import {KeyboardDatePicker} from "@material-ui/pickers";
import IconButton from "@material-ui/core/IconButton";
import {numberWithCommas} from "../../../helpers/helpers";
import FilterRow from "../../../components/MaterialTable/FilterRow";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginBottom: 30
    },
    toolbar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    action: {
        marginRight: 8
    },
    progressBar: {
        width: '100%'
    }
}));

const PurchaseIndex = ({ history }) => {

    const classes = useStyles();

    const authState = useSelector((state) => state.authReducer);

    const [startAt, setStartAt] = useState(null);
    const [startAtHasError, setStartAtHasError] = useState(false);
    const [startAtError, setStartAtError] = useState('');

    const [endAt, setEndAt] = useState(null);
    const [endAtHasError, setEndAtHasError] = useState(false);
    const [endAtError, setEndAtError] = useState('');

    const [totalCost, setTotalCost] = useState('');

    // State de tabla
    const [selected, setSelected] = useState([]);

    // State de snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(true);

    // State de diálogo
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);

    let [columnObject] = useState([
        {
            title: 'Fecha de Compra',
            field: 'purchased_at',
            defaultSort: 'desc',
            filtering: false,
            render: rowData => {
                if(rowData.purchased_at)
                    return (<div style={{minWidth: '100px'}}>  {moment(rowData.purchased_at).format('D/MMM/YY')}  </div>)
                else
                    return '';
            }
        },
        {
            title: 'Paciente',
            field: 'name',
            render: rowData =>
                (<div style={{minWidth: '250px'}}>  {rowData.name}  </div>)
        },
        {
            title: 'Producto',
            field: 'medicine',
            render: rowData =>
                (<div style={{minWidth: '200px'}}>  {rowData.medicine}  </div>)
        },
        {
            title: 'Cantidad',
            field: 'amount',
            filtering: false
        },
        {
            title: 'Costo',
            field: 'price',
            filtering: false,
            render: rowData => {
                return '$' + rowData.price;
            }
        },
        {
            title: 'Importe',
            field: 'total',
            type: 'total',
            render: rowData => {
                return '$' + rowData.total;
            }
        },
        {
            title: 'Dólares',
            field: 'dollars',
            filtering: false,
            render: rowData => {
                return '$' + rowData.dollars;
            }
        },
        {
            title: 'Pesos',
            field: 'pesos',
            filtering: false,
            render: rowData => {
                return '$' + rowData.pesos;
            }
        }
    ]);

    const tableRef = useRef(null);

    // Función que se ejecuta al enviar pulsar el botón de desactivar
    const handleDeactivate = async () => {

        // Esconder el diálogo
        setIsDialogOpen(false);

        // Mostrar el indicador de carga
        setIsLoading(true);

        try {

            // Total de seleccionados
            let count = selected.length;

            // Contadores para mensaje
            let deactivatedCount = 0;

            // Por cada categoría seleccionada
            for(let i = 0; i < count; i++){

                // Cambiar estado
                await axios.delete(`${API_URL}/purchases/${selected[i].id}`);

                deactivatedCount += 1;
            }

            if(deactivatedCount > 1){
                // Mostrar mensaje
                setSnackbarMessage(`${deactivatedCount} compras eliminadas exitosamente`);
                setSnackbarOpen(true);
            } else {
                // Mostrar mensaje
                setSnackbarMessage(`1 compra eliminada exitosamente`);
                setSnackbarOpen(true);
            }

            setSelected([]);

            tableRef.current.onQueryChange();

        } catch (error) {

            // Mostrar mensaje
            setSnackbarMessage('Ocurrió un error eliminando las compras.');
            setSnackbarOpen(true);
        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    const loadTableData = (query) => {

        return new Promise(async (resolve, reject) => {

            setIsLoading(true);

            let url = `${API_URL}/purchases?`;
            url += 'per_page=' + query.pageSize;
            url += '&page=' + (query.page + 1);

            if(query.orderBy && query.orderDirection){
                url += '&sort=' + query.orderBy.field;
                url += '&direction=' + query.orderDirection;
            }

            if(startAt)
                url += `&startAt=${startAt}`;

            if(endAt)
                url += `&endAt=${endAt}`;

            if(query.filters){
                for(let i = 0; i < query.filters.length; i++){
                    url += `&${query.filters[i].column.field}=${query.filters[i].value}`;
                }
            }

            if(query.search)
                url += '&search=' + query.search;

            try {

                const response = await axios.get(url);

                setTotalCost(response.data.total_cost);

                resolve({
                    data: response.data.collection.data,
                    page: response.data.collection.current_page - 1,
                    totalCount: response.data.collection.total
                });

            } catch(error){
                reject(new Error('Ocurrió un error cargando las compras.'))
            }

            setIsLoading(false);
        }).catch((error) => {

            // Mostrar mensaje
            setSnackbarMessage('Ocurrió un error cargando las compras');
            setSnackbarOpen(true);

        });
    };

    const filterByDate = () => {

        if(startAt || endAt)
            tableRef.current.onQueryChange();
        else {
            // Mostrar mensaje
            setSnackbarMessage('Debe llenar almenos la fecha inicial o la fecha final');
            setSnackbarOpen(true);
        }


    };

    return (
        <div className={classes.root}>
            <MaterialTable
                title="Compras"
                tableRef={tableRef}
                icons={ tableIcons }
                isLoading={ isLoading }
                columns={
                    columnObject
                }
                data={ (query) => loadTableData(query) }
                actions={[
                    {
                        icon: Add,
                        tooltip: 'Agregar compra',
                        isFreeAction: true,
                        onClick: (event) => history.push('/purchases/create')
                    },
                    authState.roleId === 1 ?
                        {
                            icon: AssistantPhoto,
                            tooltip: 'Eliminar compra(s)',
                            onClick: (event, rowData) => setIsDialogOpen(true),
                        } :
                        null
                ]}
                options={{
                    selection: true,
                    filtering: true,
                    exportButton: true,
                    exportFileName: `Compras_Tratamientos_Clinic_${ moment().format('YYYY_MM_DD')}`,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [10, 25, 50],
                    pageSize: 10,
                    debounceInterval: 500
                }}
                components={{
                    Toolbar: props => (
                        <div>
                            <MTableToolbar {...props} />
                            <div style={{padding: '0px 10px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                                <KeyboardDatePicker
                                    error={startAtHasError}
                                    invalidDateMessage={ startAtError }
                                    maxDateMessage={ startAtError }
                                    variant="inline"
                                    format="DD-MM-YYYY"
                                    style={{marginTop: 0, marginBottom: 16, flex: 1, marginRight: 10}}
                                    id="startAt"
                                    label="Fecha Inicial"
                                    value={startAt}
                                    onChange={(date) => setStartAt(date !== null ? date.format('YYYY-MM-DD') : '')}
                                    KeyboardButtonProps={{
                                        'aria-label': 'cambiar fecha',
                                    }}
                                />
                                <KeyboardDatePicker
                                    error={endAtHasError}
                                    invalidDateMessage={ endAtError }
                                    maxDateMessage={ endAtError }
                                    variant="inline"
                                    format="DD-MM-YYYY"
                                    style={{marginTop: 0, marginBottom: 16, flex: 1, marginRight: 10}}
                                    id="endAt"
                                    label="Fecha Final"
                                    value={endAt}
                                    onChange={(date) => setEndAt(date !== null ? date.format('YYYY-MM-DD') : '')}
                                    KeyboardButtonProps={{
                                        'aria-label': 'cambiar fecha',
                                    }}
                                />
                                <Button color="primary" onClick={ filterByDate } startIcon={<RefreshIcon />} disabled={ !startAt && !endAt }>Cargar</Button>
                            </div>
                        </div>
                    ),
                    FilterRow: props => (
                        <FilterRow {...props} total={ totalCost } />
                    )

                }}
                onSelectionChange={(rows) => setSelected(rows)}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} de {count}',
                        nextTooltip: 'Siguiente',
                        lastTooltip: 'Última',
                        firstTooltip: 'Primera',
                        previousTooltip: 'Anterior',
                        labelRowsPerPage: 'Filas por página:',
                        labelRowsSelect: 'filas'
                    },
                    toolbar: {
                        nRowsSelected: '{0} fila(s) seleccionada(s)',
                        searchTooltip: 'Buscar',
                        searchPlaceholder: 'Buscar',
                        exportTitle: 'Exportar',
                        exportName: 'Exportar como CSV'
                    },
                    header: {
                        actions: 'Acciones'
                    },
                    body: {
                        emptyDataSourceMessage: 'No hay registros disponibles',
                        filterRow: {
                            filterTooltip: 'Filtrar'
                        }
                    }
                }}
            />
            <Dialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">"Eliminar compra(s)"</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Está seguro que desea eliminar las compra(s) seleccionadas? Esta acción es permanente.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsDialogOpen(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleDeactivate} color="primary" autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
            <Snack message={ snackbarMessage } open={ snackbarOpen } handleClose={ () => setSnackbarOpen(false) } />
        </div>
    );

};

export default PurchaseIndex;
