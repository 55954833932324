import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import validate from 'validate.js'
import Snack from "../../../components/Snack/Snack";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import {NavLink} from "react-router-dom";
import axios from "axios";
import Dropzone from "react-dropzone";
import clsx from "clsx";
import ImageIcon from '@material-ui/icons/Image';
import ClearIcon from '@material-ui/icons/Clear';
import Tooltip from "@material-ui/core/Tooltip";
import {API_URL} from "../../../config";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import { KeyboardDatePicker } from '@material-ui/pickers';
import Grid from "@material-ui/core/Grid";
import moment from 'moment';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    padding: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    title: {
        flex: '1 1 100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backButton: {
        marginRight: 8
    },
    dropZone: {
        flex: 1,
        display: 'flex',
        cursor: 'pointer',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(4),
        padding: theme.spacing(3),
        borderWidth: 2,
        borderRadius: 2,
        borderColor: 'rgba(0,0,0,0.54)',
        borderStyle: 'dashed',
        color: 'rgba(0,0,0,0.54)',
        outline: 'none',
        transition: 'border .14s ease-in-out',
        '&:hover':{
            borderColor: theme.palette.primary.main,
        },
        '&:hover $dropzoneIcon': {
            color: theme.palette.primary.main
        }
    },
    fileError: {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main
    },
    dropzoneIcon: {
        color: 'rgba(0,0,0,0.54)',
        transition: 'color .14s ease-in-out',
    },
    iconError: {
        color: theme.palette.error.main
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    thumbsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
    },
    thumb: {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 100,
        height: 100,
        padding: 4,
        boxSizing: 'border-box'
    },
    thumbInner: {
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: 0,
        overflow: 'hidden',
        '&:hover $imageRemove': {
            opacity: 1
        }
    },
    img: {
        display: 'block',
        width: 'auto',
        height: '100%'
    },
    imageRemove: {
        position: 'absolute',
        display: 'flex',
        opacity: 0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        transition: 'opacity 300ms'
    },
    removeIcon: {
        flex: 1,
        cursor: 'pointer'
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
    subtitle: {
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(2)
    }
}));

const PatientsCreate = ({ history }) => {

    const classes = useStyles();

    // State de paises
    const [countries, setCountries] = useState([]);

    // State de estados
    const [states, setStates] = useState([]);
    const [filteredStates, setFilteredStates] = useState([]);

    // State de ciudades
    const [cities, setCities] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]);

    /* *** State de formulario *** */

    const [code, setCode] = useState('');
    const [codeHasError, setCodeHasError] = useState(false);
    const [codeError, setCodeError] = useState('');

    // State de nombre
    const [name, setName] = useState('');
    const [nameHasError, setNameHasError] = useState(false);
    const [nameError, setNameError] = useState('');

    const [address, setAddress] = useState('');
    const [addressHasError, setAddressHasError] = useState(false);
    const [addressError, setAddressError] = useState('');

    const [zipCode, setZipCode] = useState('');
    const [zipCodeHasError, setZipCodeHasError] = useState(false);
    const [zipCodeError, setZipCodeError] = useState('');

    const [rfc, setRfc] = useState('');
    const [rfcHasError, setRfcHasError] = useState(false);
    const [rfcError, setRfcError] = useState('');

    const [phone, setPhone] = useState('');
    const [phoneHasError, setPhoneHasError] = useState(false);
    const [phoneError, setPhoneError] = useState('');

    const [phone2, setPhone2] = useState('');
    const [phone2HasError, setPhone2HasError] = useState(false);
    const [phone2Error, setPhone2Error] = useState('');

    const [email, setEmail] = useState('');
    const [emailHasError, setEmailHasError] = useState(false);
    const [emailError, setEmailError] = useState('');

    const [birthAt, setBirthAt] = useState(null);
    const [birthAtHasError, setBirthAtHasError] = useState(false);
    const [birthAtError, setBirthAtError] = useState('');

    const [sex, setSex] = useState('');
    const [sexHasError, setSexHasError] = useState(false);
    const [sexError, setSexError] = useState('');

    const [pathologicalHistory, setPathologicalHistory] = useState('');
    const [pathologicalHistoryHasError, setPathologicalHistoryHasError] = useState(false);
    const [pathologicalHistoryError, setPathologicalHistoryError] = useState('');

    const [familyHistory, setFamilyHistory] = useState('');
    const [familyHistoryHasError, setFamilyHistoryHasError] = useState(false);
    const [familyHistoryError, setFamilyHistoryError] = useState('');

    const [nonPathologicalHistory, setNonPathologicalHistory] = useState('');
    const [nonPathologicalHistoryHasError, setNonPathologicalHistoryHasError] = useState(false);
    const [nonPathologicalHistoryError, setNonPathologicalHistoryError] = useState('');

    const [bloodPressure, setBloodPressure] = useState('');
    const [bloodPressureHasError, setBloodPressureHasError] = useState(false);
    const [bloodPressureError, setBloodPressureError] = useState('');

    const [heartRate, setHeartRate] = useState('');
    const [heartRateHasError, setHeartRateHasError] = useState(false);
    const [heartRateError, setHeartRateError] = useState('');

    const [size, setSize] = useState('');
    const [sizeHasError, setSizeHasError] = useState(false);
    const [sizeError, setSizeError] = useState('');

    const [initialWeight, setInitialWeight] = useState('');
    const [initialWeightHasError, setInitialWeightHasError] = useState(false);
    const [initialWeightError, setInitialWeightError] = useState('');

    const [idealWeight, setIdealWeight] = useState('');
    const [idealWeightHasError, setIdealWeightHasError] = useState(false);
    const [idealWeightError, setIdealWeightError] = useState('');

    const [comments, setComments] = useState('');
    const [commentsHasError, setCommentsHasError] = useState(false);
    const [commentsError, setCommentsError] = useState('');

    // State de precio
    const [price, setPrice] = useState('');
    const [priceHasError, setPriceHasError] = useState(false);
    const [priceError, setPriceError] = useState('');

    // State de imagen
    const [files, setFiles] = useState([]);
    const [fileHasError, setFileHasError] = useState(false);
    const [fileErrorMessage, setFileErrorMessage] = useState('');

    const [city, setCity] = useState('');
    const [cityHasError, setCityHasError] = useState(false);
    const [cityError, setCityError] = useState('');

    const [country, setCountry] = useState('');
    const [state, setState] = useState('');

    const [description, setDescription] = useState('');
    const [descriptionHasError, setDescriptionHasError] = useState(false);
    const [descriptionError, setDescriptionError] = useState('');

    const [neck, setNeck] = useState('');
    const [neckHasError, setNeckHasError] = useState(false);
    const [neckError, setNeckError] = useState('');

    const [torax, setTorax] = useState('');
    const [toraxHasError, setToraxHasError] = useState(false);
    const [toraxError, setToraxError] = useState('');

    const [waist, setWaist] = useState('');
    const [waistHasError, setWaistHasError] = useState(false);
    const [waistError, setWaistError] = useState('');

    const [hip, setHip] = useState('');
    const [hipHasError, setHipHasError] = useState(false);
    const [hipError, setHipError] = useState('');

    const [treatedAt, setTreatedAt] = useState(moment().format('YYYY-MM-DD'));
    const [treatedAtHasError, setTreatedAtHasError] = useState(false);
    const [treatedAtError, setTreatedAtError] = useState('');

    const [nextTreatmentAt, setNextTreatmentAt] = useState(moment().add(10, 'days').format('YYYY-MM-DD'));
    const [nextTreatmentAtHasError, setNextTreatmentAtHasError] = useState(false);
    const [nextTreatmentAtError, setNextTreatmentAtError] = useState('');

    /* *** State de formulario *** */

    // State de snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(false);

    // Reglas de validación de formulario
    const constraints = {
        name: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            }
        }
    };

    useEffect(() => {

        const init = async () => {
            await loadCountries();
            await loadStates();
            await loadCities();
        };

        init();
    }, []);

    // Función que carga los paises
    const loadCountries = async () => {

        // Mostrar indicador de carga
        setIsLoading(true);

        try {

            // Hacer request
            const response = await axios.get(
                `${API_URL}/countries`,
                {
                    params: {
                        active: true,
                        sort: 'name',
                        direction: 'asc'
                    }
                }
            );

            // Actualizar state
            setCountries(response.data);

        } catch(error) {

            // Mostrar mensaje
            setSnackbarMessage('Ocurrió un error cargando los países');
            setSnackbarOpen(true);

        }

        // Esconder indicador de carga
        setIsLoading(false);
    };

    // Función que carga los estados
    const loadStates = async () => {

        // Mostrar indicador de carga
        setIsLoading(true);

        try {

            // Hacer request
            const response = await axios.get(
                `${API_URL}/states`,
                {
                    params: {
                        active: true,
                        sort: 'name',
                        direction: 'asc'
                    }
                }
            );

            // Actualizar state
            setStates(response.data);

        } catch(error) {

            // Mostrar mensaje
            setSnackbarMessage('Ocurrió un error cargando los estados');
            setSnackbarOpen(true);

        }

        // Esconder indicador de carga
        setIsLoading(false);
    };

    // Función que carga las ciudades
    const loadCities = async () => {

        // Mostrar indicador de carga
        setIsLoading(true);

        try {

            // Hacer request
            const response = await axios.get(
                `${API_URL}/cities`,
                {
                    params: {
                        active: true,
                        sort: 'name',
                        direction: 'asc'
                    }
                }
            );

            // Actualizar state
            setCities(response.data);

        } catch(error) {

            // Mostrar mensaje
            setSnackbarMessage('Ocurrió un error cargando las ciudades');
            setSnackbarOpen(true);

        }

        // Esconder indicador de carga
        setIsLoading(false);
    };

    const handleServerError = (error) => {

        if(error.code){
            setCodeHasError(true);
            setCodeError(error.code[0]);
        } else {
            setCodeHasError(false);
            setCodeError('');
        }

        if(error.name){
            setNameHasError(true);
            setNameError(error.name[0]);
        } else {
            setNameHasError(false);
            setNameError('');
        }

        // Si existe un error con el campo, mostrarlo
        if( error.name){
            setNameHasError(true);
            setNameError( error.name[0]);
        } else {
            setNameHasError(false);
            setNameError('');
        }

        if( error.sex){
            setSexHasError(true);
            setSexError( error.sex[0]);
        } else {
            setSexHasError(false);
            setSexError('');
        }

        if( error.rfc){
            setRfcHasError(true);
            setRfcError( error.rfc[0]);
        } else {
            setRfcHasError(false);
            setRfcError('');
        }

        if( error.birth_at){
            setBirthAtHasError(true);
            setBirthAtError( error.birth_at[0]);
        } else {
            setBirthAtHasError(false);
            setBirthAtError('');
        }

        if( error.zip_code){
            setZipCodeHasError(true);
            setZipCodeError( error.zip_code[0]);
        } else {
            setZipCodeHasError(false);
            setZipCodeError('');
        }

        if(error.phone){
            setPhoneHasError(true);
            setPhoneError(error.phone[0]);
        } else {
            setPhoneHasError(false);
            setPhoneError('');
        }

        if(error.phone_2){
            setPhone2HasError(true);
            setPhone2Error(error.phone_2[0]);
        } else {
            setPhone2HasError(false);
            setPhone2Error('');
        }

        if( error.email){
            setEmailHasError(true);
            setEmailError( error.email[0]);
        } else {
            setEmailHasError(false);
            setEmailError('');
        }

        if( error.city){
            setCityHasError(true);
            setCityError( error.city[0]);
        } else {
            setCityHasError(false);
            setCityError('');
        }

        if( error.price){
            setPriceHasError(true);
            setPriceError( error.price[0]);
        } else {
            setPriceHasError(false);
            setPriceError('');
        }

        if( error.blood_pressure){
            setBloodPressureHasError(true);
            setBloodPressureError( error.blood_pressure[0]);
        } else {
            setBloodPressureHasError(false);
            setBloodPressureError('');
        }

        if( error.heart_rate){
            setHeartRateHasError(true);
            setHeartRateError( error.heart_rate[0]);
        } else {
            setHeartRateHasError(false);
            setHeartRateError('');
        }

        if( error.size){
            setSizeHasError(true);
            setSizeError( error.size[0]);
        } else {
            setSizeHasError(false);
            setSizeError('');
        }

        if( error.initial_weight){
            setInitialWeightHasError(true);
            setInitialWeightError( error.initial_weight[0]);
        } else {
            setInitialWeightHasError(false);
            setInitialWeightError('');
        }

        if( error.ideal_weight){
            setIdealWeightHasError(true);
            setIdealWeightError( error.ideal_weight[0]);
        } else {
            setIdealWeightHasError(false);
            setIdealWeightError('');
        }

        if(error.pathological_history){
            setPathologicalHistoryHasError(true);
            setPathologicalHistoryError(error.pathological_history[0]);
        } else {
            setPathologicalHistoryHasError(false);
            setPathologicalHistoryError('');
        }

        if(error.family_history){
            setFamilyHistoryHasError(true);
            setFamilyHistoryError(error.family_history[0]);
        } else {
            setFamilyHistoryHasError(false);
            setFamilyHistoryError('');
        }

        if(error.non_pathological_history){
            setNonPathologicalHistoryHasError(true);
            setNonPathologicalHistoryError(error.non_pathological_history[0]);
        } else {
            setNonPathologicalHistoryHasError(false);
            setNonPathologicalHistoryError('');
        }
        if(error.description){
            setDescriptionHasError(true);
            setDescriptionError(error.description[0]);
        } else {
            setDescriptionHasError(false);
            setDescriptionError('');
        }

        if(error.neck){
            setNeckHasError(true);
            setNeckError(error.neck[0]);
        } else {
            setNeckHasError(false);
            setNeckError('');
        }

        if(error.torax){
            setToraxHasError(true);
            setToraxError(error.torax[0]);
        } else {
            setToraxHasError(false);
            setToraxError('');
        }

        if(error.waist){
            setWaistHasError(true);
            setWaistError(error.waist[0]);
        } else {
            setWaistHasError(false);
            setWaistError('');
        }

        if(error.hip){
            setHipHasError(true);
            setHipError(error.hip[0]);
        } else {
            setHipHasError(false);
            setHipError('');
        }

        if(error.treated_at){
            setTreatedAtHasError(true);
            setTreatedAtError(error.treated_at[0]);
        } else {
            setTreatedAtHasError(false);
            setTreatedAtError('');
        }

        if(error.next_treatment_at){
            setNextTreatmentAtHasError(true);
            setNextTreatmentAtError(error.next_treatment_at[0]);
        } else {
            setNextTreatmentAtHasError(false);
            setNextTreatmentAtError('');
        }

        if(error.comments){
            setCommentsHasError(true);
            setCommentsError(error.comments[0]);
        } else {
            setCommentsHasError(false);
            setCommentsError('');
        }
    };

    // Función que se ejecuta al enviar el formulario
    const handleSubmit = async () => {

        if(sex !== '') {
            constraints.sex = {
                inclusion: {
                    within: ['M', 'F'],
                    message: "Los valores permitidos son Hombre y Mujer"
                }
            };
        }

        if(city !== '') {
            constraints.city = {
                inclusion: {
                    within: cities.map(city => city.id),
                    message: "El valor seleccionado no es válido"
                }
            };
        }

        if(price !== '') {
            constraints.price = {
                numericality: {
                    strict: true,
                    greaterThanOrEqualTo: 0,
                    notValid: "El valor ingresado no es válido",
                    notGreaterThanOrEqualTo: "El valor debe ser mayor o igual a 0"
                }
            };
        }

        if(rfc !== '') {
            constraints.rfc = {
                length: {
                    maximum: 13,
                    minimum: 12,
                    message: "El valor solo puede contener entre 12 y 13 caracteres"
                }
            };
        }

        if(zipCode !== '') {
            constraints.zipCode = {
                numericality: {
                    message: "El valor debe ser un número entero"
                },
                length: {
                    is: 5,
                    message: "El valor debe contener 5 números"
                }
            };
        }

        if(email !== '') {
            constraints.email = {
                email: {
                    message: "El correo ingresado no es válido"
                }
            };
        }

        if(heartRate !== '') {
            constraints.heartRate = {
                numericality: {
                    message: "El valor debe ser numérico"
                }
            };
        }

        if(size !== '') {
            constraints.size = {
                numericality: {
                    message: "El valor debe ser numérico"
                }
            };
        }

        if(initialWeight !== '') {
            constraints.initialWeight = {
                numericality: {
                    message: "El valor debe ser numérico"
                }
            };
        }

        if(idealWeight !== '') {
            constraints.idealWeight = {
                numericality: {
                    message: "El valor debe ser numérico"
                }
            };
        }

        if(neck !== '')
            constraints.neck = {
                numericality: {
                    greaterThanOrEqualTo: 0,
                    message: 'El valor no es válido, debe ingresar un número mayor o igual a 0.'
                }
            };

        if(torax !== '')
            constraints.torax = {
                numericality: {
                    greaterThanOrEqualTo: 0,
                    message: 'El valor no es válido, debe ingresar un número mayor o igual a 0.'
                }
            };

        if(waist !== '')
            constraints.waist = {
                numericality: {
                    greaterThanOrEqualTo: 0,
                    message: 'El valor no es válido, debe ingresar un número mayor o igual a 0.'
                }
            };

        if(hip !== '')
            constraints.hip = {
                numericality: {
                    greaterThanOrEqualTo: 0,
                    message: 'El valor no es válido, debe ingresar un número mayor o igual a 0.'
                }
            };

        // Validar campos
        let valid = validate({ name, code, rfc, zipCode, email, sex, city, birthAt, bloodPressure, heartRate, size, initialWeight, idealWeight, price, description, treatedAt, neck, torax, waist, hip }, constraints, {fullMessages: false});

        // Si la validación pasó
        if(valid === undefined){

            // Mostrar el indicador de carga
            setIsLoading(true);

            try {

                const config = {
                    headers: { 'Content-Type': 'multipart/form-data' }
                };

                let formData = new FormData();

                formData.append('name', name);

                if(code)
                    formData.append('code', code);

                if(rfc)
                    formData.append('rfc', rfc);

                if(sex)
                    formData.append('sex', sex);

                if(city)
                    formData.append('city_id', city);

                if(address)
                    formData.append('address', address);

                if(zipCode)
                    formData.append('zip_code', zipCode);

                if(birthAt)
                    formData.append('birth_at', birthAt);

                if(phone)
                    formData.append('phone', phone);

                if(phone2)
                    formData.append('phone_2', phone2);

                if(email)
                    formData.append('email', email);

                if(price)
                    formData.append('price', price);

                if(bloodPressure)
                    formData.append('blood_pressure', bloodPressure);

                if(heartRate)
                    formData.append('heart_rate', heartRate);

                if(size)
                    formData.append('size', size);

                if(initialWeight)
                    formData.append('initial_weight', initialWeight);

                if(idealWeight)
                    formData.append('ideal_weight', idealWeight);

                if(pathologicalHistory)
                    formData.append('pathological_history', pathologicalHistory);

                if(familyHistory)
                    formData.append('family_history', familyHistory);

                if(nonPathologicalHistory)
                    formData.append('non_pathological_history', nonPathologicalHistory);

                if(comments)
                    formData.append('comments', comments);

                if(description)
                    formData.append('description', description);

                if(treatedAt)
                    formData.append('treated_at', treatedAt);

                if(neck)
                    formData.append('neck', neck);

                if(torax)
                    formData.append('torax', torax);

                if(waist)
                    formData.append('waist', waist);

                if(hip)
                    formData.append('hip', hip);

                if(nextTreatmentAt)
                    formData.append('next_treatment_at', nextTreatmentAt);

                if(files.length > 0)
                    formData.append('file', files[0]);

                const response = await axios.post(
                    `${API_URL}/patients`,
                    formData,
                    config
                );

                history.push(`/patients/${response.data.id}`);

            } catch (error) {

                switch(error.response.status){
                    case 400:
                        // Mostrar mensaje
                        setSnackbarMessage('El contenido enviado no cumplió las reglas de validación');
                        setSnackbarOpen(true);
                        handleServerError(error.response.data.errors);
                        break;
                    case 422:
                        // Mostrar mensaje
                        setSnackbarMessage('El contenido enviado no cumplió las reglas de validación');
                        setSnackbarOpen(true);
                        handleServerError(error.response.data.errors);
                        break;
                    default:
                        // Mostrar mensaje
                        setSnackbarMessage('Ocurrió un error registrando la medicina');
                        setSnackbarOpen(true);
                        break;
                }

            }

            // Esconder indicador de carga
            setIsLoading(false);

        } else {

            if(valid.code){
                setCodeHasError(true);
                setCodeError(valid.code[0]);
            } else {
                setCodeHasError(false);
                setCodeError('');
            }

            // Si existe un error con el campo, mostrarlo
            if(valid.name){
                setNameHasError(true);
                setNameError(valid.name[0]);
            } else {
                setNameHasError(false);
                setNameError('');
            }

            if(valid.sex){
                setSexHasError(true);
                setSexError(valid.sex[0]);
            } else {
                setSexHasError(false);
                setSexError('');
            }

            if(valid.rfc){
                setRfcHasError(true);
                setRfcError(valid.rfc[0]);
            } else {
                setRfcHasError(false);
                setRfcError('');
            }

            if(valid.birthAt){
                setBirthAtHasError(true);
                setBirthAtError(valid.birthAt[0]);
            } else {
                setBirthAtHasError(false);
                setBirthAtError('');
            }

            if(valid.zipCode){
                setZipCodeHasError(true);
                setZipCodeError(valid.zipCode[0]);
            } else {
                setZipCodeHasError(false);
                setZipCodeError('');
            }

            if(valid.email){
                setEmailHasError(true);
                setEmailError(valid.email[0]);
            } else {
                setEmailHasError(false);
                setEmailError('');
            }

            if(valid.city){
                setCityHasError(true);
                setCityError(valid.city[0]);
            } else {
                setCityHasError(false);
                setCityError('');
            }

            if(valid.price){
                setPriceHasError(true);
                setPriceError(valid.price[0]);
            } else {
                setPriceHasError(false);
                setPriceError('');
            }

            if(valid.bloodPressure){
                setBloodPressureHasError(true);
                setBloodPressureError(valid.bloodPressure[0]);
            } else {
                setBloodPressureHasError(false);
                setBloodPressureError('');
            }

            if(valid.heartRate){
                setHeartRateHasError(true);
                setHeartRateError(valid.heartRate[0]);
            } else {
                setHeartRateHasError(false);
                setHeartRateError('');
            }

            if(valid.size){
                setSizeHasError(true);
                setSizeError(valid.size[0]);
            } else {
                setSizeHasError(false);
                setSizeError('');
            }

            if(valid.initialWeight){
                setInitialWeightHasError(true);
                setInitialWeightError(valid.initialWeight[0]);
            } else {
                setInitialWeightHasError(false);
                setInitialWeightError('');
            }

            if(valid.idealWeight){
                setIdealWeightHasError(true);
                setIdealWeightError(valid.idealWeight[0]);
            } else {
                setIdealWeightHasError(false);
                setIdealWeightError('');
            }

            if(valid.description){
                setDescriptionHasError(true);
                setDescriptionError(valid.description[0]);
            } else {
                setDescriptionHasError(false);
                setDescriptionError('');
            }

            if(valid.neck){
                setNeckHasError(true);
                setNeckError(valid.neck[0]);
            } else {
                setNeckHasError(false);
                setNeckError('');
            }

            if(valid.torax){
                setToraxHasError(true);
                setToraxError(valid.torax[0]);
            } else {
                setToraxHasError(false);
                setToraxError('');
            }

            if(valid.waist){
                setWaistHasError(true);
                setWaistError(valid.waist[0]);
            } else {
                setWaistHasError(false);
                setWaistError('');
            }

            if(valid.hip){
                setHipHasError(true);
                setHipError(valid.hip[0]);
            } else {
                setHipHasError(false);
                setHipError('');
            }

            if(valid.treatedAt){
                setTreatedAtHasError(true);
                setTreatedAtError(valid.treatedAt[0]);
            } else {
                setTreatedAtHasError(false);
                setTreatedAtError('');
            }

            if(valid.nextTreatmentAt){
                setNextTreatmentAtHasError(true);
                setNextTreatmentAtError(valid.nextTreatmentAt[0]);
            } else {
                setNextTreatmentAtHasError(false);
                setNextTreatmentAtError('');
            }

        }



    };

    // Función que se ejecuta cuando se selecciona un archivo
    const handleFileSelect = (selectedFiles) => {

        setFiles(selectedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));

        setFileHasError(false);
        setFileErrorMessage('');
    };

    // Función que se ejecuta cuando se selecciona un archivo inválido
    const handleFileError = (rejectedFiles) => {

        // Actualizar state
        setFiles([]);

        // Dependiendo del error mostrar mensaje
        if(!['image/png', 'image/jpg', 'image/jpeg'].includes(rejectedFiles[0].type))
            setFileErrorMessage('El tipo de archivo no es permitido');
        else if(rejectedFiles[0].size > 500000)
            setFileErrorMessage('La imagen excede el tamaño permitido de 500KB');
        else
            setFileErrorMessage('Ocurrió un error desconocido cargando la imagen');

        // Actualizar state
        setFileHasError(true);
    };

    const thumbs = files.map(file => (
        <div className={ classes.thumb } key={file.name}>
            <Tooltip title={ file.name }>
                <div className={ classes.thumbInner }>
                    <div className={ classes.imageRemove }>
                        <ClearIcon
                            fontSize="large"
                            style={ { color: 'white' } }
                            className={ classes.removeIcon }
                            onClick={ () => setFiles([]) }
                        />
                    </div>
                    <img
                        src={file.preview}
                        className={ classes.img }
                    />

                </div>
            </Tooltip>
        </div>
    ));

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    const handleTreatedAt = (date) => {
        if(date !== null){
            setTreatedAt(date.format('YYYY-MM-DD'));
            date.add(10, 'days');
            setNextTreatmentAt(date.format('YYYY-MM-DD'));
        } else {
            setTreatedAt('');
        }
    };

    const handleCountrySelect = (value) => {
        setCountry(value);
        setFilteredStates(states.filter((state) => state.country_id === value));
    };

    const handleStateSelect = (value) => {
        setState(value);
        setFilteredCities(cities.filter((city) => city.state_id === value));
    };

    const countriesOptions = countries.map(country => (
        <MenuItem key={country.id} value={country.id}>{ country.name }</MenuItem>
    ));

    const statesOptions = filteredStates.map(state => (
        <MenuItem key={state.id} value={state.id}>{ state.name }</MenuItem>
    ));

    const citiesOptions = filteredCities.map(city => (
        <MenuItem key={city.id} value={city.id}>{ city.name }</MenuItem>
    ));

    return (
        <div className={classes.root}>
            {
                isLoading ?
                    <div className={ classes.progress }>
                        <CircularProgress />
                    </div> :
                    <Paper className={classes.paper}>
                        <Toolbar className={classes.padding}>
                            <IconButton className={ classes.backButton } component={ NavLink } to="/patients">
                                <ArrowBackIcon color="secondary" />
                            </IconButton>
                            <Typography className={classes.title} variant="h6" id="title">Paciente</Typography>
                        </Toolbar>
                        <div>
                            <div className={classes.padding}>
                                <Typography variant="subtitle2" className={ classes.subtitle }>Información General</Typography>
                                <Grid container spacing={3}>
                                    <Grid item sm={7} xs={12}>
                                        <TextField variant="outlined"
                                            id="name"
                                            label="Nombre"
                                            fullWidth
                                            className={ classes.textField }
                                            value={ name }
                                            onChange={ (event) => setName(event.target.value) }
                                            error={ nameHasError }
                                            helperText={ nameError }
                                        />
                                    </Grid>
                                    <Grid item sm={2} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            id="code"
                                            label="# Expediente"
                                            fullWidth
                                            className={ classes.textField }
                                            value={ code }
                                            onChange={ (event) => setCode(event.target.value) }
                                            error={ codeHasError }
                                            helperText={ codeError }
                                        />
                                    </Grid>
                                    <Grid item sm={3} xs={12}>
                                        <TextField variant="outlined"
                                            id="rfc"
                                            label="RFC"
                                            fullWidth
                                            className={ classes.textField }
                                            value={ rfc }
                                            onChange={ (event) => setRfc(event.target.value) }
                                            error={ rfcHasError }
                                            helperText={ rfcError }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item sm={9} xs={12}>
                                        <TextField variant="outlined"
                                            id="address"
                                            label="Dirección"
                                            fullWidth
                                            className={ classes.textField }
                                            value={ address }
                                            onChange={ (event) => setAddress(event.target.value) }
                                            error={ addressHasError }
                                            helperText={ addressError }
                                        />
                                    </Grid>
                                    <Grid item sm={3} xs={12}>
                                        <TextField variant="outlined"
                                            id="zipCode"
                                            label="Código Postal"
                                            fullWidth
                                            className={ classes.textField }
                                            value={ zipCode }
                                            onChange={ (event) => setZipCode(event.target.value) }
                                            error={ zipCodeHasError }
                                            helperText={ zipCodeError }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item sm={3} xs={12}>
                                        <TextField variant="outlined"
                                                   id="phone"
                                                   label="Teléfono"
                                                   fullWidth
                                                   className={ classes.textField }
                                                   value={ phone }
                                                   onChange={ (event) => setPhone(event.target.value) }
                                                   error={ phoneHasError }
                                                   helperText={ phoneError }
                                        />
                                    </Grid>
                                    <Grid item sm={3} xs={12}>
                                        <TextField variant="outlined"
                                                   id="phone2"
                                                   label="Celular"
                                                   fullWidth
                                                   className={ classes.textField }
                                                   value={ phone2 }
                                                   onChange={ (event) => setPhone2(event.target.value) }
                                                   error={ phone2HasError }
                                                   helperText={ phone2Error }
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <TextField variant="outlined"
                                                   id="email"
                                                   label="Correo Electrónico"
                                                   fullWidth
                                                   className={ classes.textField }
                                                   value={ email }
                                                   onChange={ (event) => setEmail(event.target.value) }
                                                   error={ emailHasError }
                                                   helperText={ emailError }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item sm={4} xs={12}>
                                        <FormControl className={ classes.formControl }>
                                            <InputLabel id="country_label">País</InputLabel>
                                            <Select
                                                labelId="country_label"
                                                id="country"
                                                MenuProps={MenuProps}
                                                fullWidth
                                                value={country}
                                                onChange={(event) => handleCountrySelect(event.target.value)}
                                            >
                                                { countriesOptions }
                                            </Select>
                                            <FormHelperText></FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                        <FormControl className={ classes.formControl } disabled={ filteredStates.length === 0 }>
                                            <InputLabel id="state_label">Estado</InputLabel>
                                            <Select
                                                labelId="state_label"
                                                id="state"
                                                MenuProps={MenuProps}
                                                fullWidth
                                                value={state}
                                                onChange={(event) => handleStateSelect(event.target.value)}
                                            >
                                                { statesOptions }
                                            </Select>
                                            <FormHelperText></FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                        <FormControl className={ classes.formControl } error={ cityHasError } disabled={ filteredCities.length === 0 }>
                                            <InputLabel id="city_label">Ciudad</InputLabel>
                                            <Select
                                                labelId="city_label"
                                                id="city"
                                                MenuProps={MenuProps}
                                                fullWidth
                                                value={city}
                                                onChange={(event) => setCity(event.target.value)}
                                            >
                                                { citiesOptions }
                                            </Select>
                                            <FormHelperText>{ cityError }</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <KeyboardDatePicker
                                            disableFuture
                                            error={birthAtHasError}
                                            invalidDateMessage={ birthAtError }
                                            maxDateMessage={ birthAtError }
                                            variant="inline"
                                            format="YYYY-MM-DD"
                                            style={{marginTop: 0, marginBottom: 16}}
                                            fullWidth
                                            id="birthAt"
                                            label="Fecha De Nacimiento"
                                            value={birthAt}
                                            onChange={(date) => setBirthAt( date !== null ? date.format('YYYY-MM-DD') : '')}
                                            KeyboardButtonProps={{
                                                'aria-label': 'cambiar fecha',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl className={ classes.formControl } error={ sexHasError }>
                                            <InputLabel id="sex_label">Sexo</InputLabel>
                                            <Select
                                                labelId="sex_label"
                                                id="sex"
                                                MenuProps={MenuProps}
                                                fullWidth
                                                value={sex}
                                                onChange={(event) => setSex(event.target.value)}
                                            >
                                                <MenuItem value={'M'}>Hombre</MenuItem>
                                                <MenuItem value={'F'}>Mujer</MenuItem>
                                            </Select>
                                            <FormHelperText>{ sexError }</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField variant="outlined"
                                                   id="price"
                                                   label="Costo"
                                                   fullWidth
                                                   type="number"
                                                   className={ classes.textField }
                                                   value={ price }
                                                   onChange={ (event) => setPrice(event.target.value) }
                                                   error={ priceHasError }
                                                   helperText={ priceError }
                                        />
                                    </Grid>
                                </Grid>
                                <Dropzone
                                    onDropAccepted={ acceptedFiles => handleFileSelect(acceptedFiles) }
                                    onDropRejected={ rejectedFiles => handleFileError(rejectedFiles) }
                                    accept={ ['image/jpg', 'image/jpeg', 'image/png'] }
                                    maxSize={500000}
                                    multiple={ false }
                                >
                                    {({getRootProps, getInputProps}) => (
                                        <section className={ clsx(classes.dropZone, fileHasError && classes.fileError) } {...getRootProps()}>

                                            <ImageIcon className={ clsx(classes.dropzoneIcon, fileHasError && classes.iconError ) } fontSize="large" />
                                            <input {...getInputProps()} />
                                            {
                                                fileHasError ?
                                                    <p>{ fileErrorMessage }</p> :
                                                    <p>Arrastra una imagen o da click para seleccionar una imagen</p>
                                            }
                                        </section>
                                    )}
                                </Dropzone>
                                <aside className={ classes.thumbsContainer }>
                                    {thumbs}
                                </aside>
                                <Typography variant="subtitle2" className={ classes.subtitle }>Historia Clínica</Typography>
                                <TextField variant="outlined"
                                           id="pathologicalHistory"
                                           label="Antecedentes Patológicos"
                                           fullWidth
                                           multiline
                                           rows={3}
                                           className={ classes.textField }
                                           value={ pathologicalHistory }
                                           onChange={ (event) => setPathologicalHistory(event.target.value) }
                                           error={ pathologicalHistoryHasError }
                                           helperText={ pathologicalHistoryError }
                                />
                                <TextField variant="outlined"
                                           id="familyHistory"
                                           label="Antecedentes Familiares"
                                           fullWidth
                                           multiline
                                           rows={3}
                                           className={ classes.textField }
                                           value={ familyHistory }
                                           onChange={ (event) => setFamilyHistory(event.target.value) }
                                           error={ familyHistoryHasError }
                                           helperText={ familyHistoryError }
                                />
                                <TextField variant="outlined"
                                           id="nonPathologicalHistory"
                                           label="Antecedentes No Patológicos"
                                           fullWidth
                                           multiline
                                           rows={3}
                                           className={ classes.textField }
                                           value={ nonPathologicalHistory }
                                           onChange={ (event) => setNonPathologicalHistory(event.target.value) }
                                           error={ nonPathologicalHistoryHasError }
                                           helperText={ nonPathologicalHistoryError }
                                />
                                <Grid container spacing={3}>
                                    <Grid item sm={3} xs={12}>
                                        <TextField variant="outlined"
                                            id="bloodPressure"
                                            label="Presión Arterial"
                                            fullWidth
                                            className={ classes.textField }
                                            value={ bloodPressure }
                                            onChange={ (event) => setBloodPressure(event.target.value) }
                                            error={ bloodPressureHasError }
                                            helperText={ bloodPressureError }
                                        />
                                    </Grid>
                                    <Grid item sm={3} xs={12}>
                                        <TextField variant="outlined"
                                            id="heartRate"
                                            label="Frecuencia Cardiaca"
                                            type="number"
                                            fullWidth
                                            className={ classes.textField }
                                            value={ heartRate }
                                            onChange={ (event) => setHeartRate(event.target.value) }
                                            error={ heartRateHasError }
                                            helperText={ heartRateError }
                                        />
                                    </Grid>
                                    <Grid item sm={3} xs={12}>
                                        <TextField variant="outlined"
                                                   id="size"
                                                   label="Talla"
                                                   type="number"
                                                   fullWidth
                                                   className={ classes.textField }
                                                   value={ size }
                                                   onChange={ (event) => setSize(event.target.value) }
                                                   error={ sizeHasError }
                                                   helperText={ sizeError }
                                        />
                                    </Grid>
                                    <Grid item sm={3} xs={12}>
                                        <TextField variant="outlined"
                                                   id="initialWeight"
                                                   label="Peso Inicial"
                                                   type="number"
                                                   fullWidth
                                                   className={ classes.textField }
                                                   value={ initialWeight }
                                                   onChange={ (event) => setInitialWeight(event.target.value) }
                                                   error={ initialWeightHasError }
                                                   helperText={ initialWeightError }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item sm={3} xs={12}>
                                        <TextField variant="outlined"
                                            id="neck"
                                            label="Cuello"
                                            type="number"
                                            fullWidth
                                            className={ classes.textField }
                                            value={ neck }
                                            onChange={ (event) => setNeck(event.target.value) }
                                            error={ neckHasError }
                                            helperText={ neckError }
                                        />
                                    </Grid>
                                    <Grid item sm={3} xs={12}>
                                        <TextField variant="outlined"
                                            id="torax"
                                            label="Torax"
                                            type="number"
                                            fullWidth
                                            className={ classes.textField }
                                            value={ torax }
                                            onChange={ (event) => setTorax(event.target.value) }
                                            error={ toraxHasError }
                                            helperText={ toraxError }
                                        />
                                    </Grid>
                                    <Grid item sm={3} xs={12}>
                                        <TextField variant="outlined"
                                                   id="waist"
                                                   label="Cintura"
                                                   type="number"
                                                   fullWidth
                                                   className={ classes.textField }
                                                   value={ waist }
                                                   onChange={ (event) => setWaist(event.target.value) }
                                                   error={ waistHasError }
                                                   helperText={ waistError }
                                        />
                                    </Grid>
                                    <Grid item sm={3} xs={12}>
                                        <TextField variant="outlined"
                                                   id="hip"
                                                   label="Cadera"
                                                   type="number"
                                                   fullWidth
                                                   className={ classes.textField }
                                                   value={ hip }
                                                   onChange={ (event) => setHip(event.target.value) }
                                                   error={ hipHasError }
                                                   helperText={ hipError }
                                        />
                                    </Grid>
                                </Grid>
                                <TextField variant="outlined"
                                           id="idealWeight"
                                           label="Peso Ideal"
                                           type="number"
                                           fullWidth
                                           className={ classes.textField }
                                           value={ idealWeight }
                                           onChange={ (event) => setIdealWeight(event.target.value) }
                                           error={ idealWeightHasError }
                                           helperText={ idealWeightError }
                                />
                                <TextField variant="outlined"
                                           id="description"
                                           label="Tratamiento"
                                           multiline
                                           rows={3}
                                           fullWidth
                                           className={ classes.textField }
                                           value={ description }
                                           onChange={ (event) => setDescription(event.target.value) }
                                           error={ descriptionHasError }
                                           helperText={ descriptionError }
                                />
                                <TextField variant="outlined"
                                           id="comments"
                                           label="Comentarios"
                                           fullWidth
                                           multiline
                                           rows={3}
                                           className={ classes.textField }
                                           value={ comments }
                                           onChange={ (event) => setComments(event.target.value) }
                                           error={ commentsHasError }
                                           helperText={ commentsError }
                                />
                                <Grid container spacing={3}>
                                    <Grid item sm={6} xs={12}>
                                        <KeyboardDatePicker
                                            error={treatedAtHasError}
                                            invalidDateMessage={ treatedAtError }
                                            maxDateMessage={ treatedAtError }
                                            variant="inline"
                                            format="DD-MM-YYYY"
                                            style={{marginTop: 0, marginBottom: 16}}
                                            fullWidth
                                            id="treatedAt"
                                            label="Fecha"
                                            value={treatedAt}
                                            onChange={(date) => handleTreatedAt(date)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'cambiar fecha',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <KeyboardDatePicker
                                            error={nextTreatmentAtHasError}
                                            invalidDateMessage={ nextTreatmentAtError }
                                            maxDateMessage={ nextTreatmentAtError }
                                            disablePast
                                            variant="inline"
                                            format="DD-MM-YYYY"
                                            style={{marginTop: 0, marginBottom: 16}}
                                            fullWidth
                                            id="nextTreatmentAt"
                                            label="Fecha de Próxima Cita"
                                            value={nextTreatmentAt}
                                            onChange={(date) => setNextTreatmentAt(date !== null ? date.format('YYYY-MM-DD') : '')}
                                            KeyboardButtonProps={{
                                                'aria-label': 'cambiar fecha',
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <div className={ classes.buttonWrapper }>
                                    <Button color="secondary" onClick={ handleSubmit }>Crear</Button>
                                </div>
                            </div>
                        </div>
                    </Paper>
            }
            <Snack message={ snackbarMessage } open={ snackbarOpen } handleClose={ () => setSnackbarOpen(false) } />
        </div>
    )
};

export default PatientsCreate;
