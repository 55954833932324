import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import KitchenIcon from '@material-ui/icons/Kitchen';
import ColorizeIcon from '@material-ui/icons/Colorize';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PersonIcon from '@material-ui/icons/Person';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentIcon from '@material-ui/icons/Assignment';
import HealingIcon from '@material-ui/icons/Healing';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import {NavLink, Redirect, Route, Switch} from "react-router-dom";
import {signOut} from "../../store/actions/auth";
import {connect, useSelector} from "react-redux";
import axios from "axios";
import {API_URL} from "../../config";
import Snack from "../Snack/Snack";
import MedicinesIndex from "../../containers/Medicines/MedicinesIndex/MedicinesIndex";
import MedicinesCreate from "../../containers/Medicines/MedicinesCreate/MedicinesCreate";
import MedicinesEdit from "../../containers/Medicines/MedicinesEdit/MedicinesEdit";
import FoodItemIndex from "../../containers/FoodItems/FoodItemIndex/FoodItemIndex";
import FoodItemCreate from "../../containers/FoodItems/FoodItemCreate/FoodItemCreate";
import FoodItemEdit from "../../containers/FoodItems/FoodItemEdit/FoodItemEdit";
import DietIndex from "../../containers/Diets/DietIndex/DietIndex";
import DietCreate from "../../containers/Diets/DietCreate/DietCreate";
import DietEdit from "../../containers/Diets/DietEdit/DietEdit";
import CityIndex from "../../containers/Cities/CityIndex/CityIndex";
import CityCreate from "../../containers/Cities/CityCreate/CityCreate";
import PatientIndex from "../../containers/Patients/PatientsIndex/PatientsIndex";
import PatientsCreate from "../../containers/Patients/PatientsCreate/PatientsCreate";
import PatientsEdit from "../../containers/Patients/PatientsEdit/PatientsEdit";
import PatientsView from "../../containers/Patients/PatientsView/PatientsView";
import UsersIndex from "../../containers/Users/UsersIndex/UsersIndex";
import UsersCreate from "../../containers/Users/UsersCreate/UsersCreate";
import UsersEdit from "../../containers/Users/UsersEdit/UsersEdit";
import Divider from "@material-ui/core/Divider";
import PatientDietsIndex from "../../containers/PatientDiets/PatientDietsIndex/PatientDietsIndex";
import PatientDietsCreate from "../../containers/PatientDiets/PatientDietsCreate/PatientDietsCreate";
import PrescriptionsIndex from "../../containers/Prescriptions/PrescriptionsIndex/PrescriptionsIndex";
import PrescriptionsCreate from "../../containers/Prescriptions/PrescriptionsCreate/PrescriptionsCreate";
import TreatmentsIndex from "../../containers/Treatments/TreatmentsIndex/TreatmentsIndex";
import TreatmentsCreate from "../../containers/Treatments/TreatmentsCreate/TreatmentsCreate";
import logo_bg_b from '../../assets/images/logo_bg_b.png';
import VaccinesIndex from "../../containers/Vaccines/VaccinesIndex/VaccinesIndex";
import VaccinesCreate from "../../containers/Vaccines/VaccinesCreate/VaccinesCreate";
import Home from "../../containers/Home/Home";
import TreatmentPurchaseIndex from "../../containers/TreatmentPurchases/TreatmentPurchaseIndex/TreatmentPurchaseIndex";
import TreatmentPurchaseCreate
    from "../../containers/TreatmentPurchases/TreatmentPurchaseCreate/TreatmentPurchaseCreate";
import PurchaseIndex from "../../containers/Purchases/PurchaseIndex/PurchaseIndex";
import PurchaseCreate from "../../containers/Purchases/PurchaseCreate/PurchaseCreate";
import SettingsIcon from '@material-ui/icons/Settings';
import ConfigurationsEdit from "../../containers/Configurations/ConfigurationsEdit/ConfigurationsEdit";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
        textAlign: 'left'
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        backgroundImage: 'url(' + logo_bg_b + ')',
        backgroundSize: 150
    },
    container: {
        display: 'flex',
        flex: 1,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    mainContainer: {
        display: 'flex',
        height: '100vh',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'stretch'
    }
}));

const Dashboard = ({ signOut, readError, authMessage, history }) => {

    const classes = useStyles();

    const authState = useSelector((state) => state.authReducer);

    // State de drawer
    const [open, setOpen] = React.useState(false);

    // State de snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const F8 = 119;
    const F9 = 120;

    useEffect(() => {
        const keyPressHandler = (event) => {

            switch( event.keyCode ) {
                case F8:
                    history.push('/treatment_purchases');
                    break;
                case F9:
                    history.push('/purchases');
                    break;
                default:
                    break;
            }
        };

        document.addEventListener('keydown', keyPressHandler);

        return () => {
            document.removeEventListener('keydown', keyPressHandler);
        };
    }, []);

    // Función que abre el drawer
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    // Función que cierra el drawer
    const handleDrawerClose = () => {
        setOpen(false);
    };

    // Función que cierra la sesión
    const handleLogout = async () => {

        try {

            await axios.post(API_URL + "/logout");
            signOut();

        } catch (error) {
            setSnackbarMessage('Ocurrió un error cerrando la sesión');
            setSnackbarOpen(true);
        }
    };

    return (
        <div style={ { backgroundColor: '#fafafa' } } className={ classes.mainContainer }>
            <AppBar position="sticky">
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={ handleDrawerOpen }
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        <NavLink to="/home" style={{ textDecoration: 'none', color: 'white'}} >
                            Diet Clinic
                        </NavLink>
                    </Typography>
                    <IconButton color="inherit" onClick={ handleLogout }>
                        <ExitToAppIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                classes={{
                    paper: classes.drawerPaper,
                }}
                onClose={ handleDrawerClose }
                open={open}
            >
                <List>
                    <div>
                        {
                            authState.roleId === 1 ?
                                <ListItem button component={NavLink} to="/food_items" activeClassName="Mui-selected">
                                    <ListItemIcon>
                                        <KitchenIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Alimentos"/>
                                </ListItem>:
                                null
                        }
                        <ListItem button component={ NavLink } to="/cities" activeClassName="Mui-selected">
                            <ListItemIcon>
                                <LocationCityIcon />
                            </ListItemIcon>
                            <ListItemText primary="Ciudades" />
                        </ListItem>
                        <ListItem button component={ NavLink } to="/diets" activeClassName="Mui-selected">
                            <ListItemIcon>
                                <MenuBookIcon />
                            </ListItemIcon>
                            <ListItemText primary="Dietas" />
                        </ListItem>
                        <ListItem button component={ NavLink } to="/medicines" activeClassName="Mui-selected">
                            <ListItemIcon>
                                <ColorizeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Medicinas" />
                        </ListItem>
                        {
                            authState.roleId === 1 ?
                                <ListItem button component={ NavLink } to="/users" activeClassName="Mui-selected">
                                    <ListItemIcon>
                                        <PersonIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Usuarios" />
                                </ListItem> :
                                null
                        }
                        <Divider />
                        <ListItem button component={ NavLink } to="/patient_diets" activeClassName="Mui-selected">
                            <ListItemIcon>
                                <RestaurantIcon />
                            </ListItemIcon>
                            <ListItemText primary="Dietas" />
                        </ListItem>
                        <ListItem button component={ NavLink } to="/patients" activeClassName="Mui-selected">
                            <ListItemIcon>
                                <AssignmentIndIcon />
                            </ListItemIcon>
                            <ListItemText primary="Pacientes" />
                        </ListItem>
                        <ListItem button component={ NavLink } to="/prescriptions" activeClassName="Mui-selected">
                            <ListItemIcon>
                                <AssignmentIcon />
                            </ListItemIcon>
                            <ListItemText primary="Recetas" />
                        </ListItem>
                        <ListItem button component={ NavLink } to="/treatments" activeClassName="Mui-selected">
                            <ListItemIcon>
                                <HealingIcon />
                            </ListItemIcon>
                            <ListItemText primary="Historia Clínica" />
                        </ListItem>
                        <ListItem button component={ NavLink } to="/vaccines" activeClassName="Mui-selected">
                            <ListItemIcon>
                                <ColorizeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Vacunas" />
                        </ListItem>
                        <Divider />
                        {
                            authState.roleId === 1 ?
                                <ListItem button component={ NavLink } to="/configurations/edit" activeClassName="Mui-selected">
                                    <ListItemIcon>
                                        <SettingsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Configuración" />
                                </ListItem> :
                                null
                        }
                    </div>
                </List>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Switch>
                        <Route path="/home" exact component={ Home } />
                        <Route path="/medicines" exact component={ MedicinesIndex } />
                        <Route path="/medicines/create" exact component={ MedicinesCreate } />
                        <Route path="/medicines/:id/edit" exact component={ MedicinesEdit } />
                        { authState.roleId === 1 && <Route path="/food_items" exact component={ FoodItemIndex } /> }
                        { authState.roleId === 1 && <Route path="/food_items/create" exact component={ FoodItemCreate } /> }
                        { authState.roleId === 1 && <Route path="/food_items/:id/edit" exact component={ FoodItemEdit } /> }
                        { authState.roleId === 1 && <Route path="/diets" exact component={ DietIndex } /> }
                        { authState.roleId === 1 && <Route path="/diets/create" exact component={ DietCreate } /> }
                        { authState.roleId === 1 && <Route path="/diets/:id/edit" exact component={ DietEdit } /> }
                        <Route path="/cities" exact component={ CityIndex } />
                        <Route path="/cities/create" exact component={ CityCreate } />
                        <Route path="/patients" exact component={ PatientIndex } />
                        <Route path="/patients/create" exact component={ PatientsCreate } />
                        <Route path="/patients/:id/edit" exact component={ PatientsEdit } />
                        <Route path="/patients/:id" exact component={ PatientsView } />
                        { authState.roleId === 1 && <Route path="/users" exact component={UsersIndex}/> }
                        { authState.roleId === 1 && <Route path="/users/create" exact component={UsersCreate}/>}
                        { authState.roleId === 1 && <Route path="/users/:id/edit" exact component={UsersEdit}/> }
                        <Route path="/patient_diets" exact component={ PatientDietsIndex } />
                        <Route path="/patient_diets/create/:id?" exact component={ PatientDietsCreate } />
                        <Route path="/prescriptions" exact component={ PrescriptionsIndex } />
                        <Route path="/prescriptions/create/:id?" exact component={ PrescriptionsCreate } />
                        <Route path="/treatments" exact component={ TreatmentsIndex } />
                        <Route path="/treatments/create/:id?" exact component={ TreatmentsCreate } />
                        <Route path="/vaccines" exact component={ VaccinesIndex } />
                        <Route path="/vaccines/create/:id?" exact component={ VaccinesCreate } />
                        <Route path="/treatment_purchases" exact component={ TreatmentPurchaseIndex } />
                        <Route path="/treatment_purchases/create" exact component={ TreatmentPurchaseCreate } />
                        <Route path="/purchases" exact component={ PurchaseIndex } />
                        <Route path="/purchases/create" exact component={ PurchaseCreate } />
                        { authState.roleId === 1 && <Route path="/configurations/edit" exact component={ConfigurationsEdit}/> }
                        <Redirect from="/" to="/home" />
                    </Switch>
                </Container>
            </main>
            <Snack message={ snackbarMessage } open={ snackbarOpen } handleClose={ () => setSnackbarOpen(false) } />
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () =>
            dispatch(signOut())
    };
};

export default connect(null, mapDispatchToProps)(Dashboard);
