import React, {useRef, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import * as moment from 'moment';
import Snack from "../../../components/Snack/Snack";
import MaterialTable from "material-table";
import axios from 'axios';
import {API_URL, tableIcons} from "../../../config";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {Add, AssistantPhoto} from "@material-ui/icons";
import PrintIcon from '@material-ui/icons/Print';
import EmailIcon from '@material-ui/icons/Email';
import {useSelector} from "react-redux";
import {Document, Page, pdf, PDFDownloadLink, Text, View} from "@react-pdf/renderer";
import Prescription from "../../../components/Reports/Prescription";
import PrescriptionReal from "../../../components/Reports/PrescriptionReal";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginBottom: 30
    },
    toolbar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    action: {
        marginRight: 8
    },
    progressBar: {
        width: '100%'
    }
}));

const PrescriptionsIndex = ({ history }) => {

    const classes = useStyles();

    const authState = useSelector((state) => state.authReducer);

    // State de tabla
    const [selected, setSelected] = useState([]);

    const [lang, setLang] = useState('es');

    const [loadedData, setLoadedData] = useState(null);

    // State de snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(true);

    // State de diálogo
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [isPrintDialogOpen, setIsPrintDialogOpen] = React.useState(false);
    const [isEmailDialogOpen, setIsEmailDialogOpen] = React.useState(false);

    const [ready, setReady] = useState(false);

    let [columnObject] = useState([
        {
            title: 'Creado',
            field: 'created_at',
            filtering: false,
            defaultSort: 'desc',
            render: rowData => {
                return moment(rowData.created_at).format('D/MMM/YY');
            }
        },
        { title: 'Paciente', field: 'name' },
        {
            title: 'Acciones',
            field: 'id',
            filtering: false,
            render: rowData => {

                return <>
                    <Tooltip title="Imprimir">
                        <IconButton onClick={ () => handlePrint(rowData.id)}>
                            <PrintIcon color="secondary" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Enviar">
                        <IconButton onClick={ () => handleEmail(rowData.id)}>
                            <EmailIcon color="secondary" />
                        </IconButton>
                    </Tooltip>
                </>
            }
        }
    ]);

    const tableRef = useRef(null);

    // Función que se ejecuta al enviar pulsar el botón de desactivar
    const handleDeactivate = async () => {

        // Esconder el diálogo
        setIsDialogOpen(false);

        // Mostrar el indicador de carga
        setIsLoading(true);

        try {

            // Total de seleccionados
            let count = selected.length;

            // Contadores para mensaje
            let deactivatedCount = 0;

            // Por cada categoría seleccionada
            for(let i = 0; i < count; i++){

                // Cambiar estado
                await axios.delete(`${API_URL}/prescriptions/${selected[i].id}`);

                deactivatedCount += 1;
            }

            if(deactivatedCount > 1){
                // Mostrar mensaje
                setSnackbarMessage(`${deactivatedCount} recetas eliminadas exitosamente`);
                setSnackbarOpen(true);
            } else {
                // Mostrar mensaje
                setSnackbarMessage(`1 receta eliminada exitosamente`);
                setSnackbarOpen(true);
            }

            setSelected([]);

            tableRef.current.onQueryChange();

        } catch (error) {

            // Mostrar mensaje
            setSnackbarMessage('Ocurrió un error eliminando las recetas.');
            setSnackbarOpen(true);
        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    const loadTableData = (query) => {

        return new Promise(async (resolve, reject) => {

            setIsLoading(true);

            let url = `${API_URL}/prescriptions?`;
            url += 'per_page=' + query.pageSize;
            url += '&page=' + (query.page + 1);

            if(query.orderBy && query.orderDirection){
                url += '&sort=' + query.orderBy.field;
                url += '&direction=' + query.orderDirection;
            }

            if(query.filters){
                for(let i = 0; i < query.filters.length; i++){
                    url += `&${query.filters[i].column.field}=${query.filters[i].value}`;
                }
            }

            if(query.search)
                url += '&search=' + query.search;

            try {

                const response = await axios.get(url);

                resolve({
                    data: response.data.data,
                    page: response.data.current_page - 1,
                    totalCount: response.data.total
                });

            } catch(error){
                reject(new Error('Ocurrió un error cargando las recetas.'))
            }

            setIsLoading(false);
        }).catch((error) => {

            // Mostrar mensaje
            setSnackbarMessage('Ocurrió un error cargando las recetas');
            setSnackbarOpen(true);

        });
    };

    const handlePrint = async (id) => {

        setIsLoading(true);
        setReady(false);

        try {

            const response = await axios.get(`${API_URL}/prescriptions/${id}`);
            const prescriptionData = response.data;

            setLoadedData(prescriptionData);
            setIsPrintDialogOpen(true);

            setTimeout(() => {
                setReady(true);
            }, 1);

        } catch(error) {

            // Mostrar mensaje
            setSnackbarMessage('Ocurrió un error generando el documento');
            setSnackbarOpen(true);

        }

        setIsLoading(false);
    };

    const handleEmail = async (id) => {
        setIsLoading(true);

        try {

            const response = await axios.get(`${API_URL}/prescriptions/${id}`);
            const prescriptionData = response.data;

            setLoadedData(prescriptionData);
            setIsEmailDialogOpen(true);

        } catch(error) {

            // Mostrar mensaje
            setSnackbarMessage('Ocurrió un error cargando la información de la receta.');
            setSnackbarOpen(true);

        }

        setIsLoading(false);
    };

    const sendEmail = async (type) => {

        setIsEmailDialogOpen(false);

        if(loadedData.patient.email.trim()){
            setIsLoading(true);

            try {
                if(type === 'copy'){

                    const doc = Prescription({ data: loadedData, lang: lang, images: lang === 'es' });

                    const blobPdf = await pdf(doc);
                    blobPdf.updateContainer(doc);
                    const blob = await blobPdf.toBlob();

                    const base64 = await getBase64(blob);

                    await axios.post(`${API_URL}/prescriptions/email`, {
                        id: loadedData.prescription.id,
                        blob: base64,
                        lang: lang
                    });

                } else {
                    const doc = PrescriptionReal({ data: loadedData, lang: lang });

                    const blobPdf = await pdf(doc);
                    blobPdf.updateContainer(doc);
                    const blob = await blobPdf.toBlob();

                    const base64 = await getBase64(blob);

                    await axios.post(`${API_URL}/prescriptions/email`, {
                        id: loadedData.prescription.id,
                        blob: base64,
                        lang: lang
                    });
                }

                // Mostrar mensaje
                setSnackbarMessage('La receta ha sido enviada exitósamente.');
                setSnackbarOpen(true);

            } catch(error) {
                // Mostrar mensaje
                setSnackbarMessage('Ocurrió un error generando y enviando el documento');
                setSnackbarOpen(true);
            }

            setIsLoading(false);
        } else {
            // Mostrar mensaje
            setSnackbarMessage('El paciente no tiene registrado un correo electrónico');
            setSnackbarOpen(true);
        }

    };

    const getBase64 = async (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                const base64data = reader.result;
                resolve(base64data);
            };

            reader.readAsDataURL(blob);
        });
    };

    return (
        <div className={classes.root}>
            <MaterialTable
                title="Recetas de Pacientes"
                tableRef={tableRef}
                icons={ tableIcons }
                isLoading={ isLoading }
                columns={
                    columnObject
                }
                data={ (query) => loadTableData(query) }
                actions={[
                    {
                        icon: Add,
                        tooltip: 'Agregar receta',
                        isFreeAction: true,
                        onClick: (event) => history.push('/prescriptions/create')
                    },
                    authState.roleId === 1 ?
                        {
                            icon: AssistantPhoto,
                            tooltip: 'Eliminar receta(s)',
                            onClick: (event, rowData) => setIsDialogOpen(true),
                        } :
                        null

                ]}
                options={{
                    selection: true,
                    filtering: true,
                    exportButton: true,
                    exportFileName: `Recetas_Pacientes_Clinic_${ moment().format('YYYY_MM_DD')}`,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [10, 25, 50],
                    pageSize: 10,
                    debounceInterval: 500
                }}
                onSelectionChange={(rows) => setSelected(rows)}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} de {count}',
                        nextTooltip: 'Siguiente',
                        lastTooltip: 'Última',
                        firstTooltip: 'Primera',
                        previousTooltip: 'Anterior',
                        labelRowsPerPage: 'Filas por página:',
                        labelRowsSelect: 'filas'
                    },
                    toolbar: {
                        nRowsSelected: '{0} fila(s) seleccionada(s)',
                        searchTooltip: 'Buscar',
                        searchPlaceholder: 'Buscar',
                        exportTitle: 'Exportar',
                        exportName: 'Exportar como CSV'
                    },
                    header: {
                        actions: 'Acciones'
                    },
                    body: {
                        emptyDataSourceMessage: 'No hay registros disponibles',
                        filterRow: {
                            filterTooltip: 'Filtrar'
                        }
                    }
                }}
            />
            <Dialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">"Eliminar receta(s)"</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Está seguro que desea eliminar las recetas seleccionadas? Esta acción es permanente.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsDialogOpen(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleDeactivate} color="primary" autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isPrintDialogOpen}
                onClose={() => setIsPrintDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">"Imprimir Receta"</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Seleccione el lenguaje en el cual desea imprimir la receta.
                    </DialogContentText>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="lang" name="lang" value={lang} onChange={(event) => setLang(event.target.value)} row>
                            <FormControlLabel value="es" control={<Radio />} label="Español" />
                            <FormControlLabel value="en" control={<Radio />} label="Inglés" />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsPrintDialogOpen(false)} color="primary">
                        Cancelar
                    </Button>
                    {
                        ready ?
                            <PDFDownloadLink style={{textDecoration: 'none'}}
                                             document={<Prescription data={loadedData} lang={lang}/>}
                                             fileName={`Receta_${loadedData ? loadedData.prescription.id : ''}.pdf`}>
                                {
                                    ({blob, url, loading, error}) => {
                                        return <Button color="primary" autoFocus disabled={loading}>
                                            Copia
                                        </Button>
                                    }
                                }
                            </PDFDownloadLink> :
                            null
                    }
                    {
                        ready ?
                            <PDFDownloadLink style={{textDecoration: 'none'}}
                                             document={<PrescriptionReal data={loadedData} lang={lang}/>}
                                             fileName={`Receta_${loadedData ? loadedData.prescription.id : ''}.pdf`}>
                                {
                                    ({blob, url, loading, error}) => {
                                        return <Button color="primary" autoFocus disabled={loading}>
                                            Válida
                                        </Button>
                                    }
                                }
                            </PDFDownloadLink> :
                            null
                    }
                </DialogActions>
            </Dialog>
            <Dialog
                open={isEmailDialogOpen}
                onClose={() => setIsEmailDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">"Enviar Receta"</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Seleccione el lenguaje en el cual desea enviar la receta.
                    </DialogContentText>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="lang" name="lang" value={lang} onChange={(event) => setLang(event.target.value)} row>
                            <FormControlLabel value="es" control={<Radio />} label="Español" />
                            <FormControlLabel value="en" control={<Radio />} label="Inglés" />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsEmailDialogOpen(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button color="primary" autoFocus onClick={ () => sendEmail('copy') }>
                        Copia
                    </Button>
                    <Button color="primary" autoFocus onClick={ () => sendEmail('valid') }>
                        Válida
                    </Button>
                </DialogActions>
            </Dialog>
            <Snack message={ snackbarMessage } open={ snackbarOpen } handleClose={ () => setSnackbarOpen(false) } />
        </div>
    );

};

export default PrescriptionsIndex;
