import React from 'react';
import {Document, Page, Text, StyleSheet, View, Image} from "@react-pdf/renderer";
import moment from 'moment';
import 'moment/locale/es';
import logo from '../../assets/images/logo.png';

const styles = StyleSheet.create({
    page: { paddingVertical: 30, paddingHorizontal: 40 },
    section: { color: 'white', textAlign: 'center', margin: 30 },
    headerWrapper: { display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginBottom: 30 },
    headerRight: { fontSize: 10, fontFamily: 'Times-Italic' },
    headerLeft: { fontSize: 10, fontFamily: 'Times-Italic' },
    headerMiddle: { fontSize: 12, fontFamily: 'Times-Italic' },
    logo: { width: 150 },
    inputWrapper: { display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginBottom: 4 },
    label: { fontSize: 12, fontFamily: 'Helvetica-Bold' },
    mainWrapper: { borderRadius: 25, borderWidth: 2, borderStyle: 'solid', minHeight: 200, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' },
    copyWrapper: { position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'},
    copy: { fontSize: 60, fontWeight: 'bold', color: '#f5f0f0'},
    copySub: { fontSize: 12, color: '#f5f0f0'},
    footerWrapper: { display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginTop: 10 },
    medicinesWrapper: { marginHorizontal: 40, marginVertical: 20 },
    medicineWrapper: { marginBottom: 8 },
    medicineName: { fontSize: 16, fontFamily: 'Helvetica-Bold' },
    medicineDesc: { fontSize: 12},
    medicineDosis: { fontSize: 14, fontFamily: 'Helvetica-Oblique'},
});

const PrescriptionReal = ({ data, lang = "es" }) => {

    if(lang === 'es')
        moment.locale('es');
    else
        moment.locale('en');

    const prescription = data.prescription;
    const patient = data.patient;
    const medicines = data.medicines;
    const configuration = data.configuration;

    const renderMedicines = () => {
        return medicines.map(medicine => (
            <View style={ styles.medicineWrapper } key={medicine.id}>
                <View style={ { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' } }>
                    <View>
                        <Text style={ [styles.medicineName, {color: configuration.prescription_medicine_name_color ? configuration.prescription_medicine_name_color : '#4b7454'}] }>{ medicine.name } <Text style={ [styles.medicineDosis, {color: configuration.prescription_medicine_description_color ? configuration.prescription_medicine_description_color : '#828282'}] }>{ lang === 'es' ? medicine.description : medicine.description_en }</Text></Text>
                        <Text style={ [styles.medicineDosis, {color: configuration.prescription_medicine_dosis_color ? configuration.prescription_medicine_dosis_color : 'black'}] }>{ lang === 'es' ? medicine.dosis : medicine.dosis_en }</Text>
                    </View>
                </View>
            </View>
        ));
    };

    return <Document>
        <Page size="A4" style={ styles.page }>
            <View style={ styles.headerWrapper }>
                <View>
                    <Text style={ [styles.headerLeft, {color: configuration.prescription_contact_color ? configuration.prescription_contact_color : 'black'}] }>Álvaro Obregón No. 44</Text>
                    <Text style={ [styles.headerLeft, {color: configuration.prescription_contact_color ? configuration.prescription_contact_color : 'black'}] }>Esquina con 2a</Text>
                    <Text style={ [styles.headerLeft, {color: configuration.prescription_contact_color ? configuration.prescription_contact_color : 'black'}] }>H. Matamoros, Tam.</Text>
                </View>
                <View style={ { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' } }>
                    <Image style={ styles.logo } src={logo} />
                    <Text style={ { fontSize: 10, fontFamily: 'Times-Italic' } }>U.A.T</Text>
                    <View style={ { display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row' } }>
                        <Text style={ [styles.headerLeft, { marginRight: 10, color: configuration.prescription_contact_color ? configuration.prescription_contact_color : 'black' }] }>S.S.A 76606</Text>
                        <Text style={ [styles.headerLeft, { marginLeft: 10, color: configuration.prescription_contact_color ? configuration.prescription_contact_color : 'black' }] }>Céd. Prof. 615383</Text>
                    </View>
                </View>
                <View>
                    <Text style={ [styles.headerRight, {color: configuration.prescription_contact_color ? configuration.prescription_contact_color : 'black'}] }>Tel. (868) 812-35-85</Text>
                    <Text style={ [styles.headerRight, { marginLeft: 18, color: configuration.prescription_contact_color ? configuration.prescription_contact_color : 'black' }] }>(868) 812-12-94</Text>
                    <Text style={ [styles.headerRight, {color: configuration.prescription_contact_color ? configuration.prescription_contact_color : 'black'}] }>dietclinic@email.com</Text>
                </View>
            </View>
            <View style={ styles.inputWrapper }>
                <Text style={ [styles.label, {color: configuration.prescription_info_label_color ? configuration.prescription_info_label_color : 'black'}] }>{ lang === 'es' ? 'NOMBRE' : 'NAME' }: </Text>
                <Text style={ { borderBottomStyle: 'solid', borderBottomWidth: 1, flex: 2, fontSize: 12, fontFamily: 'Helvetica', textTransform: 'uppercase', color: configuration.prescription_info_content_color ? configuration.prescription_info_content_color : 'black' } }>{ patient.name }</Text>
                <Text style={ [styles.label, {color: configuration.prescription_info_label_color ? configuration.prescription_info_label_color : 'black'}] }>{ lang === 'es' ? 'DE' : 'AT' }: </Text>
                <Text style={ { borderBottomStyle: 'solid', borderBottomWidth: 1, flex: 1, fontSize: 12, fontFamily: 'Helvetica', textTransform: 'uppercase', color: configuration.prescription_info_content_color ? configuration.prescription_info_content_color : 'black' } }>{ moment(prescription.created_at).format(lang === 'es' ? 'D [de] MMMM YYYY' : 'MMMM D YYYY') }</Text>
            </View>
            <View style={ styles.mainWrapper }>
                <View style={ styles.medicinesWrapper }>
                    { renderMedicines() }
                    <Text style={ { fontSize: 12, fontFamily: 'Helvetica', color: configuration.prescription_comments_color ? configuration.prescription_comments_color : 'red', textAlign: 'center' } }>{ lang === 'es' ? prescription.comments : prescription.comments_en }</Text>
                </View>
            </View>
            <View style={ styles.footerWrapper }>
                <Text style={ { fontSize: 10, fontFamily: 'Times-Italic', color: configuration.prescription_contact_color ? configuration.prescription_contact_color : 'black' } }>dietclinicweb.com</Text>
                <Text style={ { fontSize: 10, fontFamily: 'Times-Italic', color: configuration.prescription_contact_color ? configuration.prescription_contact_color : 'black' } }>facebook.com/Dr.JoseHebertoTeran</Text>
            </View>
        </Page>
    </Document>

};

export default PrescriptionReal;
