
// State inicial del reducer
import {SET_PATIENT} from "../actionTypes";

const patientState = {
    current: null
};

// State de autenticación
const patientReducer = (state = patientState, action) => {
    switch(action.type){
        case SET_PATIENT:
            return { ...action.payload };
        default:
            return state;
    }
};

export default patientReducer;
