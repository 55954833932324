import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Snack from "../../../components/Snack/Snack";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import {NavLink} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import axios from "axios";
import {API_URL, PUBLIC_URL, tableIcons} from "../../../config";
import WarningIcon from '@material-ui/icons/Warning';
import moment from 'moment';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../../components/TabPanel/TabPanel";
import {Add} from "@material-ui/icons";
import MaterialTable from "material-table";
import Avatar from "@material-ui/core/Avatar";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button";
import {pdf, PDFDownloadLink} from "@react-pdf/renderer";
import Diet from "../../../components/Reports/Diet";
import Prescription from "../../../components/Reports/Prescription";
import PrescriptionReal from "../../../components/Reports/PrescriptionReal";
import PrintIcon from "@material-ui/icons/Print";
import EmailIcon from "@material-ui/icons/Email";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Grid from "@material-ui/core/Grid";
import AddIcon from '@material-ui/icons/Add';
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    inlineProgress: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    padding: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    title: {
        flex: '1 1 100%',
    },
    backButton: {
        marginRight: 8
    },
    gallery: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexWrap: 'wrap'
    },
    image: {
        height: 200,
        margin: 2
    },
    errorWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '100%'
    },
    iconError: {
        color: theme.palette.error.main
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    subtitle: {
        color: theme.palette.primary.main
    },
    label: {
        fontSize: 12,
        fontWeight: 'bold'
    }
}));

const PatientsView = ({ match, history }) => {

    const classes = useStyles();

    const [lang, setLang] = useState('es');

    const [dietLoadedData, setDietLoadedData] = useState(null);
    const [prescriptionLoadedData, setPrescriptionLoadedData] = useState(null);

    // State general del paciente
    const [patient, setPatient] = useState(null);
    const [country, setCountry] = useState(null);
    const [state, setState] = useState(null);
    const [city, setCity] = useState(null);
    const [treatment, setTreatment] = useState(null);

    const [selectedTab, setSelectedTab] = useState(0);

    const [treatments, setTreatments] = useState([]);
    const [patientDiets, setPatientDiets] = useState([]);
    const [prescriptions, setPrescriptions] = useState([]);
    const [vaccines, setVaccines] = useState([]);

    // State de tabla
    const [selectedTreatments, setSelectedTreatments] = useState([]);
    const [selectedPatientDiets, setSelectedPatientDiets] = useState([]);
    const [selectedPrescriptions, setSelectedPrescriptions] = useState([]);
    const [selectedVaccines, setSelectedVaccines] = useState([]);

    // State de snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(true);
    const [isTabLoading, setIsTabLoading] = useState(false);

    const [error, setError] = useState('');

    const [isDietPrintDialogOpen, setIsDietPrintDialogOpen] = React.useState(false);
    const [isDietEmailDialogOpen, setIsDietEmailDialogOpen] = React.useState(false);
    const [isPrescriptionPrintDialogOpen, setIsPrescriptionPrintDialogOpen] = React.useState(false);
    const [isPrescriptionEmailDialogOpen, setIsPrescriptionEmailDialogOpen] = React.useState(false);

    const [dietReady, setDietReady] = useState(false);
    const [prescriptionReady, setPrescriptionReady] = useState(false);

    // ID del documento
    const id = match.params.id;

    let [treatmentsColumns] = useState([
        {
            title: 'Fecha',
            field: 'treated_at',
            defaultSort: 'desc',
            filtering: false,
            render: rowData => {
                if(rowData.treated_at)
                    return (<div style={{minWidth: '100px'}}>  {moment(rowData.treated_at).format('D/MMM/YY')}  </div>)
                else
                    return '';
            }
        },
        {
            title: 'Tratamiento',
            field: 'description',
            filtering: false,
            render: rowData =>
                (<div style={{minWidth: '250px'}}>  {rowData.description}  </div>)
        },
        { title: 'Peso', field: 'weight', filtering: false },
        { title: 'Torax', field: 'torax', filtering: false },
        { title: 'Cintura', field: 'waist', filtering: false },
        { title: 'Cadera', field: 'hip', filtering: false },
        {
            title: 'Próxima Cita',
            field: 'next_treatment_at',
            filtering: false,
            render: rowData => {
                if(rowData.next_treatment_at)
                    return (<div style={{minWidth: '100px'}}>  {moment(rowData.next_treatment_at).format('D/MMM/YY')}  </div>)
                else
                    return '';
            }
        }
    ]);

    let [patientDietsColumns] = useState([
        { title: 'Dieta', field: 'diet_name', filtering: false },
        {
            title: 'Fecha de Inicio',
            field: 'start_at',
            filtering: false,
            render: rowData => {
                return moment(rowData.start_at).format('D/MMM/YY');
            }
        },
        {
            title: 'Acciones',
            field: 'id',
            filtering: false,
            render: rowData => {

                return <>
                    <Tooltip title="Imprimir">
                        <IconButton onClick={ () => handleDietPrint(rowData.id)}>
                            <PrintIcon color="secondary" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Enviar">
                        <IconButton onClick={ () => handleDietEmail(rowData.id)}>
                            <EmailIcon color="secondary" />
                        </IconButton>
                    </Tooltip>
                </>
            }
        },
        {
            title: 'Creado',
            field: 'created_at',
            defaultSort: 'desc',
            filtering: false,
            render: rowData => {
                return moment(rowData.created_at).format('D/MMM/YY');
            }
        }
    ]);

    let [prescriptionsColumns] = useState([
        {
            title: 'Creado',
            field: 'created_at',
            defaultSort: 'desc',
            filtering: false,
            render: rowData => {
                return moment(rowData.created_at).format('D/MMM/YY');
            }
        },
        {
            title: 'Acciones',
            field: 'id',
            filtering: false,
            render: rowData => {

                return <>
                    <Tooltip title="Imprimir">
                        <IconButton onClick={ () => handlePrescriptionPrint(rowData.id)}>
                            <PrintIcon color="secondary" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Enviar">
                        <IconButton onClick={ () => handlePrescriptionEmail(rowData.id)}>
                            <EmailIcon color="secondary" />
                        </IconButton>
                    </Tooltip>
                </>
            }
        }
    ]);

    let [vaccinesColumns] = useState([
        { title: 'Cantidad', field: 'amount', filtering: false },
        {
            title: 'Se aplicó',
            field: 'was_applied',
            type: 'boolean',
        },
        {
            title: 'Comentarios',
            field: 'comments',
            filtering: false,
            render: rowData =>
                (<div style={{minWidth: '250px'}}>  {rowData.comments}  </div>)
        },
        {
            title: 'Fecha',
            field: 'handed_at',
            filtering: false,
            render: rowData => {
                if(rowData.handed_at)
                    return (<div style={{minWidth: '100px'}}>  {moment(rowData.handed_at).format('D/MMM/YY')}  </div>)
                else
                    return '';
            }
        }
    ]);

    useEffect(() => {

        const init = async () => {

            setIsLoading(true);

            await loadPatient();

            setIsLoading(false);
        };

        init();
    }, []);

    const loadPatient = async () => {

        try {

            const response = await axios.get(`${API_URL}/patients/${id}`);

            setPatient(response.data.patient);

            if(response.data.country)
                setCountry(response.data.country);

            if(response.data.state)
                setState(response.data.state);

            if(response.data.city)
                setCity(response.data.city);

            if(response.data.treatment)
                setTreatment(response.data.treatment);

        } catch(error){

            setError('Ocurrió un error cargando el paciente');

            // Mostrar mensaje de error
            setSnackbarMessage('Ocurrió un error cargando el paciente');
            setSnackbarOpen(true);

        }

    };

    const loadTreatments = async () => {

        try {

            const response = await axios.get(`${API_URL}/treatments`, { params: { patient_id: id } });

            setTreatments(response.data);

        } catch(error){

            setError('Ocurrió un error cargando la historia clínica');

            // Mostrar mensaje de error
            setSnackbarMessage('Ocurrió un error cargando la historia clínica');
            setSnackbarOpen(true);

        }

    };

    const loadPatientDiets = async () => {

        try {

            const response = await axios.get(`${API_URL}/patient_diets`, { params: { patient_id: id } });

            setPatientDiets(response.data);

        } catch(error){

            setError('Ocurrió un error cargando las dietas del paciente');

            // Mostrar mensaje de error
            setSnackbarMessage('Ocurrió un error cargando las dietas del paciente');
            setSnackbarOpen(true);

        }

    };

    const loadPrescriptions = async () => {

        try {

            const response = await axios.get(`${API_URL}/prescriptions`, { params: { patient_id: id } });

            setPrescriptions(response.data);

        } catch(error){

            setError('Ocurrió un error cargando las recetas del paciente');

            // Mostrar mensaje de error
            setSnackbarMessage('Ocurrió un error cargando las recetas del paciente');
            setSnackbarOpen(true);

        }

    };

    const loadVaccines = async () => {

        try {

            const response = await axios.get(`${API_URL}/vaccines`, { params: { patient_id: id } });

            setVaccines(response.data);

        } catch(error){

            setError('Ocurrió un error cargando las vacunas del paciente');

            // Mostrar mensaje de error
            setSnackbarMessage('Ocurrió un error cargando las vacunas del paciente');
            setSnackbarOpen(true);

        }

    };

    const handleDietPrint = async (id) => {

        setIsLoading(true);
        setDietReady(false);

        try {

            const response = await axios.get(`${API_URL}/patient_diets/${id}`);
            const patientDietData = response.data;

            setDietLoadedData(patientDietData);
            setIsDietPrintDialogOpen(true);

            setTimeout(() => {
                setDietReady(true);
            }, 1);
        } catch(error) {

            // Mostrar mensaje
            setSnackbarMessage('Ocurrió un error generando el documento');
            setSnackbarOpen(true);

        }

        setIsLoading(false);
    };

    const handlePrescriptionPrint = async (id) => {

        setIsLoading(true);
        setPrescriptionReady(false);

        try {

            const response = await axios.get(`${API_URL}/prescriptions/${id}`);
            const prescriptionData = response.data;

            setPrescriptionLoadedData(prescriptionData);
            setIsPrescriptionPrintDialogOpen(true);

            setTimeout(() => {
                setPrescriptionReady(true);
            }, 1);

        } catch(error) {

            // Mostrar mensaje
            setSnackbarMessage('Ocurrió un error generando el documento');
            setSnackbarOpen(true);

        }

        setIsLoading(false);
    };

    const handleTabChange = async (tab) => {
        setSelectedTab(tab);
        setIsTabLoading(true);
        switch (tab) {
            case 1:
                await loadTreatments(id);
                break;
            case 2:
                await loadPatientDiets(id);
                break;
            case 3:
                await loadPrescriptions(id);
                break;
            case 4:
                await loadVaccines(id);
                break;
        }
        setIsTabLoading(false);
    };

    const handlePrescriptionEmail = async (id) => {
        setIsLoading(true);

        try {

            const response = await axios.get(`${API_URL}/prescriptions/${id}`);
            const prescriptionData = response.data;

            setPrescriptionLoadedData(prescriptionData);
            setIsPrescriptionEmailDialogOpen(true);

        } catch(error) {

            // Mostrar mensaje
            setSnackbarMessage('Ocurrió un error cargando la información de la receta.');
            setSnackbarOpen(true);

        }

        setIsLoading(false);
    };

    const sendPrescriptionEmail = async (type) => {

        setIsPrescriptionEmailDialogOpen(false);

        if(prescriptionLoadedData.patient.email.trim()){
            setIsLoading(true);

            try {
                if(type === 'copy'){

                    const doc = Prescription({ data: prescriptionLoadedData, lang: lang, images: lang === 'es' });

                    const blobPdf = await pdf(doc);
                    blobPdf.updateContainer(doc);
                    const blob = await blobPdf.toBlob();

                    const base64 = await getBase64(blob);

                    await axios.post(`${API_URL}/prescriptions/email`, {
                        id: prescriptionLoadedData.prescription.id,
                        blob: base64,
                        lang: lang
                    });

                } else {
                    const doc = PrescriptionReal({ data: prescriptionLoadedData, lang: lang });

                    const blobPdf = await pdf(doc);
                    blobPdf.updateContainer(doc);
                    const blob = await blobPdf.toBlob();

                    const base64 = await getBase64(blob);

                    await axios.post(`${API_URL}/prescriptions/email`, {
                        id: prescriptionLoadedData.prescription.id,
                        blob: base64,
                        lang: lang
                    });
                }

                // Mostrar mensaje
                setSnackbarMessage('La receta ha sido enviada exitósamente.');
                setSnackbarOpen(true);

            } catch(error) {
                // Mostrar mensaje
                setSnackbarMessage('Ocurrió un error generando y enviando el documento');
                setSnackbarOpen(true);
            }

            setIsLoading(false);
        } else {
            // Mostrar mensaje
            setSnackbarMessage('El paciente no tiene registrado un correo electrónico');
            setSnackbarOpen(true);
        }

    };

    const handleDietEmail = async (id) => {
        setIsLoading(true);

        try {

            const response = await axios.get(`${API_URL}/patient_diets/${id}`);
            const patientDietData = response.data;

            setDietLoadedData(patientDietData);
            setIsDietEmailDialogOpen(true);

        } catch(error) {

            // Mostrar mensaje
            setSnackbarMessage('Ocurrió un error cargando la información de la dieta.');
            setSnackbarOpen(true);

        }

        setIsLoading(false);
    };

    const sendDietEmail = async () => {

        setIsDietEmailDialogOpen(false);

        if(dietLoadedData.patient.email.trim()){
            setIsLoading(true);

            try {

                const doc = Diet({ data: dietLoadedData, lang: lang });

                const blobPdf = await pdf(doc);
                blobPdf.updateContainer(doc);
                const blob = await blobPdf.toBlob();

                const base64 = await getBase64(blob);

                await axios.post(`${API_URL}/patient_diets/email`, {
                    id: dietLoadedData.diet.id,
                    blob: base64,
                    lang: lang
                });

                // Mostrar mensaje
                setSnackbarMessage('La dieta ha sido enviada exitósamente.');
                setSnackbarOpen(true);

            } catch(error) {
                // Mostrar mensaje
                setSnackbarMessage('Ocurrió un error generando y enviando el documento');
                setSnackbarOpen(true);
            }

            setIsLoading(false);
        } else {
            // Mostrar mensaje
            setSnackbarMessage('El paciente no tiene registrado un correo electrónico');
            setSnackbarOpen(true);
        }

    };

    const getBase64 = async (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                const base64data = reader.result;
                resolve(base64data);
            };

            reader.readAsDataURL(blob);
        });
    };

    const renderAddress = () => {
        return `${patient.address ? patient.address + '. ' : ''}${patient.zip_code ? 'C.P.' + patient.zip_code + '. ' : ''}`
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <div className={classes.root}>
            {
                isLoading ?
                    <div className={ classes.progress }>
                        <CircularProgress />
                    </div> :
                    error !== '' ?
                        <div className={ classes.errorWrapper }>
                            <WarningIcon color="primary" style={ { marginRight: 8 } }/>
                            <Typography>{ error }</Typography>
                        </div> :
                        <div>
                            <Grid container spacing={3} style={ { marginBottom: 10 } }>
                                <Grid item sm={3} xs={12}>
                                    <Button variant="contained" color="primary" size="large"
                                            startIcon={<AddIcon />}
                                            fullWidth
                                            onClick={() => history.push('/treatments/create/' + id)}>
                                        Historia Clínica
                                    </Button>
                                </Grid>
                                <Grid item sm={3} xs={12}>
                                    <Button variant="contained" color="primary" size="large" startIcon={<AddIcon />} fullWidth onClick={() => history.push('/patient_diets/create/' + id)}>Dieta</Button>
                                </Grid>
                                <Grid item sm={3} xs={12}>
                                    <Button variant="contained" color="primary" size="large" startIcon={<AddIcon />} fullWidth onClick={() => history.push('/prescriptions/create/' + id)}>Receta</Button>
                                </Grid>
                                <Grid item sm={3} xs={12}>
                                    <Button variant="contained" color="primary" size="large" startIcon={<AddIcon />} fullWidth onClick={() => history.push('/vaccines/create/' + id)}>Vacuna</Button>
                                </Grid>
                            </Grid>
                            <Paper className={classes.paper}>
                                <Toolbar className={classes.padding}>
                                    <IconButton className={ classes.backButton } component={ NavLink } to="/patients">
                                        <ArrowBackIcon color="secondary" />
                                    </IconButton>
                                    <Typography className={classes.title} variant="h6" id="name">Paciente</Typography>
                                </Toolbar>
                                <div className={classes.padding}>
                                    <div style={ { display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                        <Avatar alt={ patient.name ? patient.name : 'Sin Nombre'} src={ `${PUBLIC_URL}/patients/${patient.image}` } className={ classes.avatar }/>
                                        <Typography variant="h6" style={ { marginTop: 10 } }>{ patient.name ? patient.name : 'Sin Nombre'}</Typography>
                                        <Typography variant="subtitle2" style={ { marginBottom: 20 } }>#{ patient.code}</Typography>
                                    </div>
                                    <Tabs
                                        value={selectedTab}
                                        onChange={ (event, value) => handleTabChange(value) }
                                        centered
                                        indicatorColor="primary"
                                        textColor="primary"
                                        aria-label="Información de paciente"
                                    >
                                        <Tab label="Información General" {...a11yProps(0)} />
                                        <Tab label="Historia Clínica" {...a11yProps(1)} />
                                        <Tab label="Dietas" {...a11yProps(2)} />
                                        <Tab label="Recetas" {...a11yProps(3)} />
                                        <Tab label="Vacunas" {...a11yProps(4)} />
                                    </Tabs>
                                    <TabPanel value={selectedTab} index={0}>
                                        <Typography variant="subtitle2" style={ { marginBottom: 20 } } className={ classes.subtitle }>Información General</Typography>
                                        <TableContainer>
                                            <Table className={classes.table} aria-label="simple table" size="small">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography className={classes.label}>Nombre</Typography>
                                                            <Typography>{ patient.name ? patient.name : '' }</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography className={classes.label}>RFC</Typography>
                                                            <Typography>{ patient.rfc ? patient.rfc : '' }</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography className={classes.label}>Sexo</Typography>
                                                            <Typography>{ patient.sex  && (patient.sex === 'M' ? 'Hombre' : 'Mujer')}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography className={classes.label}>Fecha de Nacimiento</Typography>
                                                            <Typography>{ patient.birth_at ? moment(patient.birth_at).format('D/MMM/YY') : '' }</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography className={classes.label}>Edad</Typography>
                                                            <Typography>{ patient.birth_at ? moment().diff(patient.birth_at, 'years') + ' años.' : '' }</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography className={classes.label}>Creado</Typography>
                                                            <Typography>{ moment(patient.created_at).format('D/MMM/YY') }</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography className={classes.label}>Dirección</Typography>
                                                            <Typography>{ renderAddress() }</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography className={classes.label}>Ciudad</Typography>
                                                            <Typography>{ city && city.name }</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography className={classes.label}>Estado</Typography>
                                                            <Typography>{ state && state.name }</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography className={classes.label}>Correo Electrónico</Typography>
                                                            <Typography>{ patient.email ? patient.email : '' }</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography className={classes.label}>Teléfono</Typography>
                                                            <Typography>{ patient.phone ? patient.phone : '' }</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography className={classes.label}>Teléfono 2</Typography>
                                                            <Typography>{ patient.phone_2 ? patient.phone_2 : '' }</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <Typography variant="subtitle2" style={ { marginBottom: 20, marginTop: 30 } } className={ classes.subtitle }>Salud</Typography>
                                        <TableContainer>
                                            <Table className={classes.table} aria-label="simple table">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell colSpan={4}>
                                                            <Typography className={classes.label}>Tratamiento</Typography>
                                                            <Typography>{ treatment ? treatment.description : '' }</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell colSpan={2}>
                                                            <Typography className={classes.label}>Presión Arterial</Typography>
                                                            <Typography>{ patient.blood_pressure ? patient.blood_pressure : '' }</Typography>
                                                        </TableCell>
                                                        <TableCell colSpan={2}>
                                                            <Typography className={classes.label}>Frecuencia Cardiaca</Typography>
                                                            <Typography>{ patient.heart_rate ? patient.heart_rate : '' }</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell colSpan={2}>
                                                            <Typography className={classes.label}>Talla</Typography>
                                                            <Typography>{ patient.size ? patient.size : '' }</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography className={classes.label}>Peso Inicial</Typography>
                                                            <Typography>{ patient.initial_weight ? patient.initial_weight : '' }</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography className={classes.label}>Peso Ideal</Typography>
                                                            <Typography>{ patient.ideal_weight ? patient.ideal_weight : '' }</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography className={classes.label}>Cuello</Typography>
                                                            <Typography>{ patient.neck ? patient.neck : '' }</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography className={classes.label}>Torax</Typography>
                                                            <Typography>{ treatment && treatment.torax ? treatment.torax : '' }</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography className={classes.label}>Cintura</Typography>
                                                            <Typography>{ treatment && treatment.waist ? treatment.waist : '' }</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography className={classes.label}>Cadera</Typography>
                                                            <Typography>{ treatment && treatment.hip ? treatment.hip : '' }</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell colSpan={2}>
                                                            <Typography className={classes.label}>Antecedentes Patológicos</Typography>
                                                            <Typography>{ patient.pathological_history ? patient.pathological_history : '' }</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography className={classes.label}>Antecedentes Familiares</Typography>
                                                            <Typography>{ patient.family_history ? patient.family_history : '' }</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography className={classes.label}>Antecedentes No Patológicos</Typography>
                                                            <Typography>{ patient.non_pathological_history ? patient.non_pathological_history : '' }</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </TabPanel>
                                    <TabPanel value={selectedTab} index={1}>
                                        {
                                            isTabLoading ?
                                                <div className={classes.inlineProgress}>
                                                    <CircularProgress/>
                                                </div> :
                                                <MaterialTable
                                                    title="Historia Clínica"
                                                    components={{
                                                        Container: props => <div {...props} />
                                                    }}
                                                    icons={tableIcons}
                                                    isLoading={isLoading}
                                                    columns={
                                                        treatmentsColumns
                                                    }
                                                    data={treatments}
                                                    actions={[
                                                        {
                                                            icon: Add,
                                                            tooltip: 'Agregar historia clínica',
                                                            isFreeAction: true,
                                                            onClick: (event) => history.push('/treatments/create/' + patient.id)
                                                        }
                                                    ]}
                                                    options={{
                                                        filtering: true,
                                                        exportButton: true,
                                                        exportFileName: `Historia_Clinica_Pacientes_Clinic_${moment().format('YYYY_MM_DD')}`,
                                                        emptyRowsWhenPaging: false,
                                                        pageSizeOptions: [10, 25, 50],
                                                        pageSize: 10,
                                                        debounceInterval: 500
                                                    }}
                                                    onSelectionChange={(rows) => setSelectedTreatments(rows)}
                                                    localization={{
                                                        pagination: {
                                                            labelDisplayedRows: '{from}-{to} de {count}',
                                                            nextTooltip: 'Siguiente',
                                                            lastTooltip: 'Última',
                                                            firstTooltip: 'Primera',
                                                            previousTooltip: 'Anterior',
                                                            labelRowsPerPage: 'Filas por página:',
                                                            labelRowsSelect: 'filas'
                                                        },
                                                        toolbar: {
                                                            nRowsSelected: '{0} fila(s) seleccionada(s)',
                                                            searchTooltip: 'Buscar',
                                                            searchPlaceholder: 'Buscar',
                                                            exportTitle: 'Exportar',
                                                            exportName: 'Exportar como CSV'
                                                        },
                                                        header: {
                                                            actions: 'Acciones'
                                                        },
                                                        body: {
                                                            emptyDataSourceMessage: 'No hay registros disponibles',
                                                            filterRow: {
                                                                filterTooltip: 'Filtrar'
                                                            }
                                                        }
                                                    }}
                                                />
                                        }
                                    </TabPanel>
                                    <TabPanel value={selectedTab} index={2}>
                                        {
                                            isTabLoading ?
                                                <div className={classes.inlineProgress}>
                                                    <CircularProgress/>
                                                </div> :
                                                <MaterialTable
                                                    title="Dietas"
                                                    components={{
                                                        Container: props => <div {...props} />
                                                    }}
                                                    icons={tableIcons}
                                                    isLoading={isLoading}
                                                    columns={
                                                        patientDietsColumns
                                                    }
                                                    data={patientDiets}
                                                    actions={[
                                                        {
                                                            icon: Add,
                                                            tooltip: 'Agregar dieta de paciente',
                                                            isFreeAction: true,
                                                            onClick: (event) => history.push('/patient_diets/create/' + patient.id)
                                                        }
                                                    ]}
                                                    options={{
                                                        filtering: true,
                                                        exportButton: true,
                                                        exportFileName: `Dietas_Pacientes_Clinic_${moment().format('YYYY_MM_DD')}`,
                                                        emptyRowsWhenPaging: false,
                                                        pageSizeOptions: [10, 25, 50],
                                                        pageSize: 10,
                                                        debounceInterval: 500
                                                    }}
                                                    onSelectionChange={(rows) => setSelectedPatientDiets(rows)}
                                                    localization={{
                                                        pagination: {
                                                            labelDisplayedRows: '{from}-{to} de {count}',
                                                            nextTooltip: 'Siguiente',
                                                            lastTooltip: 'Última',
                                                            firstTooltip: 'Primera',
                                                            previousTooltip: 'Anterior',
                                                            labelRowsPerPage: 'Filas por página:',
                                                            labelRowsSelect: 'filas'
                                                        },
                                                        toolbar: {
                                                            nRowsSelected: '{0} fila(s) seleccionada(s)',
                                                            searchTooltip: 'Buscar',
                                                            searchPlaceholder: 'Buscar',
                                                            exportTitle: 'Exportar',
                                                            exportName: 'Exportar como CSV'
                                                        },
                                                        header: {
                                                            actions: 'Acciones'
                                                        },
                                                        body: {
                                                            emptyDataSourceMessage: 'No hay registros disponibles',
                                                            filterRow: {
                                                                filterTooltip: 'Filtrar'
                                                            }
                                                        }
                                                    }}
                                                />
                                        }
                                    </TabPanel>
                                    <TabPanel value={selectedTab} index={3}>
                                        {
                                            isTabLoading ?
                                                <div className={classes.inlineProgress}>
                                                    <CircularProgress/>
                                                </div> :
                                                <MaterialTable
                                                    title="Recetas"
                                                    components={{
                                                        Container: props => <div {...props} />
                                                    }}
                                                    icons={tableIcons}
                                                    isLoading={isLoading}
                                                    columns={
                                                        prescriptionsColumns
                                                    }
                                                    data={prescriptions}
                                                    actions={[
                                                        {
                                                            icon: Add,
                                                            tooltip: 'Agregar receta',
                                                            isFreeAction: true,
                                                            onClick: (event) => history.push('/prescriptions/create/' + patient.id)
                                                        }
                                                    ]}
                                                    options={{
                                                        filtering: true,
                                                        exportButton: true,
                                                        exportFileName: `Recetas_Pacientes_Clinic_${moment().format('YYYY_MM_DD')}`,
                                                        emptyRowsWhenPaging: false,
                                                        pageSizeOptions: [10, 25, 50],
                                                        pageSize: 10,
                                                        debounceInterval: 500
                                                    }}
                                                    onSelectionChange={(rows) => setSelectedPrescriptions(rows)}
                                                    localization={{
                                                        pagination: {
                                                            labelDisplayedRows: '{from}-{to} de {count}',
                                                            nextTooltip: 'Siguiente',
                                                            lastTooltip: 'Última',
                                                            firstTooltip: 'Primera',
                                                            previousTooltip: 'Anterior',
                                                            labelRowsPerPage: 'Filas por página:',
                                                            labelRowsSelect: 'filas'
                                                        },
                                                        toolbar: {
                                                            nRowsSelected: '{0} fila(s) seleccionada(s)',
                                                            searchTooltip: 'Buscar',
                                                            searchPlaceholder: 'Buscar',
                                                            exportTitle: 'Exportar',
                                                            exportName: 'Exportar como CSV'
                                                        },
                                                        header: {
                                                            actions: 'Acciones'
                                                        },
                                                        body: {
                                                            emptyDataSourceMessage: 'No hay registros disponibles',
                                                            filterRow: {
                                                                filterTooltip: 'Filtrar'
                                                            }
                                                        }
                                                    }}
                                                />
                                        }
                                    </TabPanel>
                                    <TabPanel value={selectedTab} index={4}>
                                        {
                                            isTabLoading ?
                                                <div className={classes.inlineProgress}>
                                                    <CircularProgress/>
                                                </div> :
                                                <MaterialTable
                                                    title="Vacunas"
                                                    components={{
                                                        Container: props => <div {...props} />
                                                    }}
                                                    icons={tableIcons}
                                                    isLoading={isLoading}
                                                    columns={
                                                        vaccinesColumns
                                                    }
                                                    data={vaccines}
                                                    actions={[
                                                        {
                                                            icon: Add,
                                                            tooltip: 'Agregar vacunas',
                                                            isFreeAction: true,
                                                            onClick: (event) => history.push('/vaccines/create/' + patient.id)
                                                        }
                                                    ]}
                                                    options={{
                                                        filtering: true,
                                                        exportButton: true,
                                                        exportFileName: `Vacunas_Pacientes_Clinic_${moment().format('YYYY_MM_DD')}`,
                                                        emptyRowsWhenPaging: false,
                                                        pageSizeOptions: [10, 25, 50],
                                                        pageSize: 10,
                                                        debounceInterval: 500
                                                    }}
                                                    onSelectionChange={(rows) => setSelectedVaccines(rows)}
                                                    localization={{
                                                        pagination: {
                                                            labelDisplayedRows: '{from}-{to} de {count}',
                                                            nextTooltip: 'Siguiente',
                                                            lastTooltip: 'Última',
                                                            firstTooltip: 'Primera',
                                                            previousTooltip: 'Anterior',
                                                            labelRowsPerPage: 'Filas por página:',
                                                            labelRowsSelect: 'filas'
                                                        },
                                                        toolbar: {
                                                            nRowsSelected: '{0} fila(s) seleccionada(s)',
                                                            searchTooltip: 'Buscar',
                                                            searchPlaceholder: 'Buscar',
                                                            exportTitle: 'Exportar',
                                                            exportName: 'Exportar como CSV'
                                                        },
                                                        header: {
                                                            actions: 'Acciones'
                                                        },
                                                        body: {
                                                            emptyDataSourceMessage: 'No hay registros disponibles',
                                                            filterRow: {
                                                                filterTooltip: 'Filtrar'
                                                            }
                                                        }
                                                    }}
                                                />
                                        }
                                    </TabPanel>
                                </div>
                            </Paper>
                        </div>
            }
            <Dialog
                open={isDietPrintDialogOpen}
                onClose={() => setIsDietPrintDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">"Imprimir Dieta"</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Seleccione el lenguaje en el cual desea imprimir la dieta.
                    </DialogContentText>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="lang" name="lang" value={lang} onChange={(event) => setLang(event.target.value)} row>
                            <FormControlLabel value="es" control={<Radio />} label="Español" />
                            <FormControlLabel value="en" control={<Radio />} label="Inglés" />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsDietPrintDialogOpen(false)} color="primary">
                        Cancelar
                    </Button>
                    {
                        dietReady ?
                            <PDFDownloadLink style={{textDecoration: 'none'}}
                                             document={<Diet data={dietLoadedData} lang={lang}/>}
                                             fileName={`Dieta_${dietLoadedData ? dietLoadedData.diet.id : ''}.pdf`}>
                                {
                                    ({blob, url, loading, error}) => {
                                        return <Button color="primary" autoFocus disabled={loading}>
                                            IMPRIMIR
                                        </Button>
                                    }
                                }
                            </PDFDownloadLink> :
                            null
                    }
                </DialogActions>
            </Dialog>
            <Dialog
                open={isPrescriptionPrintDialogOpen}
                onClose={() => setIsPrescriptionPrintDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">"Imprimir Receta"</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Seleccione el lenguaje en el cual desea imprimir la receta.
                    </DialogContentText>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="lang" name="lang" value={lang} onChange={(event) => setLang(event.target.value)} row>
                            <FormControlLabel value="es" control={<Radio />} label="Español" />
                            <FormControlLabel value="en" control={<Radio />} label="Inglés" />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsPrescriptionPrintDialogOpen(false)} color="primary">
                        Cancelar
                    </Button>
                    {
                        prescriptionReady ?
                            <PDFDownloadLink style={{textDecoration: 'none'}}
                                             document={<Prescription data={prescriptionLoadedData} lang={lang}/>}
                                             fileName={`Receta_${prescriptionLoadedData ? prescriptionLoadedData.prescription.id : ''}.pdf`}>
                                {
                                    ({blob, url, loading, error}) => {
                                        return <Button color="primary" autoFocus disabled={loading}>
                                            Copia
                                        </Button>
                                    }
                                }
                            </PDFDownloadLink> :
                            null
                    }
                    {
                        prescriptionReady ?
                            <PDFDownloadLink style={{textDecoration: 'none'}}
                                             document={<PrescriptionReal data={prescriptionLoadedData} lang={lang}/>}
                                             fileName={`Receta_${prescriptionLoadedData ? prescriptionLoadedData.prescription.id : ''}.pdf`}>
                                {
                                    ({blob, url, loading, error}) => {
                                        return <Button color="primary" autoFocus disabled={loading}>
                                            Válida
                                        </Button>
                                    }
                                }
                            </PDFDownloadLink> :
                            null
                    }
                </DialogActions>
            </Dialog>
            <Dialog
                open={isPrescriptionEmailDialogOpen}
                onClose={() => setIsPrescriptionEmailDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">"Enviar Receta"</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Seleccione el lenguaje en el cual desea enviar la receta.
                    </DialogContentText>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="lang" name="lang" value={lang} onChange={(event) => setLang(event.target.value)} row>
                            <FormControlLabel value="es" control={<Radio />} label="Español" />
                            <FormControlLabel value="en" control={<Radio />} label="Inglés" />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsPrescriptionEmailDialogOpen(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button color="primary" autoFocus onClick={ () => sendPrescriptionEmail('copy') }>
                        Copia
                    </Button>
                    <Button color="primary" autoFocus onClick={ () => sendPrescriptionEmail('valid') }>
                        Válida
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isDietEmailDialogOpen}
                onClose={() => setIsDietEmailDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">"Enviar Dieta"</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Seleccione el lenguaje en el cual desea enviar la dieta.
                    </DialogContentText>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="lang" name="lang" value={lang} onChange={(event) => setLang(event.target.value)} row>
                            <FormControlLabel value="es" control={<Radio />} label="Español" />
                            <FormControlLabel value="en" control={<Radio />} label="Inglés" />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsDietEmailDialogOpen(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button color="primary" autoFocus onClick={ () => sendDietEmail() }>
                        Enviar
                    </Button>
                </DialogActions>
            </Dialog>
            <Snack message={ snackbarMessage } open={ snackbarOpen } handleClose={ () => setSnackbarOpen(false) } />
        </div>
    )
};

export default PatientsView;
