import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import validate from 'validate.js'
import Snack from "../../../components/Snack/Snack";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import axios from "axios";
import {API_URL, PUBLIC_URL} from "../../../config";
import Autocomplete from '@material-ui/lab/Autocomplete';
import useDebounce from "../../../hooks/useDebounce";
import {KeyboardDatePicker} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    padding: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    title: {
        flex: '1 1 100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    foodWrapper: {
        marginBottom: 20
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    insideProgress: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backButton: {
        marginRight: 8
    },
    textField: {
        marginBottom: theme.spacing(2),
        width: '100%'
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
    chipWrapper: {
        marginBottom: 20
    },
    chip: {
        margin: 2,
    },
    errorWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '100%'
    },
}));

const PurchaseCreate = ({ history, match }) => {

    const classes = useStyles();

    /* *** State de formulario *** */

    const [medicine, setMedicine] = useState('');
    const [medicineHasError, setMedicineHasError] = useState(false);
    const [medicineError, setMedicineError] = useState('');

    const [patient, setPatient] = useState('');
    const [patientHasError, setPatientHasError] = useState(false);
    const [patientError, setPatientError] = useState('');

    const [purchasedAt, setPurchasedAt] = useState(moment().format('YYYY-MM-DD'));
    const [purchasedAtHasError, setPurchasedAtHasError] = useState(false);
    const [purchasedAtError, setPurchasedAtError] = useState('');

    const [price, setPrice] = useState('');
    const [priceHasError, setPriceHasError] = useState(false);
    const [priceError, setPriceError] = useState('');

    const [total, setTotal] = useState('');
    const [totalHasError, setTotalHasError] = useState(false);
    const [totalError, setTotalError] = useState('');

    const [dollars, setDollars] = useState(0);
    const [dollarsHasError, setDollarsHasError] = useState(false);
    const [dollarsError, setDollarsError] = useState('');

    const [pesos, setPesos] = useState(0);
    const [pesosHasError, setPesosHasError] = useState(false);
    const [pesosError, setPesosError] = useState('');

    const [amount, setAmount] = useState(1);
    const [amountHasError, setAmountHasError] = useState(false);
    const [amountError, setAmountError] = useState('');

    /* *** State de formulario *** */

    const [medicines, setMedicines] = useState([]);
    const [patients, setPatients] = useState([]);

    // State de snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(false);

    const [open, setOpen] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');
    const [isSearching, setIsSearching] = useState(false);

    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    // Reglas de validación de formulario
    const constraints = {
        patient: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            }
        },
        amount: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            numericality: {
                greaterThan: 0,
                message: "El valor debe ser un número mayor a 0"
            }
        },
        price: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            numericality: {
                greaterThanOrEqualTo: 0,
                message: "El valor debe ser un número mayor o igual a 0"
            }
        },
        dollars: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            numericality: {
                greaterThanOrEqualTo: 0,
                message: "El valor debe ser un número mayor o igual a 0"
            }
        },
        pesos: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            numericality: {
                greaterThanOrEqualTo: 0,
                message: "El valor debe ser un número mayor o igual a 0"
            }
        },
        purchasedAt: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            }
        }
    };

    useEffect( () => {

        const init = async () => {
            await loadMedicines();
        };

        init();

    }, []);

    // Función que carga las dietas
    const loadMedicines = async () => {

        // Mostrar indicador de carga
        setIsLoading(true);

        try {

            // Hacer request
            const response = await axios.get(
                `${API_URL}/medicines`,
                {
                    params: {
                        active: true,
                        sort: 'name',
                        direction: 'asc'
                    }
                }
            );

            // Actualizar state
            setMedicines(response.data);

        } catch(error){

            // Mostrar mensaje de error
            setSnackbarMessage('Ocurrió un error cargando las medicinas');
            setSnackbarOpen(true);

        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    const handleServerError = (error) => {

        if(error.patient_id){
            setPatientHasError(true);
            setPatientError(error.patient_id[0]);
        } else {
            setPatientHasError(false);
            setPatientError('');
        }

        if(error.medicine_id){
            setMedicineHasError(true);
            setMedicineError(error.medicine_id[0]);
        } else {
            setMedicineHasError(false);
            setMedicineError('');
        }

        if(error.purchased_at){
            setPurchasedAtHasError(true);
            setPurchasedAtError(error.purchased_at[0]);
        } else {
            setPurchasedAtHasError(false);
            setPurchasedAtError('');
        }

        if(error.price){
            setPriceHasError(true);
            setPriceError(error.price[0]);
        } else {
            setPriceHasError(false);
            setPriceError('');
        }

        if(error.amount){
            setAmountHasError(true);
            setAmountError(error.amount[0]);
        } else {
            setAmountHasError(false);
            setAmountError('');
        }

        if(error.total){
            setTotalHasError(true);
            setTotalError(error.total[0]);
        } else {
            setTotalHasError(false);
            setTotalError('');
        }

        if(error.dollars){
            setDollarsHasError(true);
            setDollarsError(error.dollars[0]);
        } else {
            setDollarsHasError(false);
            setDollarsError('');
        }

        if(error.pesos){
            setPesosHasError(true);
            setPesosError(error.pesos[0]);
        } else {
            setPesosHasError(false);
            setPesosError('');
        }

    };

    // Función que se ejecuta al enviar el formulario
    const handleSubmit = async () => {

        // Validar campos
        let valid = validate({ patient, purchasedAt, price, dollars, pesos, amount, medicine }, constraints, {fullMessages: false});

        // Si la validación pasó
        if(valid === undefined){

            // Mostrar el indicador de carga
            setIsLoading(true);

            try {

                let requestData = {
                    patient_id: patient,
                    medicine_id: medicine,
                    purchased_at: purchasedAt,
                    price,
                    amount,
                    total: amount * price,
                    dollars,
                    pesos
                };

                await axios.post(
                    `${API_URL}/purchases`,
                    requestData
                );

                resetForm();

                setSnackbarMessage('Compra registrada exitosamente.');
                setSnackbarOpen(true);

            } catch (error) {

                switch(error.response.status){
                    case 400:
                        // Mostrar mensaje
                        setSnackbarMessage('El contenido enviado no cumplió las reglas de validación');
                        setSnackbarOpen(true);
                        handleServerError(error.response.data.errors);
                        break;
                    case 422:
                        // Mostrar mensaje
                        setSnackbarMessage('El contenido enviado no cumplió las reglas de validación');
                        setSnackbarOpen(true);
                        handleServerError(error.response.data.errors);
                        break;
                    default:
                        // Mostrar mensaje
                        setSnackbarMessage('Ocurrió un error registrando la compra.');
                        setSnackbarOpen(true);
                        break;
                }

            }

            // Esconder indicador de carga
            setIsLoading(false);

        } else {

            if(valid.patient){
                setPatientHasError(true);
                setPatientError(valid.patient[0]);
            } else {
                setPatientHasError(false);
                setPatientError('');
            }

            if(valid.medicine){
                setMedicineHasError(true);
                setMedicineError(valid.medicine[0]);
            } else {
                setMedicineHasError(false);
                setMedicineError('');
            }

            if(valid.purchasedAt){
                setPurchasedAtHasError(true);
                setPurchasedAtError(valid.purchasedAt[0]);
            } else {
                setPurchasedAtHasError(false);
                setPurchasedAtError('');
            }

            if(valid.price){
                setPriceHasError(true);
                setPriceError(valid.price[0]);
            } else {
                setPriceHasError(false);
                setPriceError('');
            }

            if(valid.amount){
                setAmountHasError(true);
                setAmountError(valid.amount[0]);
            } else {
                setAmountHasError(false);
                setAmountError('');
            }

            if(valid.dollars){
                setDollarsHasError(true);
                setDollarsError(valid.dollars[0]);
            } else {
                setDollarsHasError(false);
                setDollarsError('');
            }

            if(valid.pesos){
                setPesosHasError(true);
                setPesosError(valid.pesos[0]);
            } else {
                setPesosHasError(false);
                setPesosError('');
            }
        }
    };

    // Función que vacia el formulario
    const resetForm = () => {

        // Vaciar campos del formulario
        setPurchasedAt(null);
        setPrice('');
        setDollars(0);
        setPesos(0);
        setAmount(1);
        setTotal('');
        setPatient('');
    };

    useEffect(
        () => {

            const searchPatients = async () => {

                // Make sure we have a value (user has entered something in input)
                if (debouncedSearchTerm) {

                    // Set isSearching state
                    setIsSearching(true);

                    try {

                        const data = {
                            active: true,
                            direction: 'asc',
                        };

                        if(isNaN(debouncedSearchTerm)) {
                            data.name = debouncedSearchTerm;
                            data.sort = 'special';
                        } else {
                            data.code = debouncedSearchTerm;
                            data.sort = 'code';
                        }

                        const response = await axios.get(
                            `${API_URL}/patients`, {
                                params: data
                            }
                        );

                        const patientsData = response.data;

                        setPatients(patientsData);

                    } catch (error) {

                        setSnackbarMessage('Ocurrió un error cargando los pacientes.');
                        setSnackbarOpen(true);

                    }

                    setIsSearching(false);

                } else {
                    setPatients([]);
                }

            };

            searchPatients();

        },
        // This is the useEffect input array
        // Our useEffect function will only execute if this value changes ...
        // ... and thanks to our hook it will only change if the original ...
        // value (searchTerm) hasn't changed for more than 500ms.
        [debouncedSearchTerm]
    );

    const handleMedicineChange = (id) => {
        setMedicine(id);

        const selectedMed = medicines.find(medicine => medicine.id === id);

        setPrice(selectedMed.price);
    };

    const medicinesOptions = medicines.map(medicine => (
        <MenuItem key={medicine.id} value={medicine.id}>{ medicine.name }</MenuItem>
    ));

    return (
        <div className={classes.root}>
            {
                isLoading ?
                    <div className={ classes.progress }>
                        <CircularProgress />
                    </div> :
                    <Paper className={classes.paper}>
                        <Toolbar className={classes.padding}>
                            <IconButton className={ classes.backButton } onClick={() => history.goBack() }>
                                <ArrowBackIcon color="secondary" />
                            </IconButton>
                            <Typography className={classes.title} variant="h6" id="title">Compra</Typography>
                        </Toolbar>
                        <div>
                            <div className={classes.padding}>
                                <Autocomplete
                                    id="patient"
                                    open={open}
                                    fullWidth
                                    onOpen={() => {
                                        setOpen(true);
                                    }}
                                    onClose={() => {
                                        setOpen(false);
                                    }}
                                    onChange={(event, value) => setPatient(value.id)}
                                    onInputChange={(event) => {
                                        setSearchTerm(event.target.value)
                                    }}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => `${option.name} (#${option.code})`}
                                    options={patients}
                                    loading={isSearching}
                                    renderInput={(params) => (
                                        <TextField variant="outlined"
                                                   {...params}
                                                   error={patientHasError}
                                                   helperText={patientError}
                                                   className={classes.textField}
                                                   label="Paciente"
                                                   InputProps={{
                                                       ...params.InputProps,
                                                       endAdornment: (
                                                           <React.Fragment>
                                                               {isSearching ? <CircularProgress color="inherit"
                                                                                                size={20}/> : null}
                                                               {params.InputProps.endAdornment}
                                                           </React.Fragment>
                                                       ),
                                                   }}
                                        />
                                    )}
                                />
                                <KeyboardDatePicker
                                    error={purchasedAtHasError}
                                    invalidDateMessage={ purchasedAtError }
                                    maxDateMessage={ purchasedAtError }
                                    variant="inline"
                                    format="DD-MM-YYYY"
                                    style={{marginTop: 0, marginBottom: 16}}
                                    fullWidth
                                    id="purchasedAt"
                                    label="Fecha de Compra"
                                    value={purchasedAt}
                                    onChange={(date) => setPurchasedAt(date !== null ? date.format('YYYY-MM-DD') : '')}
                                    KeyboardButtonProps={{
                                        'aria-label': 'cambiar fecha',
                                    }}
                                />
                                <FormControl className={ classes.formControl }>
                                    <InputLabel id="medicine_label">Producto</InputLabel>
                                    <Select
                                        labelId="medicine_label"
                                        id="medicine"
                                        MenuProps={MenuProps}
                                        value={medicine}
                                        onChange={(event) => handleMedicineChange(event.target.value)}
                                    >
                                        { medicinesOptions }
                                    </Select>
                                    <FormHelperText>{ medicineError }</FormHelperText>
                                </FormControl>
                                <Grid container spacing={3}>
                                    <Grid item sm={4} xs={12}>
                                        <TextField
                                            id="amount"
                                            label="Cantidad"
                                            type="number"
                                            variant="outlined"
                                            fullWidth
                                            className={ classes.textField }
                                            value={ amount }
                                            onChange={ (event) => setAmount(event.target.value) }
                                            error={ amountHasError }
                                            helperText={ amountError }
                                        />
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                        <TextField
                                            id="price"
                                            label="Costo"
                                            type="number"
                                            variant="outlined"
                                            fullWidth
                                            className={ classes.textField }
                                            value={ price }
                                            onChange={ (event) => setPrice(event.target.value) }
                                            error={ priceHasError }
                                            helperText={ priceError }
                                        />
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                        <TextField
                                            id="total"
                                            label="Total"
                                            type="number"
                                            variant="outlined"
                                            disabled
                                            fullWidth
                                            className={ classes.textField }
                                            value={ price && amount ? price * amount : '' }
                                            error={ totalHasError }
                                            helperText={ totalError }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item sm={6} xs={12}>
                                        <TextField
                                            id="dollars"
                                            label="Dólares"
                                            type="number"
                                            variant="outlined"
                                            fullWidth
                                            className={ classes.textField }
                                            value={ dollars }
                                            onChange={ (event) => setDollars(event.target.value) }
                                            error={ dollarsHasError }
                                            helperText={ dollarsError }
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <TextField
                                            id="pesos"
                                            label="Pesos"
                                            type="number"
                                            variant="outlined"
                                            fullWidth
                                            className={ classes.textField }
                                            value={ pesos }
                                            onChange={ (event) => setPesos(event.target.value) }
                                            error={ pesosHasError }
                                            helperText={ pesosError }
                                        />
                                    </Grid>
                                </Grid>
                                <div className={ classes.buttonWrapper }>
                                    <Button color="secondary" onClick={ handleSubmit }>Crear</Button>
                                </div>
                            </div>
                        </div>
                    </Paper>
            }
            <Snack message={ snackbarMessage } open={ snackbarOpen } handleClose={ () => setSnackbarOpen(false) } />
        </div>
    )
};

export default PurchaseCreate;
