import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import validate from 'validate.js'
import Snack from "../../../components/Snack/Snack";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import {NavLink} from "react-router-dom";
import axios from "axios";
import Dropzone from "react-dropzone";
import clsx from "clsx";
import ImageIcon from '@material-ui/icons/Image';
import ClearIcon from '@material-ui/icons/Clear';
import Tooltip from "@material-ui/core/Tooltip";
import {API_URL} from "../../../config";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    padding: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    title: {
        flex: '1 1 100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backButton: {
        marginRight: 8
    },
    dropZone: {
        flex: 1,
        display: 'flex',
        cursor: 'pointer',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(4),
        padding: theme.spacing(3),
        borderWidth: 2,
        borderRadius: 2,
        borderColor: 'rgba(0,0,0,0.54)',
        borderStyle: 'dashed',
        color: 'rgba(0,0,0,0.54)',
        outline: 'none',
        transition: 'border .14s ease-in-out',
        '&:hover':{
            borderColor: theme.palette.primary.main,
        },
        '&:hover $dropzoneIcon': {
            color: theme.palette.primary.main
        }
    },
    fileError: {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main
    },
    dropzoneIcon: {
        color: 'rgba(0,0,0,0.54)',
        transition: 'color .14s ease-in-out',
    },
    iconError: {
        color: theme.palette.error.main
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    thumbsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
    },
    thumb: {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 100,
        height: 100,
        padding: 4,
        boxSizing: 'border-box'
    },
    thumbInner: {
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: 0,
        overflow: 'hidden',
        '&:hover $imageRemove': {
            opacity: 1
        }
    },
    img: {
        display: 'block',
        width: 'auto',
        height: '100%'
    },
    imageRemove: {
        position: 'absolute',
        display: 'flex',
        opacity: 0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        transition: 'opacity 300ms'
    },
    removeIcon: {
        flex: 1,
        cursor: 'pointer'
    }
}));

const MedicinesCreate = () => {

    const classes = useStyles();

    /* *** State de formulario *** */

    // State de nombre
    const [name, setName] = useState('');
    const [nameHasError, setNameHasError] = useState(false);
    const [nameError, setNameError] = useState('');

    // State de descripción
    const [description, setDescription] = useState('');
    const [descriptionHasError, setDescriptionHasError] = useState(false);
    const [descriptionError, setDescriptionError] = useState('');

    // State de descripción en inglés
    const [descriptionEn, setDescriptionEn] = useState('');
    const [descriptionEnHasError, setDescriptionEnHasError] = useState(false);
    const [descriptionEnError, setDescriptionEnError] = useState('');

    // State de dosis
    const [dosis, setDosis] = useState('');
    const [dosisHasError, setDosisHasError] = useState(false);
    const [dosisError, setDosisError] = useState('');

    // State de dosis en inglés
    const [dosisEn, setDosisEn] = useState('');
    const [dosisEnHasError, setDosisEnHasError] = useState(false);
    const [dosisEnError, setDosisEnError] = useState('');

    // State de precio
    const [price, setPrice] = useState('');
    const [priceHasError, setPriceHasError] = useState(false);
    const [priceError, setPriceError] = useState('');

    // State de imagen
    const [files, setFiles] = useState([]);
    const [fileHasError, setFileHasError] = useState(false);
    const [fileErrorMessage, setFileErrorMessage] = useState('');

    /* *** State de formulario *** */

    // State de snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(false);

    // Reglas de validación de formulario
    const constraints = {
        name: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            }
        },
        description: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            }
        },
        descriptionEn: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            }
        },
        dosis: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            }
        },
        dosisEn: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            }
        },
        price: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            numericality: {
                strict: true,
                greaterThanOrEqualTo: 0,
                notValid: "El valor ingresado no es válido",
                notGreaterThanOrEqualTo: "El valor debe ser mayor o igual a 0"
            }
        }
    };

    const handleServerError = (error) => {

        if(error.description){
            setDescriptionHasError(true);
            setDescriptionError(error.description[0]);
        } else {
            setDescriptionHasError(false);
            setDescriptionError('');
        }

        if(error.name){
            setNameHasError(true);
            setNameError(error.name[0]);
        } else {
            setNameHasError(false);
            setNameError('');
        }

        if(error.description_en){
            setDescriptionEnHasError(true);
            setDescriptionEnError(error.description_en[0]);
        } else {
            setDescriptionEnHasError(false);
            setDescriptionEnError('');
        }

        if(error.dosis){
            setDosisHasError(true);
            setDosisError(error.dosis[0]);
        } else {
            setDosisHasError(false);
            setDosisError('');
        }

        if(error.dosis_en){
            setDosisEnHasError(true);
            setDosisEnError(error.dosis_en[0]);
        } else {
            setDosisEnHasError(false);
            setDosisEnError('');
        }

        if(error.price){
            setPriceHasError(true);
            setPriceError(error.price[0]);
        } else {
            setPriceHasError(false);
            setPriceError('');
        }

        if(error.image){
            setFileHasError(true);
            setFileErrorMessage(error.image[0]);
        } else {
            setFileHasError(false);
            setFileErrorMessage('');
        }
    };

    // Función que se ejecuta al enviar el formulario
    const handleSubmit = async () => {

        // Validar campos
        let valid = validate({ name, description, descriptionEn, dosis, dosisEn, price, files }, constraints, {fullMessages: false});

        // Si la validación pasó
        if(valid === undefined){

            // Mostrar el indicador de carga
            setIsLoading(true);

            try {

                const config = {
                    headers: { 'Content-Type': 'multipart/form-data' }
                };

                let formData = new FormData();
                formData.append('name', name);
                formData.append('description', description);
                formData.append('description_en', descriptionEn);
                formData.append('dosis', dosis);
                formData.append('dosis_en', dosisEn);
                formData.append('price', price);

                if(files.length > 0)
                    formData.append('file', files[0]);

                const response = await axios.post(
                    `${API_URL}/medicines`,
                    formData,
                    config
                );

                // Vaciar el formulario
                resetForm();

                // Mostrar mensaje
                setSnackbarMessage('Medicina registrada exitosamente');
                setSnackbarOpen(true);

            } catch (error) {

                switch(error.response.status){
                    case 400:
                        // Mostrar mensaje
                        setSnackbarMessage('El contenido enviado no cumplió las reglas de validación');
                        setSnackbarOpen(true);
                        handleServerError(error.response.data.errors);
                        break;
                    case 422:
                        // Mostrar mensaje
                        setSnackbarMessage('El contenido enviado no cumplió las reglas de validación');
                        setSnackbarOpen(true);
                        handleServerError(error.response.data.errors);
                        break;
                    default:
                        // Mostrar mensaje
                        setSnackbarMessage('Ocurrió un error registrando la medicina');
                        setSnackbarOpen(true);
                        break;
                }

            }

            // Esconder indicador de carga
            setIsLoading(false);

        } else {

            // Si existe un error con el campo, mostrarlo
            if(valid.name){
                setNameHasError(true);
                setNameError(valid.name[0]);
            } else {
                setNameHasError(false);
                setNameError('');
            }

            if(valid.description){
                setDescriptionHasError(true);
                setDescriptionError(valid.description[0]);
            } else {
                setDescriptionHasError(false);
                setDescriptionError('');
            }

            if(valid.descriptionEn){
                setDescriptionEnHasError(true);
                setDescriptionEnError(valid.descriptionEn[0]);
            } else {
                setDescriptionEnHasError(false);
                setDescriptionEnError('');
            }

            if(valid.dosis){
                setDosisHasError(true);
                setDosisError(valid.dosis[0]);
            } else {
                setDosisHasError(false);
                setDosisError('');
            }

            if(valid.dosisEn){
                setDosisEnHasError(true);
                setDosisEnError(valid.dosisEn[0]);
            } else {
                setDosisEnHasError(false);
                setDosisEnError('');
            }

            if(valid.price){
                setPriceHasError(true);
                setPriceError(valid.price[0]);
            } else {
                setPriceHasError(false);
                setPriceError('');
            }

            if(valid.files){
                setFileHasError(true);
                setFileErrorMessage(valid.files[0]);
            } else {
                setFileHasError(false);
                setFileErrorMessage('');
            }

        }



    };

    // Función que vacia el formulario
    const resetForm = () => {

        // Vaciar campos del formulario
        setName('');
        setDescription('');
        setDescriptionEn('');
        setDosis('');
        setDosisEn('');
        setPrice('');
        setFiles([]);
    };

    // Función que se ejecuta cuando se selecciona un archivo
    const handleFileSelect = (selectedFiles) => {

        setFiles(selectedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));

        setFileHasError(false);
        setFileErrorMessage('');
    };

    // Función que se ejecuta cuando se selecciona un archivo inválido
    const handleFileError = (rejectedFiles) => {

        // Actualizar state
        setFiles([]);

        // Dependiendo del error mostrar mensaje
        if(!['image/png', 'image/jpg', 'image/jpeg'].includes(rejectedFiles[0].type))
            setFileErrorMessage('El tipo de archivo no es permitido');
        else if(rejectedFiles[0].size > 500000)
            setFileErrorMessage('La imagen excede el tamaño permitido de 500KB');
        else
            setFileErrorMessage('Ocurrió un error desconocido cargando la imagen');

        // Actualizar state
        setFileHasError(true);
    };

    const thumbs = files.map(file => (
        <div className={ classes.thumb } key={file.name}>
            <Tooltip title={ file.name }>
                <div className={ classes.thumbInner }>
                    <div className={ classes.imageRemove }>
                        <ClearIcon
                            fontSize="large"
                            style={ { color: 'white' } }
                            className={ classes.removeIcon }
                            onClick={ () => setFiles([]) }
                        />
                    </div>
                    <img
                        src={file.preview}
                        className={ classes.img }
                    />

                </div>
            </Tooltip>
        </div>
    ));

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    return (
        <div className={classes.root}>
            {
                isLoading ?
                    <div className={ classes.progress }>
                        <CircularProgress />
                    </div> :
                    <Paper className={classes.paper}>
                        <Toolbar className={classes.padding}>
                            <IconButton className={ classes.backButton } component={ NavLink } to="/medicines">
                                <ArrowBackIcon color="secondary" />
                            </IconButton>
                            <Typography className={classes.title} variant="h6" id="title">Medicina</Typography>
                        </Toolbar>
                        <div>
                            <div className={classes.padding}>
                                <TextField variant="outlined"
                                    id="name"
                                    label="Nombre"
                                    fullWidth
                                    className={ classes.textField }
                                    value={ name }
                                    onChange={ (event) => setName(event.target.value) }
                                    error={ nameHasError }
                                    helperText={ nameError }
                                />
                                <TextField variant="outlined"
                                    id="description"
                                    label="Descripción"
                                    fullWidth
                                    className={ classes.textField }
                                    value={ description }
                                    onChange={ (event) => setDescription(event.target.value) }
                                    error={ descriptionHasError }
                                    helperText={ descriptionError }
                                />
                                <TextField variant="outlined"
                                    id="descriptionEn"
                                    label="Descripción (Inglés)"
                                    fullWidth
                                    className={ classes.textField }
                                    value={ descriptionEn }
                                    onChange={ (event) => setDescriptionEn(event.target.value) }
                                    error={ descriptionEnHasError }
                                    helperText={ descriptionEnError }
                                />
                                <TextField variant="outlined"
                                    id="dosis"
                                    label="Dosis"
                                    fullWidth
                                    className={ classes.textField }
                                    value={ dosis }
                                    onChange={ (event) => setDosis(event.target.value) }
                                    error={ dosisHasError }
                                    helperText={ dosisError }
                                />
                                <TextField variant="outlined"
                                    id="dosisEn"
                                    label="Dosis (Inglés)"
                                    fullWidth
                                    className={ classes.textField }
                                    value={ dosisEn }
                                    onChange={ (event) => setDosisEn(event.target.value) }
                                    error={ dosisEnHasError }
                                    helperText={ dosisEnError }
                                />
                                <TextField variant="outlined"
                                    id="price"
                                    label="Precio"
                                    fullWidth
                                    type="number"
                                    className={ classes.textField }
                                    value={ price }
                                    onChange={ (event) => setPrice(event.target.value) }
                                    error={ priceHasError }
                                    helperText={ priceError }
                                />
                                <Dropzone
                                    onDropAccepted={ acceptedFiles => handleFileSelect(acceptedFiles) }
                                    onDropRejected={ rejectedFiles => handleFileError(rejectedFiles) }
                                    accept={ ['image/jpg', 'image/jpeg', 'image/png'] }
                                    maxSize={500000}
                                    multiple={ false }
                                >
                                    {({getRootProps, getInputProps}) => (
                                        <section className={ clsx(classes.dropZone, fileHasError && classes.fileError) } {...getRootProps()}>

                                            <ImageIcon className={ clsx(classes.dropzoneIcon, fileHasError && classes.iconError ) } fontSize="large" />
                                            <input {...getInputProps()} />
                                            {
                                                fileHasError ?
                                                    <p>{ fileErrorMessage }</p> :
                                                    <p>Arrastra una imagen o da click para seleccionar una imagen</p>
                                            }
                                        </section>
                                    )}
                                </Dropzone>
                                <aside className={ classes.thumbsContainer }>
                                    {thumbs}
                                </aside>
                                <div className={ classes.buttonWrapper }>
                                    <Button color="secondary" onClick={ handleSubmit }>Crear</Button>
                                </div>
                            </div>
                        </div>
                    </Paper>
            }
            <Snack message={ snackbarMessage } open={ snackbarOpen } handleClose={ () => setSnackbarOpen(false) } />
        </div>
    )
};

export default MedicinesCreate;
