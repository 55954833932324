import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import * as moment from 'moment';
import Snack from "../../../components/Snack/Snack";
import MaterialTable from "material-table";
import axios from 'axios';
import {API_URL, PUBLIC_URL, tableIcons} from "../../../config";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {Add, Edit, AssistantPhoto} from "@material-ui/icons";
import {useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginBottom: 30
    },
    toolbar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    action: {
        marginRight: 8
    },
    progressBar: {
        width: '100%'
    }
}));

const CityIndex = ({ history }) => {

    const classes = useStyles();

    const authState = useSelector((state) => state.authReducer);

    // State de ciudades
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    // State de tabla
    const [selected, setSelected] = useState([]);

    // State de snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(true);

    // State de diálogo
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);

    useEffect( () => {

        const init = async () => {
            await loadStates();
            await loadCities();
        };

        init()

    }, []);

    // Función que carga los estados
    const loadStates = async () => {

        // Mostrar indicador de carga
        setIsLoading(true);

        try {

            // Hacer request
            const response = await axios.get(`${API_URL}/states`);

            // Actualizar state
            setStates(response.data);

        } catch(error){

            // Mostrar mensaje de error
            setSnackbarMessage('Ocurrió un error cargando los estados');
            setSnackbarOpen(true);

        }

        // Esconder indicador de carga
        setIsLoading(false);
    };

    // Función que carga las ciudades
    const loadCities = async () => {

        // Mostrar indicador de carga
        setIsLoading(true);

        try {

            // Hacer request
            const response = await axios.get(`${API_URL}/cities`);

            // Actualizar state
            setCities(response.data);

        } catch(error){

            // Mostrar mensaje de error
            setSnackbarMessage('Ocurrió un error cargando las ciudades');
            setSnackbarOpen(true);

        }

        // Esconder indicador de carga
        setIsLoading(false);
    };

    // Función que se ejecuta al enviar pulsar el botón de desactivar
    const handleDeactivate = async () => {

        // Esconder el diálogo
        setIsDialogOpen(false);

        // Mostrar el indicador de carga
        setIsLoading(true);

        try {

            // Total de seleccionados
            let count = selected.length;

            // Contadores para mensaje
            let deactivatedCount = 0;
            let activatedCount = 0;

            // Por cada ciudad seleccionada
            for(let i = 0; i < count; i++){

                // Cambiar estado
                await axios.delete(`${API_URL}/cities/${selected[i].id}`);

                // Actualizar contador
                if(selected[i].is_active)
                    deactivatedCount += 1;
                else
                    activatedCount += 1;
            }

            if(deactivatedCount > 1 && activatedCount > 1){
                // Mostrar mensaje
                setSnackbarMessage(`${deactivatedCount} ciudades desactivadas y ${activatedCount} ciudades activadas exitosamente`);
                setSnackbarOpen(true);
            } else if(deactivatedCount === 1 && activatedCount === 1){
                // Mostrar mensaje
                setSnackbarMessage(`1 ciudad desactivada y 1 ciudad activada exitosamente`);
                setSnackbarOpen(true);
            } else if(deactivatedCount > 1 && activatedCount === 0){
                // Mostrar mensaje
                setSnackbarMessage(`${deactivatedCount} ciudades desactivadas exitosamente`);
                setSnackbarOpen(true);
            } else if(deactivatedCount === 1 && activatedCount === 0){
                // Mostrar mensaje
                setSnackbarMessage(`1 ciudad desactivada exitosamente`);
                setSnackbarOpen(true);
            } else if(deactivatedCount === 0 && activatedCount > 1){
                // Mostrar mensaje
                setSnackbarMessage(`${activatedCount} ciudades activadas exitosamente`);
                setSnackbarOpen(true);
            } else if(deactivatedCount === 0 && activatedCount === 1){
                // Mostrar mensaje
                setSnackbarMessage(`1 ciudad activada exitosamente`);
                setSnackbarOpen(true);
            } else if(deactivatedCount === 1 && activatedCount > 1){
                // Mostrar mensaje
                setSnackbarMessage(`1 ciudad desactivada y ${activatedCount} ciudades activadas exitosamente`);
                setSnackbarOpen(true);
            } else {
                // Mostrar mensaje
                setSnackbarMessage(`${deactivatedCount} ciudades desactivadas y 1 ciudad activada exitosamente`);
                setSnackbarOpen(true);
            }

            setSelected([]);

            await loadCities();

        } catch (error) {

            // Mostrar mensaje
            setSnackbarMessage('Ocurrió un error desactivando o activando los ciudades');
            setSnackbarOpen(true);
        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    return (
        <div className={classes.root}>
            <MaterialTable
                title="Ciudades"
                icons={ tableIcons }
                isLoading={ isLoading }
                columns={
                    [
                        { title: 'Nombre', field: 'name', filtering: false },
                        {
                            title: 'Estado',
                            field: 'state_id',
                            filtering: false,
                            render: rowData => {
                                return states.find(state => state.id === rowData.state_id).name;
                            }
                        },
                        {
                            title: 'Creado',
                            field: 'created_at',
                            filtering: false,
                            render: rowData => {
                                return moment(rowData.created_at).format('D/MMM/YY');
                            }
                        },
                        {
                            title: 'Estado',
                            field: 'is_active',
                            type: 'boolean',
                        },
                    ]
                }
                data={ cities }
                actions={[
                    {
                        icon: Add,
                        tooltip: 'Agregar ciudad',
                        isFreeAction: true,
                        onClick: (event) => history.push('/cities/create')
                    },
                    authState.roleId === 1 ?
                        {
                            icon: AssistantPhoto,
                            tooltip: 'Desactivar o activar ciudad(es)',
                            onClick: (event, rowData) => setIsDialogOpen(true),
                        } :
                        null
                ]}
                options={{
                    selection: true,
                    filtering: true,
                    exportButton: true,
                    exportFileName: `Ciudades_Clinic_${ moment().format('YYYY_MM_DD')}`,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [10, 25, 50],
                    pageSize: 10,
                }}
                onSelectionChange={(rows) => setSelected(rows)}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} de {count}',
                        nextTooltip: 'Siguiente',
                        lastTooltip: 'Última',
                        firstTooltip: 'Primera',
                        previousTooltip: 'Anterior',
                        labelRowsPerPage: 'Filas por página:',
                        labelRowsSelect: 'filas'
                    },
                    toolbar: {
                        nRowsSelected: '{0} fila(s) seleccionada(s)',
                        searchTooltip: 'Buscar',
                        searchPlaceholder: 'Buscar',
                        exportTitle: 'Exportar',
                        exportName: 'Exportar como CSV'
                    },
                    header: {
                        actions: 'Acciones'
                    },
                    body: {
                        emptyDataSourceMessage: 'No hay registros disponibles',
                        filterRow: {
                            filterTooltip: 'Filtrar'
                        }
                    }
                }}
            />
            <Dialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">"Desactivar o activar ciudad(es)"</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Está seguro que desea desactivar o activar los ciudades seleccionadas?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsDialogOpen(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleDeactivate} color="primary" autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
            <Snack message={ snackbarMessage } open={ snackbarOpen } handleClose={ () => setSnackbarOpen(false) } />
        </div>
    );

};

export default CityIndex;
