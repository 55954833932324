import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import validate from 'validate.js'
import Snack from "../../../components/Snack/Snack";
import {signIn} from "../../../store/actions/auth";
import {connect} from "react-redux";
import axios from "axios";
import {API_URL, APP_URL} from "../../../config";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

const SignIn = ({ authMessage, signIn, readError }) => {

    const classes = useStyles();

    // State de formulario
    const [email, setEmail] = useState('');
    const [emailHasError, setEmailHasError] = useState(false);
    const [emailError, setEmailError] = useState('');

    const [password, setPassword] = useState('');
    const [passwordHasError, setPasswordHasError] = useState(false);
    const [passwordError, setPasswordError] = useState('');

    // State de snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(false);

    // Reglas de validación de formulario
    const constraints = {
        email: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            email: {
                message: "Debe de ingresar un correo válido"
            }
        },
        password: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            }
        }
    };

    document.body.style.backgroundColor = '#1a237e';

    // Función que intenta iniciar la sesión
    const handleSubmit = async () => {

        // Mostrar indicador de carga
        setIsLoading(true);

        resetErrors();

        // Validar campos
        let valid = validate({ email, password }, constraints, {fullMessages: false});

        // Si la validación pasó
        if(valid === undefined){

            try {

                await axios.get(`${APP_URL}/sanctum/csrf-cookie`);

                const response = await axios.post(API_URL + "/login", {
                    email: email,
                    password: password,
                });

                signIn(response.data.user.id, response.data.user.name, response.data.role.id);

            } catch (error) {

                // Mostrar mensaje
                setSnackbarMessage('No se pudo iniciar la sesión');
                setSnackbarOpen(true);

            }

        } else {

            // Si existe un error con el campo, mostrarlo
            if(valid.email){
                setEmailHasError(true);
                setEmailError(valid.email[0]);
            }

            // Si existe un error con el campo, mostrarlo
            if(valid.password){
                setPasswordHasError(true);
                setPasswordError(valid.password[0]);
            }

        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    const resetErrors = () => {
        setEmailError('');
        setEmailHasError(false);
        setPasswordError('');
        setPasswordHasError(false);
    };

    return (
        isLoading ?
            <div className={ classes.progress }>
                <CircularProgress color="secondary" />
            </div> :
            <Container component="main" maxWidth="xs">
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Iniciar Sesión
                    </Typography>
                    <div className={classes.form} >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="Correo electrónico"
                            autoFocus
                            value={ email }
                            onChange={ (event) => setEmail(event.target.value) }
                            error={ emailHasError }
                            helperText={ emailError }
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="Contraseña"
                            type="password"
                            value={ password }
                            onChange={ (event) => setPassword(event.target.value) }
                            error={ passwordHasError }
                            helperText={ passwordError }
                        />
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={ handleSubmit }
                        >
                            Iniciar
                        </Button>
                    </div>
                </Paper>
                <Snack message={ snackbarMessage } open={ snackbarOpen } handleClose={ () => setSnackbarOpen(false) } />
            </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        authMessage: state.authReducer.authMessage,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (userId, name, roleId) =>
            dispatch(signIn(userId, name, roleId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
