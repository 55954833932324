import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import * as moment from 'moment';
import Snack from "../../../components/Snack/Snack";
import MaterialTable from "material-table";
import axios from 'axios';
import {API_URL, PUBLIC_URL, tableIcons} from "../../../config";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import ImageIcon from '@material-ui/icons/Image';
import Tooltip from "@material-ui/core/Tooltip";
import {Add, Edit, AssistantPhoto} from "@material-ui/icons";
import InfoIcon from '@material-ui/icons/Info';
import {useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginBottom: 30
    },
    toolbar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    action: {
        marginRight: 8
    },
    progressBar: {
        width: '100%'
    }
}));

const PatientIndex = ({ history }) => {

    const classes = useStyles();

    const authState = useSelector((state) => state.authReducer);

    // State de tabla
    const [selected, setSelected] = useState([]);

    // State de snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(true);

    // State de diálogo
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);

    let [columnObject] = useState([
        { title: 'Expediente', field: 'code', filtering: false},
        {
            title: 'Nombre',
            field: 'name',
            render: rowData =>
                (<div style={{minWidth: '250px'}}>  {rowData.name}  </div>)
        },
        {
            title: 'Teléfono',
            field: 'phone',
            filtering: false,
            render: rowData =>
                (<div style={{minWidth: '150px'}}>  {rowData.phone}  </div>)
        },
        { title: 'Correo electrónico', field: 'email', filtering: false },
        { title: 'Ciudad', field: 'city' },
        { title: 'Precio', field: 'price', filtering: false, render: rowData => rowData.price && ('$' + rowData.price)},
        {
            title: 'Detalles',
            field: 'id',
            render: rowData => {
                return <Tooltip title="Ver detalles">
                    <IconButton onClick={ () => history.push(`/patients/${rowData.id}`)}>
                        <InfoIcon color="secondary" />
                    </IconButton>
                </Tooltip>
            },
            filtering: false
        },
        {
            title: 'Creado',
            field: 'created_at',
            defaultSort: 'desc',
            filtering: false,
            render: rowData => {
                return (<div style={{minWidth: '100px'}}>  {moment(rowData.created_at).format('D/MMM/YY')}  </div>)
            }
        },
        {
            title: 'Estado',
            field: 'is_active',
            type: 'boolean',
        },
    ]);

    const tableRef = useRef(null);

    // Función que se ejecuta al enviar pulsar el botón de desactivar
    const handleDeactivate = async () => {

        // Esconder el diálogo
        setIsDialogOpen(false);

        // Mostrar el indicador de carga
        setIsLoading(true);

        try {

            // Total de seleccionados
            let count = selected.length;

            // Contadores para mensaje
            let deactivatedCount = 0;
            let activatedCount = 0;

            // Por cada categoría seleccionada
            for(let i = 0; i < count; i++){

                // Cambiar estado
                await axios.delete(`${API_URL}/patients/${selected[i].id}`);

                // Actualizar contador
                if(selected[i].is_active)
                    deactivatedCount += 1;
                else
                    activatedCount += 1;
            }

            if(deactivatedCount > 1 && activatedCount > 1){
                // Mostrar mensaje
                setSnackbarMessage(`${deactivatedCount} pacientes desactivados y ${activatedCount} pacientes activados exitosamente`);
                setSnackbarOpen(true);
            } else if(deactivatedCount === 1 && activatedCount === 1){
                // Mostrar mensaje
                setSnackbarMessage(`1  paciente desactivado y 1  paciente activado exitosamente`);
                setSnackbarOpen(true);
            } else if(deactivatedCount > 1 && activatedCount === 0){
                // Mostrar mensaje
                setSnackbarMessage(`${deactivatedCount} pacientes desactivados exitosamente`);
                setSnackbarOpen(true);
            } else if(deactivatedCount === 1 && activatedCount === 0){
                // Mostrar mensaje
                setSnackbarMessage(`1  paciente desactivado exitosamente`);
                setSnackbarOpen(true);
            } else if(deactivatedCount === 0 && activatedCount > 1){
                // Mostrar mensaje
                setSnackbarMessage(`${activatedCount} pacientes activados exitosamente`);
                setSnackbarOpen(true);
            } else if(deactivatedCount === 0 && activatedCount === 1){
                // Mostrar mensaje
                setSnackbarMessage(`1  paciente activado exitosamente`);
                setSnackbarOpen(true);
            } else if(deactivatedCount === 1 && activatedCount > 1){
                // Mostrar mensaje
                setSnackbarMessage(`1  paciente desactivado y ${activatedCount} pacientes activados exitosamente`);
                setSnackbarOpen(true);
            } else {
                // Mostrar mensaje
                setSnackbarMessage(`${deactivatedCount} pacientes desactivados y 1  paciente activado exitosamente`);
                setSnackbarOpen(true);
            }

            setSelected([]);

            tableRef.current.onQueryChange();

        } catch (error) {

            // Mostrar mensaje
            setSnackbarMessage('Ocurrió un error desactivando o activando los pacientes');
            setSnackbarOpen(true);
        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    const loadTableData = (query) => {

        return new Promise(async (resolve, reject) => {

            setIsLoading(true);

            let url = `${API_URL}/patients?`;
            url += 'per_page=' + query.pageSize;
            url += '&page=' + (query.page + 1);

            if(query.orderBy && query.orderDirection){
                url += '&sort=' + query.orderBy.field;
                url += '&direction=' + query.orderDirection;
            }

            if(query.filters){
                for(let i = 0; i < query.filters.length; i++){
                    if(query.filters[i].column.field === 'is_active')
                        url += `&${query.filters[i].column.field}=${query.filters[i].value === 'checked' ? 1 : 0}`;
                    else
                        url += `&${query.filters[i].column.field}=${query.filters[i].value}`;
                }
            }

            if(query.search)
                url += '&search=' + query.search;

            try {

                const response = await axios.get(url);

                resolve({
                    data: response.data.data,
                    page: response.data.current_page - 1,
                    totalCount: response.data.total
                });

            } catch(error){
                reject(new Error('Ocurrió un error cargando los pacientes.'))
            }

            setIsLoading(false);
        }).catch((error) => {

            // Mostrar mensaje
            setSnackbarMessage('Ocurrió un error cargando los pacientes');
            setSnackbarOpen(true);

        });
    };

    return (
        <div className={classes.root}>
            <MaterialTable
                title="Pacientes"
                tableRef={tableRef}
                icons={ tableIcons }
                isLoading={ isLoading }
                columns={
                    columnObject
                }
                data={ (query) => loadTableData(query) }
                actions={[
                    {
                        icon: Add,
                        tooltip: 'Agregar paciente',
                        isFreeAction: true,
                        onClick: (event) => history.push('/patients/create')
                    },
                    {
                        icon: Edit,
                        tooltip: 'Editar paciente',
                        onClick: (event, rowData) => history.push(`/patients/${rowData[0].id}/edit`),
                        disabled: (selected.length > 1)
                    },
                    authState.roleId === 1 ?
                        {
                            icon: AssistantPhoto,
                            tooltip: 'Desactivar o activar paciente(s)',
                            onClick: (event, rowData) => setIsDialogOpen(true),
                        } :
                        null
                ]}
                options={{
                    selection: true,
                    filtering: true,
                    exportButton: true,
                    exportFileName: `Pacientes_Clinic_${ moment().format('YYYY_MM_DD')}`,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [10, 25, 50],
                    pageSize: 10,
                    debounceInterval: 500
                }}
                onSelectionChange={(rows) => setSelected(rows)}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} de {count}',
                        nextTooltip: 'Siguiente',
                        lastTooltip: 'Última',
                        firstTooltip: 'Primera',
                        previousTooltip: 'Anterior',
                        labelRowsPerPage: 'Filas por página:',
                        labelRowsSelect: 'filas'
                    },
                    toolbar: {
                        nRowsSelected: '{0} fila(s) seleccionada(s)',
                        searchTooltip: 'Buscar',
                        searchPlaceholder: 'Buscar',
                        exportTitle: 'Exportar',
                        exportName: 'Exportar como CSV'
                    },
                    header: {
                        actions: 'Acciones'
                    },
                    body: {
                        emptyDataSourceMessage: 'No hay registros disponibles',
                        filterRow: {
                            filterTooltip: 'Filtrar'
                        }
                    }
                }}
            />
            <Dialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">"Desactivar o activar paciente(s)"</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Está seguro que desea desactivar o activar los pacientes seleccionadas?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsDialogOpen(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleDeactivate} color="primary" autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
            <Snack message={ snackbarMessage } open={ snackbarOpen } handleClose={ () => setSnackbarOpen(false) } />
        </div>
    );

};

export default PatientIndex;
