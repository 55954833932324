import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import validate from 'validate.js'
import Snack from "../../../components/Snack/Snack";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import {NavLink} from "react-router-dom";
import axios from "axios";
import {API_URL} from "../../../config";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import Autocomplete from '@material-ui/lab/Autocomplete';
import useDebounce from "../../../hooks/useDebounce";
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import WarningIcon from "@material-ui/core/SvgIcon/SvgIcon";
import TransferDraggableList from "../../../components/TransferDraggableList/TransferDraggableList";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import Radio from "@material-ui/core/Radio/Radio";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import {PDFDownloadLink} from "@react-pdf/renderer";
import Diet from "../../../components/Reports/Diet";
import Dialog from "@material-ui/core/Dialog/Dialog";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    padding: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    title: {
        flex: '1 1 100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    foodWrapper: {
        marginBottom: 20
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    insideProgress: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backButton: {
        marginRight: 8
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
    chipWrapper: {
        marginBottom: 20
    },
    errorWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '100%'
    },
    subtitle: {
        color: theme.palette.primary.main,
        marginTop: 20
    }
}));

const PatientDietsCreate = ({ history, match }) => {

    const classes = useStyles();

    // ID del registro
    const patientId = match.params.id;

    /* *** State de formulario *** */

    const [startAt, setStartAt] = useState(null);
    const [startAtHasError, setStartAtHasError] = useState(false);
    const [startAtError, setStartAtError] = useState('');

    const [patient, setPatient] = useState('');
    const [patientHasError, setPatientHasError] = useState(false);
    const [patientError, setPatientError] = useState('');

    const [leyend, setLeyend] = useState('');
    const [leyendHasError, setLeyendHasError] = useState(false);
    const [leyendError, setLeyendError] = useState('');

    const [leyendEn, setLeyendEn] = useState('');
    const [leyendEnHasError, setLeyendEnHasError] = useState(false);
    const [leyendEnError, setLeyendEnError] = useState('');

    const [allowedFoodItems, setAllowedFoodItems] = useState([]);
    const [selectedAllowedFoodItems, setSelectedAllowedFoodItems] = useState([]);

    const [allowedDrinkItems, setAllowedDrinkItems] = useState([]);
    const [selectedAllowedDrinkItems, setSelectedAllowedDrinkItems] = useState([]);

    const [allowedAlcoholItems, setAllowedAlcoholItems] = useState([]);
    const [selectedAllowedAlcoholItems, setSelectedAllowedAlcoholItems] = useState([]);

    const [forbiddenFoodItems, setForbiddenFoodItems] = useState([]);
    const [selectedForbiddenFoodItems, setSelectedForbiddenFoodItems] = useState([]);

    const [forbiddenDrinkItems, setForbiddenDrinkItems] = useState([]);
    const [selectedForbiddenDrinkItems, setSelectedForbiddenDrinkItems] = useState([]);

    const [forbiddenAlcoholItems, setForbiddenAlcoholItems] = useState([]);
    const [selectedForbiddenAlcoholItems, setSelectedForbiddenAlcoholItems] = useState([]);

    /* *** State de formulario *** */

    const [foodItems, setFoodItems] = useState([]);
    const [diets, setDiets] = useState([]);
    const [patients, setPatients] = useState([]);

    const [selectedDietId, setSelectedDietId] = useState('');
    const [selectedDietTitle, setSelectedDietTitle] = useState('Personalizado');

    // State de snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(true);
    const [isDietLoading, setIsDietLoading] = useState(false);

    const [open, setOpen] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');
    const [isSearching, setIsSearching] = useState(false);

    const [isPrintDialogOpen, setIsPrintDialogOpen] = React.useState(false);

    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const [loadedData, setLoadedData] = useState(null);

    const [lang, setLang] = useState('es');

    const [error, setError] = useState('');

    const [ready, setReady] = useState(false);

    // Reglas de validación de formulario
    const constraints = {
        patient: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            }
        }
    };

    useEffect( () => {

        const init = async () => {
            await loadFoodItems();
            await loadDiets();

            if(patientId)
                await loadPatient();
        };

        init();

    }, []);

    const loadPatient = async () => {

        setIsLoading(true);

        try {

            const response = await axios.get(`${API_URL}/patients/${patientId}`);
            const patientData = response.data.patient;

            // Actualizar stare
            setPatient(patientData);

        } catch(error){

            setError('Ocurrió un error cargando el paciente');

            // Mostrar mensaje de error
            setSnackbarMessage('Ocurrió un error cargando el paciente');
            setSnackbarOpen(true);

        }

        // Esconder indicador de carga
        setIsLoading(false);
    };

    // Función que carga las dietas
    const loadDiets = async () => {

        // Mostrar indicador de carga
        setIsLoading(true);

        try {

            // Hacer request
            const response = await axios.get(
                `${API_URL}/diets`,
                {
                    params: {
                        active: true,
                        sort: 'title',
                        direction: 'asc'
                    }
                }
            );

            // Actualizar state
            setDiets(response.data);

        } catch(error){

            // Mostrar mensaje de error
            setSnackbarMessage('Ocurrió un error cargando las dietas');
            setSnackbarOpen(true);

        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    // Función que carga la dieta
    const loadDiet = async (id) => {

        // Mostrar indicador de carga
        setIsDietLoading(true);

        try {

            // Hacer request
            const response = await axios.get(`${API_URL}/diets/${id}`);
            const dietData = response.data;

            const foodItemIds = dietData.food_items;

            let allowedFoodData = foodItems.filter(item => item.is_allowed && item.category === 1);
            let forbiddenFoodData = foodItems.filter(item => !item.is_allowed && item.category === 1);
            let allowedDrinkData = foodItems.filter(item => item.is_allowed && item.category === 2);
            let forbiddenDrinkData = foodItems.filter(item => !item.is_allowed && item.category === 2);
            let allowedAlcoholData = foodItems.filter(item => item.is_allowed && item.category === 3);
            let forbiddenAlcoholData = foodItems.filter(item => !item.is_allowed && item.category === 3);

            setAllowedFoodItems(allowedFoodData.filter(item => !foodItemIds.map(it => it.id).includes(item.id)));
            setSelectedAllowedFoodItems(foodItemIds.filter(item => item.is_allowed && item.category === 1));

            setForbiddenFoodItems(forbiddenFoodData.filter(item => !foodItemIds.map(it => it.id).includes(item.id)));
            setSelectedForbiddenFoodItems(foodItemIds.filter(item => !item.is_allowed && item.category === 1));

            setAllowedDrinkItems(allowedDrinkData.filter(item => !foodItemIds.map(it => it.id).includes(item.id)));
            setSelectedAllowedDrinkItems(foodItemIds.filter(item => item.is_allowed && item.category === 2));

            setForbiddenDrinkItems(forbiddenDrinkData.filter(item => !foodItemIds.map(it => it.id).includes(item.id)));
            setSelectedForbiddenDrinkItems(foodItemIds.filter(item => !item.is_allowed && item.category === 2));

            setAllowedAlcoholItems(allowedAlcoholData.filter(item => !foodItemIds.map(it => it.id).includes(item.id)));
            setSelectedAllowedAlcoholItems(foodItemIds.filter(item => item.is_allowed && item.category === 3));

            setForbiddenAlcoholItems(forbiddenAlcoholData.filter(item => !foodItemIds.map(it => it.id).includes(item.id)));
            setSelectedForbiddenAlcoholItems(foodItemIds.filter(item => !item.is_allowed && item.category === 3));

            setLeyend(dietData.diet.leyend);
            setLeyendEn(dietData.diet.leyend_en);

        } catch(error){

            // Mostrar mensaje de error
            setSnackbarMessage('Ocurrió un error cargando los alimentos de la dieta.');
            setSnackbarOpen(true);

        }

        // Esconder indicador de carga
        setIsDietLoading(false);
    };

    // Función que carga los alimentos
    const loadFoodItems = async () => {

        // Mostrar indicador de carga
        setIsLoading(true);
        setSelectedForbiddenAlcoholItems([]);
        setSelectedAllowedAlcoholItems([]);
        setSelectedForbiddenDrinkItems([]);
        setSelectedAllowedDrinkItems([]);
        setSelectedForbiddenFoodItems([]);
        setSelectedAllowedFoodItems([]);

        try {

            // Hacer request
            const response = await axios.get(
                `${API_URL}/food_items`,
                {
                    params: {
                        active: true,
                        sort: 'name',
                        direction: 'asc'
                    }
                }
            );

            let foodItemsData = response.data;

            setFoodItems(foodItemsData);

            setAllowedFoodItems(foodItemsData.filter(item => item.is_allowed && item.category === 1));
            setForbiddenFoodItems(foodItemsData.filter(item => !item.is_allowed && item.category === 1));

            setAllowedDrinkItems(foodItemsData.filter(item => item.is_allowed && item.category === 2));
            setForbiddenDrinkItems(foodItemsData.filter(item => !item.is_allowed && item.category === 2));

            setAllowedAlcoholItems(foodItemsData.filter(item => item.is_allowed && item.category === 3));
            setForbiddenAlcoholItems(foodItemsData.filter(item => !item.is_allowed && item.category === 3));

        } catch(error){

            // Mostrar mensaje de error
            setSnackbarMessage('Ocurrió un error cargando los alimentos');
            setSnackbarOpen(true);

        }

        // Esconder indicador de carga
        setIsLoading(false);
    };

    const handleServerError = (error) => {

        if(error.patient_id){
            setPatientHasError(true);
            setPatientError(error.patient_id[0]);
        } else {
            setPatientHasError(false);
            setPatientError('');
        }

        if(error.start_at){
            setStartAtHasError(true);
            setStartAtError(error.start_at[0]);
        } else {
            setStartAtHasError(false);
            setStartAtError('');
        }

        if(error.leyend){
            setLeyendHasError(true);
            setLeyendError(error.leyend[0]);
        } else {
            setLeyendHasError(false);
            setLeyendError('');
        }

        if(error.leyend_en){
            setLeyendEnHasError(true);
            setLeyendEnError(error.leyend_en[0]);
        } else {
            setLeyendEnHasError(false);
            setLeyendEnError('');
        }
    };

    // Función que se ejecuta al enviar el formulario
    const handleSubmit = async () => {

        // Validar campos
        let valid = validate({ patient }, constraints, {fullMessages: false});

        // Si la validación pasó
        if(valid === undefined){

            // Mostrar el indicador de carga
            setIsLoading(true);

            try {

                const allowedFoodIds = selectedAllowedFoodItems.map(foodItem => foodItem.id);
                const allowedDrinkIds = selectedAllowedDrinkItems.map(foodItem => foodItem.id);
                const allowedAlcoholIds = selectedAllowedAlcoholItems.map(foodItem => foodItem.id);
                const forbiddenFoodIds = selectedForbiddenFoodItems.map(foodItem => foodItem.id);
                const forbiddenDrinkIds = selectedForbiddenDrinkItems.map(foodItem => foodItem.id);
                const forbiddenAlcoholIds = selectedForbiddenAlcoholItems.map(foodItem => foodItem.id);

                let itemIds = [];
                itemIds = itemIds.concat(allowedFoodIds);
                itemIds = itemIds.concat(allowedDrinkIds);
                itemIds = itemIds.concat(allowedAlcoholIds);
                itemIds = itemIds.concat(forbiddenFoodIds);
                itemIds = itemIds.concat(forbiddenDrinkIds);
                itemIds = itemIds.concat(forbiddenAlcoholIds);

                let requestData = {};

                if(patientId){
                    requestData = {
                        patient_id: patient.id,
                        diet_name: selectedDietTitle,
                        leyend,
                        leyend_en: leyendEn,
                        food_item_ids: itemIds
                    };
                } else {
                    requestData = {
                        patient_id: patient,
                        diet_name: selectedDietTitle,
                        leyend,
                        leyend_en: leyendEn,
                        food_item_ids: itemIds
                    };
                }

                if(startAt !== null)
                    requestData.start_at = startAt;

                const response = await axios.post(
                    `${API_URL}/patient_diets`,
                    requestData
                );

                await handlePrint(response.data.id);

                // history.goBack();

            } catch (error) {

                switch(error.response.status){
                    case 400:
                        // Mostrar mensaje
                        setSnackbarMessage('El contenido enviado no cumplió las reglas de validación');
                        setSnackbarOpen(true);
                        handleServerError(error.response.data.errors);
                        break;
                    case 422:
                        // Mostrar mensaje
                        setSnackbarMessage('El contenido enviado no cumplió las reglas de validación');
                        setSnackbarOpen(true);
                        handleServerError(error.response.data.errors);
                        break;
                    default:
                        // Mostrar mensaje
                        setSnackbarMessage('Ocurrió un error registrando la dieta de paciente.');
                        setSnackbarOpen(true);
                        break;
                }

            }

            // Esconder indicador de carga
            setIsLoading(false);

        } else {

            if(valid.patient){
                setPatientHasError(true);
                setPatientError(valid.patient[0]);
            } else {
                setPatientHasError(false);
                setPatientError('');
            }

        }



    };

    useEffect(
        () => {

            const searchPatients = async () => {

                // Make sure we have a value (user has entered something in input)
                if (debouncedSearchTerm) {

                    // Set isSearching state
                    setIsSearching(true);

                    try {

                        const data = {
                            active: true,
                            direction: 'asc',
                        };

                        if(isNaN(debouncedSearchTerm)) {
                            data.name = debouncedSearchTerm;
                            data.sort = 'special';
                        } else {
                            data.code = debouncedSearchTerm;
                            data.sort = 'code';
                        }

                        const response = await axios.get(
                            `${API_URL}/patients`, {
                                params: data
                            }
                        );

                        const patientsData = response.data;

                        setPatients(patientsData);

                    } catch (error) {

                        setSnackbarMessage('Ocurrió un error cargando los pacientes.');
                        setSnackbarOpen(true);

                    }

                    setIsSearching(false);

                } else {
                    setPatients([]);
                }

            };

            searchPatients();

        },
        // This is the useEffect input array
        // Our useEffect function will only execute if this value changes ...
        // ... and thanks to our hook it will only change if the original ...
        // value (searchTerm) hasn't changed for more than 500ms.
        [debouncedSearchTerm]
    );

    const renderCheckboxes = (category) => {

        return foodItems
            .filter(foodItem => foodItem.category === category)
            .map((foodItem, index) =>
                <FormControlLabel
                    key={foodItem.id}
                    control={
                        <Checkbox
                            checked={foodItem.checked}
                            onChange={toggleCheckbox(foodItem.id)}
                        />
                    }
                    label={ foodItem.name }
                    style={{ marginLeft: 8, marginRight: 8}}
                />
            );
    };

    const toggleCheckbox = (id) => (event) => {

        const newFoodItems = [...foodItems];

        let index = newFoodItems.findIndex((item) => item.id === id);

        newFoodItems[index].checked = !newFoodItems[index].checked;

        setFoodItems(newFoodItems);

        setSelectedDietId('');
        setSelectedDietTitle('Personalizado');

    };

    const handleDietSelect = async (diet) => {

        setSelectedDietId(diet.id);
        setSelectedDietTitle(diet.title);

        await loadDiet(diet.id);
    };

    const handlePrint = async (id) => {

        setIsLoading(true);
        setReady(false);

        try {

            const response = await axios.get(`${API_URL}/patient_diets/${id}`);
            const patientDietData = response.data;

            setLoadedData(patientDietData);
            setIsPrintDialogOpen(true);

            setTimeout(() => {
                setReady(true);
            }, 1);

        } catch(error) {

            // Mostrar mensaje
            setSnackbarMessage('Ocurrió un error generando el documento');
            setSnackbarOpen(true);

        }

        setIsLoading(false);
    };

    const renderDiets = () => {
        return diets.map(diet => (
            <Chip color={selectedDietId === diet.id ? 'primary' : 'default'} key={`${diet.id}_diet`} label={diet.title}
                  onClick={() => handleDietSelect(diet)} style={{margin: 4}}/>
        ));
    };

    return (
        <div className={classes.root}>
            {
                isLoading ?
                    <div className={ classes.progress }>
                        <CircularProgress />
                    </div> :
                    error !== '' ?
                        <div className={ classes.errorWrapper }>
                            <WarningIcon color="primary" style={ { marginRight: 8 } }/>
                            <Typography>{ error }</Typography>
                        </div> :
                        <Paper className={classes.paper}>
                            <Toolbar className={classes.padding}>
                                <IconButton className={ classes.backButton } onClick={() => history.goBack() }>
                                    <ArrowBackIcon color="secondary" />
                                </IconButton>
                                <Typography className={classes.title} variant="h6" id="title">Dieta</Typography>
                            </Toolbar>
                            <div>
                                <div className={classes.padding}>
                                    {
                                        patientId ?
                                            <TextField variant="outlined"
                                                id="patient"
                                                label="Paciente"
                                                fullWidth
                                                className={classes.textField}
                                                value={patient.name}
                                                disabled
                                                error={patientHasError}
                                                helperText={patientError}
                                            /> :
                                            <Autocomplete
                                                id="patient"
                                                open={open}
                                                fullWidth
                                                onOpen={() => {
                                                    setOpen(true);
                                                }}
                                                onClose={() => {
                                                    setOpen(false);
                                                }}
                                                onChange={(event, value) => setPatient(value.id)}
                                                onInputChange={(event) => {
                                                    setSearchTerm(event.target.value)
                                                }}
                                                getOptionSelected={(option, value) => option.id === value.id}
                                                getOptionLabel={(option) => `${option.name} (#${option.code})`}
                                                options={patients}
                                                loading={isSearching}
                                                renderInput={(params) => (
                                                    <TextField variant="outlined"
                                                        {...params}
                                                        error={patientHasError}
                                                        helperText={patientError}
                                                        className={classes.textField}
                                                        label="Paciente"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {isSearching ? <CircularProgress color="inherit"
                                                                                                     size={20}/> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                    }
                                    <KeyboardDatePicker
                                        error={startAtHasError}
                                        invalidDateMessage={ startAtError }
                                        maxDateMessage={ startAtError }
                                        variant="inline"
                                        format="DD-MM-YYYY"
                                        style={{marginTop: 0, marginBottom: 16}}
                                        fullWidth
                                        id="startAt"
                                        label="Fecha de Inicio con Dieta"
                                        value={startAt}
                                        onChange={(date) => setStartAt(date !== null ? date.format('YYYY-MM-DD') : '')}
                                        KeyboardButtonProps={{
                                            'aria-label': 'cambiar fecha',
                                        }}
                                    />
                                    <Typography variant="subtitle2">Dietas</Typography>
                                    <div className={ classes.chipWrapper }>
                                        { renderDiets() }
                                    </div>
                                    {
                                        isDietLoading ?
                                            <div className={ classes.insideProgress }>
                                                <CircularProgress />
                                            </div> :
                                            <div>
                                                <TextField variant="outlined"
                                                           id="leyend"
                                                           label="Leyenda"
                                                           fullWidth
                                                           multiline
                                                           rows={3}
                                                           className={ classes.textField }
                                                           value={ leyend }
                                                           onChange={ (event) => setLeyend(event.target.value) }
                                                           error={ leyendHasError }
                                                           helperText={ leyendError }
                                                />
                                                <TextField variant="outlined"
                                                           id="leyend_en"
                                                           label="Leyenda (Inglés)"
                                                           fullWidth
                                                           multiline
                                                           rows={3}
                                                           className={ classes.textField }
                                                           value={ leyendEn }
                                                           onChange={ (event) => setLeyendEn(event.target.value) }
                                                           error={ leyendEnHasError }
                                                           helperText={ leyendEnError }
                                                />
                                                <div className={ classes.buttonWrapper }>
                                                    <Button color="secondary" onClick={ handleSubmit }>Crear</Button>
                                                </div>
                                                <Typography variant="subtitle2" className={ classes.subtitle }>Alimentos Permitidos</Typography>
                                                <TransferDraggableList type="PERMITIDO" items={allowedFoodItems} selectedItems={selectedAllowedFoodItems} onSelectedChange={setSelectedAllowedFoodItems} onInitialChange={setAllowedFoodItems} />
                                                <Typography variant="subtitle2" className={ classes.subtitle }>Alimentos Prohibidos</Typography>
                                                <TransferDraggableList items={forbiddenFoodItems} selectedItems={selectedForbiddenFoodItems} onSelectedChange={setSelectedForbiddenFoodItems} onInitialChange={setForbiddenFoodItems} />
                                                <Typography variant="subtitle2" className={ classes.subtitle }>Bebidas Permitidas</Typography>
                                                <TransferDraggableList type="PERMITIDO" items={allowedDrinkItems} selectedItems={selectedAllowedDrinkItems} onSelectedChange={setSelectedAllowedDrinkItems} onInitialChange={setSelectedAllowedDrinkItems} />
                                                <Typography variant="subtitle2" className={ classes.subtitle }>Bebidas Prohibidas</Typography>
                                                <TransferDraggableList items={forbiddenDrinkItems} selectedItems={selectedForbiddenDrinkItems} onSelectedChange={setSelectedForbiddenDrinkItems} onInitialChange={setForbiddenDrinkItems} />
                                                <Typography variant="subtitle2" className={ classes.subtitle }>Alcoholes Permitidos</Typography>
                                                <TransferDraggableList type="PERMITIDO" items={allowedAlcoholItems} selectedItems={selectedAllowedAlcoholItems} onSelectedChange={setSelectedAllowedAlcoholItems} onInitialChange={setAllowedAlcoholItems} />
                                                <Typography variant="subtitle2" className={ classes.subtitle }>Alcoholes Prohibidos</Typography>
                                                <TransferDraggableList items={forbiddenAlcoholItems} selectedItems={selectedForbiddenAlcoholItems} onSelectedChange={setSelectedForbiddenAlcoholItems} onInitialChange={setForbiddenAlcoholItems} />
                                            </div>
                                    }
                                </div>
                            </div>
                        </Paper>
            }
            <Snack message={ snackbarMessage } open={ snackbarOpen } handleClose={ () => setSnackbarOpen(false) } />
            <Dialog
                open={isPrintDialogOpen}
                onClose={() => setIsPrintDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">"Imprimir Dieta"</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Seleccione el lenguaje en el cual desea imprimir la dieta.
                    </DialogContentText>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="lang" name="lang" value={lang} onChange={(event) => setLang(event.target.value)} row>
                            <FormControlLabel value="es" control={<Radio />} label="Español" />
                            <FormControlLabel value="en" control={<Radio />} label="Inglés" />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsPrintDialogOpen(false)} color="primary">
                        Cancelar
                    </Button>
                    {
                        ready ?
                            <PDFDownloadLink style={{textDecoration: 'none'}}
                                             document={<Diet data={loadedData} lang={lang}/>}
                                             fileName={`Dieta_${loadedData ? loadedData.diet.id : ''}.pdf`}>
                                {
                                    ({blob, url, loading, error}) => {
                                        return <Button color="primary" autoFocus disabled={loading}>
                                            IMPRIMIR
                                        </Button>
                                    }
                                }
                            </PDFDownloadLink> :
                            null
                    }
                </DialogActions>
            </Dialog>
        </div>
    )
};

export default PatientDietsCreate;
