import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import validate from 'validate.js'
import Snack from "../../../components/Snack/Snack";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import axios from "axios";
import {API_URL, PUBLIC_URL} from "../../../config";
import Autocomplete from '@material-ui/lab/Autocomplete';
import useDebounce from "../../../hooks/useDebounce";
import {KeyboardDatePicker} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import moment from "moment";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    padding: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    title: {
        flex: '1 1 100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    foodWrapper: {
        marginBottom: 20
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    insideProgress: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backButton: {
        marginRight: 8
    },
    textField: {
        marginBottom: theme.spacing(2),
        width: '100%'
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
    chipWrapper: {
        marginBottom: 20
    },
    chip: {
        margin: 2,
    },
    errorWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '100%'
    },
}));

const TreatmentPurchaseCreate = ({ history, match }) => {

    const classes = useStyles();

    /* *** State de formulario *** */

    const [patient, setPatient] = useState('');
    const [patientHasError, setPatientHasError] = useState(false);
    const [patientError, setPatientError] = useState('');

    const [purchasedAt, setPurchasedAt] = useState(moment().format('YYYY-MM-DD'));
    const [purchasedAtHasError, setPurchasedAtHasError] = useState(false);
    const [purchasedAtError, setPurchasedAtError] = useState('');

    const [cost, setCost] = useState('');
    const [costHasError, setCostHasError] = useState(false);
    const [costError, setCostError] = useState('');

    const [dollars, setDollars] = useState(0);
    const [dollarsHasError, setDollarsHasError] = useState(false);
    const [dollarsError, setDollarsError] = useState('');

    const [pesos, setPesos] = useState(0);
    const [pesosHasError, setPesosHasError] = useState(false);
    const [pesosError, setPesosError] = useState('');

    const [comments, setComments] = useState('');
    const [commentsHasError, setCommentsHasError] = useState(false);
    const [commentsError, setCommentsError] = useState('');

    /* *** State de formulario *** */

    const [patients, setPatients] = useState([]);

    // State de snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(false);

    const [open, setOpen] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');
    const [isSearching, setIsSearching] = useState(false);

    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    // Reglas de validación de formulario
    const constraints = {
        patient: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            }
        },
        cost: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            numericality: {
                greaterThanOrEqualTo: 0,
                message: "El valor debe ser un número mayor o igual a 0"
            }
        },
        dollars: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            numericality: {
                greaterThanOrEqualTo: 0,
                message: "El valor debe ser un número mayor o igual a 0"
            }
        },
        pesos: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            numericality: {
                greaterThanOrEqualTo: 0,
                message: "El valor debe ser un número mayor o igual a 0"
            }
        },
        purchasedAt: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            }
        }
    };

    const handleServerError = (error) => {

        if(error.patient_id){
            setPatientHasError(true);
            setPatientError(error.patient_id[0]);
        } else {
            setPatientHasError(false);
            setPatientError('');
        }

        if(error.purchased_at){
            setPurchasedAtHasError(true);
            setPurchasedAtError(error.purchased_at[0]);
        } else {
            setPurchasedAtHasError(false);
            setPurchasedAtError('');
        }

        if(error.cost){
            setCostHasError(true);
            setCostError(error.cost[0]);
        } else {
            setCostHasError(false);
            setCostError('');
        }

        if(error.dollars){
            setDollarsHasError(true);
            setDollarsError(error.dollars[0]);
        } else {
            setDollarsHasError(false);
            setDollarsError('');
        }

        if(error.pesos){
            setPesosHasError(true);
            setPesosError(error.pesos[0]);
        } else {
            setPesosHasError(false);
            setPesosError('');
        }

        if(error.comments){
            setCommentsHasError(true);
            setCommentsError(error.comments[0]);
        } else {
            setCommentsHasError(false);
            setCommentsError('');
        }

    };

    // Función que se ejecuta al enviar el formulario
    const handleSubmit = async () => {

        // Validar campos
        let valid = validate({ patient, purchasedAt, cost, dollars, pesos, comments }, constraints, {fullMessages: false});

        // Si la validación pasó
        if(valid === undefined){

            // Mostrar el indicador de carga
            setIsLoading(true);

            try {

                let requestData = {
                    patient_id: patient,
                    purchased_at: purchasedAt,
                    cost,
                    dollars,
                    pesos
                };

                if(comments)
                    requestData.comments = comments;

                await axios.post(
                    `${API_URL}/treatment_purchases`,
                    requestData
                );

                resetForm();

                setSnackbarMessage('Compra registrada exitosamente.');
                setSnackbarOpen(true);

            } catch (error) {

                switch(error.response.status){
                    case 400:
                        // Mostrar mensaje
                        setSnackbarMessage('El contenido enviado no cumplió las reglas de validación');
                        setSnackbarOpen(true);
                        handleServerError(error.response.data.errors);
                        break;
                    case 422:
                        // Mostrar mensaje
                        setSnackbarMessage('El contenido enviado no cumplió las reglas de validación');
                        setSnackbarOpen(true);
                        handleServerError(error.response.data.errors);
                        break;
                    default:
                        // Mostrar mensaje
                        setSnackbarMessage('Ocurrió un error registrando la compra.');
                        setSnackbarOpen(true);
                        break;
                }

            }

            // Esconder indicador de carga
            setIsLoading(false);

        } else {

            if(valid.patient){
                setPatientHasError(true);
                setPatientError(valid.patient[0]);
            } else {
                setPatientHasError(false);
                setPatientError('');
            }

            if(valid.purchasedAt){
                setPurchasedAtHasError(true);
                setPurchasedAtError(valid.purchasedAt[0]);
            } else {
                setPurchasedAtHasError(false);
                setPurchasedAtError('');
            }

            if(valid.cost){
                setCostHasError(true);
                setCostError(valid.cost[0]);
            } else {
                setCostHasError(false);
                setCostError('');
            }

            if(valid.dollars){
                setDollarsHasError(true);
                setDollarsError(valid.dollars[0]);
            } else {
                setDollarsHasError(false);
                setDollarsError('');
            }

            if(valid.pesos){
                setPesosHasError(true);
                setPesosError(valid.pesos[0]);
            } else {
                setPesosHasError(false);
                setPesosError('');
            }

            if(valid.comments){
                setCommentsHasError(true);
                setCommentsError(valid.comments[0]);
            } else {
                setCommentsHasError(false);
                setCommentsError('');
            }
        }
    };

    // Función que vacia el formulario
    const resetForm = () => {

        // Vaciar campos del formulario
        setPurchasedAt(null);
        setCost('');
        setDollars(0);
        setPesos(0);
        setComments('');
        setPatient('');
    };

    useEffect(
        () => {

            const searchPatients = async () => {

                // Make sure we have a value (user has entered something in input)
                if (debouncedSearchTerm) {

                    // Set isSearching state
                    setIsSearching(true);

                    try {

                        const data = {
                            active: true,
                            direction: 'asc',
                        };

                        if(isNaN(debouncedSearchTerm)) {
                            data.name = debouncedSearchTerm;
                            data.sort = 'special';
                        } else {
                            data.code = debouncedSearchTerm;
                            data.sort = 'code';
                        }

                        const response = await axios.get(
                            `${API_URL}/patients`, {
                                params: data
                            }
                        );

                        const patientsData = response.data;

                        setPatients(patientsData);

                    } catch (error) {

                        setSnackbarMessage('Ocurrió un error cargando los pacientes.');
                        setSnackbarOpen(true);

                    }

                    setIsSearching(false);

                } else {
                    setPatients([]);
                }

            };

            searchPatients();

        },
        // This is the useEffect input array
        // Our useEffect function will only execute if this value changes ...
        // ... and thanks to our hook it will only change if the original ...
        // value (searchTerm) hasn't changed for more than 500ms.
        [debouncedSearchTerm]
    );

    return (
        <div className={classes.root}>
            {
                isLoading ?
                    <div className={ classes.progress }>
                        <CircularProgress />
                    </div> :
                    <Paper className={classes.paper}>
                        <Toolbar className={classes.padding}>
                            <IconButton className={ classes.backButton } onClick={() => history.goBack() }>
                                <ArrowBackIcon color="secondary" />
                            </IconButton>
                            <Typography className={classes.title} variant="h6" id="title">Compra por Tratamiento</Typography>
                        </Toolbar>
                        <div>
                            <div className={classes.padding}>
                                <Autocomplete
                                    id="patient"
                                    open={open}
                                    fullWidth
                                    onOpen={() => {
                                        setOpen(true);
                                    }}
                                    onClose={() => {
                                        setOpen(false);
                                    }}
                                    onChange={(event, value) => setPatient(value.id)}
                                    onInputChange={(event) => {
                                        setSearchTerm(event.target.value)
                                    }}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => `${option.name} (#${option.code})`}
                                    options={patients}
                                    loading={isSearching}
                                    renderInput={(params) => (
                                        <TextField variant="outlined"
                                                   {...params}
                                                   error={patientHasError}
                                                   helperText={patientError}
                                                   className={classes.textField}
                                                   label="Paciente"
                                                   InputProps={{
                                                       ...params.InputProps,
                                                       endAdornment: (
                                                           <React.Fragment>
                                                               {isSearching ? <CircularProgress color="inherit"
                                                                                                size={20}/> : null}
                                                               {params.InputProps.endAdornment}
                                                           </React.Fragment>
                                                       ),
                                                   }}
                                        />
                                    )}
                                />
                                <KeyboardDatePicker
                                    error={purchasedAtHasError}
                                    invalidDateMessage={ purchasedAtError }
                                    maxDateMessage={ purchasedAtError }
                                    variant="inline"
                                    format="DD-MM-YYYY"
                                    style={{marginTop: 0, marginBottom: 16}}
                                    fullWidth
                                    id="purchasedAt"
                                    label="Fecha de Compra"
                                    value={purchasedAt}
                                    onChange={(date) => setPurchasedAt(date !== null ? date.format('YYYY-MM-DD') : '')}
                                    KeyboardButtonProps={{
                                        'aria-label': 'cambiar fecha',
                                    }}
                                />
                                <Grid container spacing={3}>
                                    <Grid item sm={4} xs={12}>
                                        <TextField
                                            id="cost"
                                            label="Costo"
                                            type="number"
                                            variant="outlined"
                                            fullWidth
                                            className={ classes.textField }
                                            value={ cost }
                                            onChange={ (event) => setCost(event.target.value) }
                                            error={ costHasError }
                                            helperText={ costError }
                                        />
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                        <TextField
                                            id="dollars"
                                            label="Dólares"
                                            variant="outlined"
                                            type="number"
                                            fullWidth
                                            className={ classes.textField }
                                            value={ dollars }
                                            onChange={ (event) => setDollars(event.target.value) }
                                            error={ dollarsHasError }
                                            helperText={ dollarsError }
                                        />
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                        <TextField
                                            id="pesos"
                                            label="Pesos"
                                            variant="outlined"
                                            type="number"
                                            fullWidth
                                            className={ classes.textField }
                                            value={ pesos }
                                            onChange={ (event) => setPesos(event.target.value) }
                                            error={ pesosHasError }
                                            helperText={ pesosError }
                                        />
                                    </Grid>
                                </Grid>
                                <TextField variant="outlined"
                                    id="comments"
                                    label="Comentarios"
                                    fullWidth
                                    multiline
                                    rows={3}
                                    className={ classes.textField }
                                    value={ comments }
                                    onChange={ (event) => setComments(event.target.value) }
                                    error={ commentsHasError }
                                    helperText={ commentsError }
                                />
                                <div className={ classes.buttonWrapper }>
                                    <Button color="secondary" onClick={ handleSubmit }>Crear</Button>
                                </div>
                            </div>
                        </div>
                    </Paper>
            }
            <Snack message={ snackbarMessage } open={ snackbarOpen } handleClose={ () => setSnackbarOpen(false) } />
        </div>
    )
};

export default TreatmentPurchaseCreate;
