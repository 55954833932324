import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import * as moment from 'moment';
import Snack from "../../../components/Snack/Snack";
import MaterialTable from "material-table";
import axios from 'axios';
import {API_URL, PUBLIC_URL, tableIcons} from "../../../config";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import ImageIcon from '@material-ui/icons/Image';
import Tooltip from "@material-ui/core/Tooltip";
import {Add, Edit, AssistantPhoto} from "@material-ui/icons";
import {useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginBottom: 30
    },
    toolbar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    action: {
        marginRight: 8
    },
    progressBar: {
        width: '100%'
    }
}));

const MedicinesIndex = ({ history }) => {

    const classes = useStyles();

    const authState = useSelector((state) => state.authReducer);

    // State de medicinas
    const [medicines, setMedicines] = useState([]);

    // State de tabla
    const [selected, setSelected] = useState([]);

    // State de snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(true);

    // State de diálogo
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);

    useEffect( () => {

        loadMedicines();

    }, []);

    // Función que carga los medicamentos
    const loadMedicines = async () => {

        // Mostrar indicador de carga
        setIsLoading(true);

        try {

            // Hacer request
            const response = await axios.get(`${API_URL}/medicines`);

            // Actualizar state
            setMedicines(response.data);

        } catch(error){

            // Mostrar mensaje de error
            setSnackbarMessage('Ocurrió un error cargando los medicamentos');
            setSnackbarOpen(true);

        }

        // Esconder indicador de carga
        setIsLoading(false);
    };

    // Función que se ejecuta al enviar pulsar el botón de desactivar
    const handleDeactivate = async () => {

        // Esconder el diálogo
        setIsDialogOpen(false);

        // Mostrar el indicador de carga
        setIsLoading(true);

        try {

            // Total de seleccionados
            let count = selected.length;

            // Contadores para mensaje
            let deactivatedCount = 0;
            let activatedCount = 0;

            // Por cada categoría seleccionada
            for(let i = 0; i < count; i++){

                // Cambiar estado
                await axios.delete(`${API_URL}/medicines/${selected[i].id}`);

                // Actualizar contador
                if(selected[i].is_active)
                    deactivatedCount += 1;
                else
                    activatedCount += 1;
            }

            if(deactivatedCount > 1 && activatedCount > 1){
                // Mostrar mensaje
                setSnackbarMessage(`${deactivatedCount} medicinas desactivadas y ${activatedCount} medicinas activadas exitosamente`);
                setSnackbarOpen(true);
            } else if(deactivatedCount === 1 && activatedCount === 1){
                // Mostrar mensaje
                setSnackbarMessage(`1 medicina desactivada y 1 medicina activada exitosamente`);
                setSnackbarOpen(true);
            } else if(deactivatedCount > 1 && activatedCount === 0){
                // Mostrar mensaje
                setSnackbarMessage(`${deactivatedCount} medicinas desactivadas exitosamente`);
                setSnackbarOpen(true);
            } else if(deactivatedCount === 1 && activatedCount === 0){
                // Mostrar mensaje
                setSnackbarMessage(`1 medicina desactivada exitosamente`);
                setSnackbarOpen(true);
            } else if(deactivatedCount === 0 && activatedCount > 1){
                // Mostrar mensaje
                setSnackbarMessage(`${activatedCount} medicinas activadas exitosamente`);
                setSnackbarOpen(true);
            } else if(deactivatedCount === 0 && activatedCount === 1){
                // Mostrar mensaje
                setSnackbarMessage(`1 medicina activada exitosamente`);
                setSnackbarOpen(true);
            } else if(deactivatedCount === 1 && activatedCount > 1){
                // Mostrar mensaje
                setSnackbarMessage(`1 medicina desactivada y ${activatedCount} medicinas activadas exitosamente`);
                setSnackbarOpen(true);
            } else {
                // Mostrar mensaje
                setSnackbarMessage(`${deactivatedCount} medicinas desactivadas y 1 medicina activada exitosamente`);
                setSnackbarOpen(true);
            }

            setSelected([]);

            await loadMedicines();

        } catch (error) {

            // Mostrar mensaje
            setSnackbarMessage('Ocurrió un error desactivando o activando las medicinas');
            setSnackbarOpen(true);
        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    return (
        <div className={classes.root}>
            <MaterialTable
                title="Medicamentos"
                icons={ tableIcons }
                isLoading={ isLoading }
                columns={
                    [
                        { title: 'Nombre', field: 'name', filtering: false },
                        { title: 'Descripción', field: 'description', filtering: false },
                        { title: 'Dosis', field: 'dosis', filtering: false },
                        { title: 'Precio', field: 'price', filtering: false, render: rowData => '$' + rowData.price},
                        {
                            title: 'Imagen',
                            field: 'image',
                            render: rowData => {
                                if(rowData.image) {
                                    return <Tooltip title={rowData.image}>
                                        <IconButton aria-label="Ver imagen"
                                                    onClick={() => window.open(`${PUBLIC_URL}/medicines/${rowData.image}`)}>
                                            <ImageIcon color="secondary"/>
                                        </IconButton>
                                    </Tooltip>
                                }
                            },
                            filtering: false
                        },
                        {
                            title: 'Creado',
                            field: 'created_at',
                            filtering: false,
                            render: rowData => {
                                return moment(rowData.created_at).format('D/MMM/YY');
                            }
                        },
                        {
                            title: 'Estado',
                            field: 'is_active',
                            type: 'boolean',
                        },
                    ]
                }
                data={ medicines }
                actions={[
                    {
                        icon: Add,
                        tooltip: 'Agregar medicina',
                        isFreeAction: true,
                        onClick: (event) => history.push('/medicines/create')
                    },
                    {
                        icon: Edit,
                        tooltip: 'Editar medicina',
                        onClick: (event, rowData) => history.push(`/medicines/${rowData[0].id}/edit`),
                        disabled: (selected.length > 1)
                    },
                    authState.roleId === 1 ?
                        {
                            icon: AssistantPhoto,
                            tooltip: 'Desactivar o activar medicina(s)',
                            onClick: (event, rowData) => setIsDialogOpen(true),
                        } :
                        null
                ]}
                options={{
                    selection: true,
                    filtering: true,
                    exportButton: true,
                    exportFileName: `Medicinas_Clinic_${ moment().format('YYYY_MM_DD')}`,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [10, 25, 50],
                    pageSize: 10,
                }}
                onSelectionChange={(rows) => setSelected(rows)}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} de {count}',
                        nextTooltip: 'Siguiente',
                        lastTooltip: 'Última',
                        firstTooltip: 'Primera',
                        previousTooltip: 'Anterior',
                        labelRowsPerPage: 'Filas por página:',
                        labelRowsSelect: 'filas'
                    },
                    toolbar: {
                        nRowsSelected: '{0} fila(s) seleccionada(s)',
                        searchTooltip: 'Buscar',
                        searchPlaceholder: 'Buscar',
                        exportTitle: 'Exportar',
                        exportName: 'Exportar como CSV'
                    },
                    header: {
                        actions: 'Acciones'
                    },
                    body: {
                        emptyDataSourceMessage: 'No hay registros disponibles',
                        filterRow: {
                            filterTooltip: 'Filtrar'
                        }
                    }
                }}
            />
            <Dialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">"Desactivar o activar medicina(s)"</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Está seguro que desea desactivar o activar las medicinas seleccionadas?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsDialogOpen(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleDeactivate} color="primary" autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
            <Snack message={ snackbarMessage } open={ snackbarOpen } handleClose={ () => setSnackbarOpen(false) } />
        </div>
    );

};

export default MedicinesIndex;
