import React from 'react';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import RootRef from "@material-ui/core/RootRef";
import Paper from "@material-ui/core/Paper";

const TransferDraggableList = ({items, selectedItems, onSelectedChange, onInitialChange, type}) => {

    const move = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);

        destClone.splice(droppableDestination.index, 0, removed);

        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;

        return result;
    };

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const grid = 8;

    const getItemStyle = (isDragging, draggableStyle) => ({
        // styles we need to apply on draggables
        ...draggableStyle,
        ...(isDragging && {
            background: "rgb(235,235,235)"
        }),
        color: type === 'PERMITIDO' ? 'green' : 'red'
    });

    const getListStyle = isDraggingOver => ({

    });

    const onDragEnd = result => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                source.droppableId === 'droppable' ? items : selectedItems,
                source.index,
                destination.index
            );

            if (source.droppableId === 'droppable2') {
                onSelectedChange(items);
            } else {
                onInitialChange(items);
            }

        } else {
            const result = move(
                source.droppableId === 'droppable' ? items : selectedItems,
                destination.droppableId === 'droppable' ? items : selectedItems,
                source,
                destination
            );

            onInitialChange(result.droppable);
            onSelectedChange(result.droppable2);
        }
    };

    return <div style={{ display: 'flex', justifyContent: 'space-between'}}>
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <Paper style={{ flex: 1, margin: 4, maxHeight: 600, overflowY: 'scroll' }}>
                        <RootRef rootRef={provided.innerRef}>
                            <List style={getListStyle(snapshot.isDraggingOver)}>
                                {items.map((item, index) => (
                                    <Draggable
                                        key={item.id}
                                        draggableId={`id_${item.id}`}
                                        index={index}>
                                        {(provided, snapshot) => (
                                            <>
                                                <ListItem
                                                    ContainerComponent="li"
                                                    ContainerProps={{ ref: provided.innerRef }}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <ListItemIcon>
                                                        <CancelIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={item.name}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <IconButton>
                                                            <OpenWithIcon />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            </>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </List>
                        </RootRef>
                    </Paper>
                )}
            </Droppable>
            <Droppable droppableId="droppable2">
                {(provided, snapshot) => (
                    <Paper style={{ flex: 1, margin: 4, maxHeight: 600, overflowY: 'scroll' }}>
                        <RootRef rootRef={provided.innerRef}>
                            <List style={getListStyle(snapshot.isDraggingOver)}>
                                {selectedItems.map((item, index) => (
                                    <Draggable
                                        key={item.id}
                                        draggableId={`id_${item.id}`}
                                        index={index}>
                                        {(provided, snapshot) => (
                                            <ListItem
                                                ContainerComponent="li"
                                                ContainerProps={{ ref: provided.innerRef }}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <ListItemIcon>
                                                    <CheckCircleIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={item.name}
                                                />
                                                <ListItemSecondaryAction>
                                                    <IconButton>
                                                        <OpenWithIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </List>
                        </RootRef>
                    </Paper>
                )}
            </Droppable>
        </DragDropContext>
    </div>
};

export default TransferDraggableList;
