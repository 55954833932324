import React from 'react';
import {Document, Page, Text, StyleSheet, View, Image, Font} from "@react-pdf/renderer";
import moment from 'moment';
import 'moment/locale/es';
import logo from '../../assets/images/logo.png';

const styles = StyleSheet.create({
    page: { paddingVertical: 30, paddingHorizontal: 80 },
    section: { color: 'white', textAlign: 'center', margin: 30 },
    headerWrapper: { display: 'flex', justifyContent: 'center', flexDirection: 'row', marginBottom: 30, marginTop: 30 },
    headerRight: { fontSize: 10, fontFamily: 'Times-Italic' },
    headerLeft: { fontSize: 10, fontFamily: 'Times-Italic' },
    headerMiddle: { fontSize: 12, fontFamily: 'Times-Italic' },
    logo: { width: 200 },
    label: { fontSize: 10, fontFamily: 'Times-Italic' },
    notice: { fontSize: 13, fontFamily: 'Times-Italic' },
    foodItemsWrapper: { display: 'flex', justifyContent: 'flex-start', flexDirection: 'row' },
    categoryLabel: { fontSize: 10, marginTop: 20, marginBottom: 4, fontFamily: 'Times-Italic' },
    divider: { borderBottomWidth: 2, borderBottomStyle: 'dotted', borderBottomColor: 'black', marginBottom: 10 },
    foodItemWrapper: { marginBottom: 0 },
    foodItemName: { fontSize: 12, fontFamily: 'Times-Italic' },
    foodItemDesc: { fontSize: 10, fontFamily: 'Times-Italic' }
});

const Diet = ({ data, lang = "es" }) => {

    Font.registerHyphenationCallback(word => [word]);

    if(lang === 'es')
        moment.locale('es');
    else
        moment.locale('en');

    const diet = data.diet;
    const patient = data.patient;
    const foodItems = data.food_items;
    const configuration = data.configuration;

    const renderFoodItems = (type, allowed) => {
        return foodItems.filter(foodItem => foodItem.category === type && foodItem.is_allowed == allowed).map(foodItem => (
            <View key={foodItem.id} style={ styles.foodItemWrapper }>
                <Text style={ [styles.foodItemName, { color: allowed ? (configuration.diet_allowed_color ? configuration.diet_allowed_color : '#009688') : (configuration.diet_forbidden_color ? configuration.diet_forbidden_color : '#ff5754'), letterSpacing: 1 }] }>{ lang === 'es' ? foodItem.name : foodItem.name_en }  <Text style={ [styles.foodItemDesc, { color: allowed ? (configuration.diet_allowed_description_color ? configuration.diet_allowed_description_color : '#009688') : (configuration.diet_forbidden_description_color ? configuration.diet_forbidden_description_color : '#ff5754'), letterSpacing: 0.8 }] }>{ lang === 'es' ? foodItem.description : foodItem.description_en }</Text></Text>
            </View>
        ));
    };

    return <Document>
        <Page size="A4" style={ styles.page }>
            <View style={ styles.headerWrapper }>
                <View style={ { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' } }>
                    <Image style={ styles.logo } src={logo} />
                    <Text style={ [styles.headerLeft, configuration.diet_contact_color && {color: configuration.diet_contact_color}] }>Álvaro Obregón No. 44 Esquina con 2a</Text>
                    <Text style={ [styles.headerLeft, configuration.diet_contact_color && {color: configuration.diet_contact_color}] }>Tel. (868) 812-35-85 y (868) 812-12-94</Text>
                    <Text style={ [styles.headerLeft, configuration.diet_contact_color && {color: configuration.diet_contact_color}] }>H. Matamoros, Tam.</Text>
                </View>
            </View>
            <Text style={ [styles.label, { fontFamily: 'Times-Bold' }, configuration.diet_info_label_color && {color: configuration.diet_info_label_color}] }>{ lang === 'es' ? `NOMBRE:` : `NAME:`}<Text style={ { fontFamily: 'Times-Italic', textTransform: 'uppercase', color: configuration.diet_info_content_color ? configuration.diet_info_content_color : 'red' } }>   {patient.name}</Text></Text>
            <Text style={ [styles.label, { fontFamily: 'Times-Bold' }, configuration.diet_info_label_color && {color: configuration.diet_info_label_color}] }>{ lang === 'es' ? `FECHA:` : `DATE:`}<Text style={ { fontFamily: 'Times-Italic', textTransform: 'uppercase', color: configuration.diet_info_content_color ? configuration.diet_info_content_color : 'red'} }>       { lang === 'es' ? moment(diet.created_at).format('D [de] MMMM YYYY') : moment(diet.created_at).format('MMMM D YYYY')}</Text></Text>
            <Text style={ [styles.label, { fontFamily: 'Times-Bold', color: configuration.diet_name_color ? configuration.diet_name_color : 'red', textTransform: 'uppercase' }] }>{ diet.diet_name }</Text>
            {
                (diet.leyend && diet.leyend !== '') && (diet.leyend_en && diet.leyend_en !== '') ?
                    lang === 'es' ?
                        <Text style={ [styles.notice, { marginTop: 10, color: configuration.diet_description_color ? configuration.diet_description_color : '#0d47a1' }] }>{ diet.leyend }</Text> :
                        <Text style={ [styles.notice, { marginTop: 10, color: configuration.diet_description_color ? configuration.diet_description_color : '#0d47a1' }] }>{ diet.leyend_en }</Text> :
                    null
            }
            <View>
                <View style={ styles.foodItemsWrapper }>
                    <Text style={ [styles.categoryLabel, { flex: 1, marginRight: 20, color: configuration.diet_allowed_column_header_color ? configuration.diet_allowed_column_header_color : 'black' }] }>{ lang === 'es' ? 'ALIMENTOS PERMITIDOS' : 'ALLOWED MEALS' }</Text>
                    <Text style={ [styles.categoryLabel, { flexBasis: 120, color: configuration.diet_forbidden_column_header_color ? configuration.diet_forbidden_column_header_color : 'black' }] }>{ lang === 'es' ? 'ALIMENTOS PROHIBIDOS' : 'PROHIBITED MEALS' }</Text>
                </View>
                <View style={ styles.divider }></View>
                <View style={ styles.foodItemsWrapper }>
                    <View style={ { flex: 1, marginRight: 20 } }>
                        { renderFoodItems(1, true) }
                    </View>
                    <View style={ { flexBasis: 120 } }>
                        { renderFoodItems(1, false) }
                    </View>
                </View>
                <View style={ styles.foodItemsWrapper }>
                    <Text style={ [styles.categoryLabel, { flex: 1, marginRight: 20, color: configuration.diet_allowed_column_header_color ? configuration.diet_allowed_column_header_color : 'black' }] }>{ lang === 'es' ? 'BEBIDAS PERMITIDAS' : 'ALLOWED DRINKS' }</Text>
                    <Text style={ [styles.categoryLabel, { flexBasis: 120, color: configuration.diet_forbidden_column_header_color ? configuration.diet_forbidden_column_header_color : 'black' }] }>{ lang === 'es' ? 'BEBIDAS PROHIBIDAS' : 'PROHIBITED DRINKS' }</Text>
                </View>
                <View style={ styles.divider }></View>
                <View style={ styles.foodItemsWrapper }>
                    <View style={ { flex: 1, marginRight: 20 } }>
                        { renderFoodItems(2, true) }
                    </View>
                    <View style={ { flexBasis: 120 } }>
                        { renderFoodItems(2, false) }
                    </View>
                </View>
                <View style={ styles.foodItemsWrapper }>
                    <Text style={ [styles.categoryLabel, { flex: 1, marginRight: 20, color: configuration.diet_allowed_column_header_color ? configuration.diet_allowed_column_header_color : 'black' }] }>{ lang === 'es' ? 'ALCOHOL PERMITIDO' : 'ALLOWED ALCOHOL' }</Text>
                    <Text style={ [styles.categoryLabel, { flexBasis: 120, color: configuration.diet_forbidden_column_header_color ? configuration.diet_forbidden_column_header_color : 'black' }] }>{ lang === 'es' ? 'ALCOHOL PROHIBIDO' : 'PROHIBITED ALCOHOL' }</Text>
                </View>
                <View style={ styles.divider }></View>
                <View style={ styles.foodItemsWrapper }>
                    <View style={ { flex: 1, marginRight: 20 } }>
                        { renderFoodItems(3, true) }
                    </View>
                    <View style={ { flexBasis: 120 } }>
                        { renderFoodItems(3, false) }
                    </View>
                </View>
                <View style={ { marginTop: 10 } }>
                    <Text style={ [styles.label, { fontFamily: 'Times-Bold', color: configuration.diet_note_label_color ? configuration.diet_note_label_color : 'black' }] }>{ lang === 'es' ? `IMPORTANTE:` : `IMPORTANT:`}</Text>
                    <Text style={ { fontSize: 12, fontFamily: 'Times-Italic', color: configuration.diet_important_content_color ? configuration.diet_important_content_color : 'red', marginTop: 4 } }> { lang === 'es' ? 'Si usted sabe que tomará más de 3 bebidas, suspenda el medicamento por ese día.' : 'If you know you are going to have more than 3 drinks, suspend the medicine for that day.'}</Text>
                    <Text style={ [styles.label, { fontFamily: 'Times-Bold', marginTop: 10, color: configuration.diet_note_label_color ? configuration.diet_note_label_color : 'black' }] }>{ lang === 'es' ? `INDICACIONES:` : `INDICATIONS:` } <Text style={ { fontSize: 12, fontFamily: 'Times-Italic', color: configuration.diet_note_content_color ? configuration.diet_note_content_color : 'black' } }> { lang === 'es' ? 'Tomar 8 vasos con agua al dia.' : 'Drink 8 glasses of water per day.'}</Text></Text>
                </View>
            </View>
        </Page>
    </Document>

};

export default Diet;
