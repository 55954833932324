import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import validate from 'validate.js'
import Snack from "../../../components/Snack/Snack";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import {NavLink} from "react-router-dom";
import axios from "axios";
import {API_URL, APP_URL} from "../../../config";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    padding: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    title: {
        flex: '1 1 100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backButton: {
        marginRight: 8
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
}));

const FoodItemCreate = () => {

    const classes = useStyles();

    /* *** State de formulario *** */

    // State de nombre
    const [name, setName] = useState('');
    const [nameHasError, setNameHasError] = useState(false);
    const [nameError, setNameError] = useState('');

    const [nameEn, setNameEn] = useState('');
    const [nameEnHasError, setNameEnHasError] = useState(false);
    const [nameEnError, setNameEnError] = useState('');

    const [description, setDescription] = useState('');
    const [descriptionHasError, setDescriptionHasError] = useState(false);
    const [descriptionError, setDescriptionError] = useState('');

    const [descriptionEn, setDescriptionEn] = useState('');
    const [descriptionEnHasError, setDescriptionEnHasError] = useState(false);
    const [descriptionEnError, setDescriptionEnError] = useState('');

    const [category, setCategory] = useState('');
    const [categoryHasError, setCategoryHasError] = useState(false);
    const [categoryError, setCategoryError] = useState('');

    // State de permitido
    const [isAllowed, setIsAllowed] = useState(false);

    /* *** State de formulario *** */

    // State de snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(false);

    // Reglas de validación de formulario
    const constraints = {
        name: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            }
        },
        nameEn: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            }
        },
        category: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            }
        }
    };

    const handleServerError = (error) => {

        if(error.name){
            setNameHasError(true);
            setNameError(error.name[0]);
        } else {
            setNameHasError(false);
            setNameError('');
        }

        if(error.name_en){
            setNameEnHasError(true);
            setNameEnError(error.name_en[0]);
        } else {
            setNameEnHasError(false);
            setNameEnError('');
        }

        if(error.description){
            setDescriptionHasError(true);
            setDescriptionError(error.description[0]);
        } else {
            setDescriptionHasError(false);
            setDescriptionError('');
        }

        if(error.description_en){
            setDescriptionEnHasError(true);
            setDescriptionEnError(error.description_en[0]);
        } else {
            setDescriptionEnHasError(false);
            setDescriptionEnError('');
        }

        if(error.category){
            setCategoryHasError(true);
            setCategoryError(error.category[0]);
        } else {
            setCategoryHasError(false);
            setCategoryError('');
        }
    };

    // Función que se ejecuta al enviar el formulario
    const handleSubmit = async () => {

        // Validar campos
        let valid = validate({ name, nameEn, category }, constraints, {fullMessages: false});

        // Si la validación pasó
        if(valid === undefined){

            // Mostrar el indicador de carga
            setIsLoading(true);

            try {

                const formData = {
                    name,
                    name_en: nameEn,
                    category,
                    is_allowed: isAllowed
                };

                if(description)
                    formData.description = description;

                if(descriptionEn)
                    formData.description_en = descriptionEn;

                const response = await axios.post(
                    `${API_URL}/food_items`,
                    formData
                );

                // Vaciar el formulario
                resetForm();

                // Mostrar mensaje
                setSnackbarMessage('Alimento registrado exitosamente');
                setSnackbarOpen(true);

            } catch (error) {

                switch(error.response.status){
                    case 400:
                        // Mostrar mensaje
                        setSnackbarMessage('El contenido enviado no cumplió las reglas de validación');
                        setSnackbarOpen(true);
                        handleServerError(error.response.data.errors);
                        break;
                    case 422:
                        // Mostrar mensaje
                        setSnackbarMessage('El contenido enviado no cumplió las reglas de validación');
                        setSnackbarOpen(true);
                        handleServerError(error.response.data.errors);
                        break;
                    default:
                        // Mostrar mensaje
                        setSnackbarMessage('Ocurrió un error registrando el alimento');
                        setSnackbarOpen(true);
                        break;
                }

            }

            // Esconder indicador de carga
            setIsLoading(false);

        } else {

            // Si existe un error con el campo, mostrarlo
            if(valid.name){
                setNameHasError(true);
                setNameError(valid.name[0]);
            } else {
                setNameHasError(false);
                setNameError('');
            }

            if(valid.nameEn){
                setNameEnHasError(true);
                setNameEnError(valid.nameEn[0]);
            } else {
                setNameEnHasError(false);
                setNameEnError('');
            }

            if(valid.description){
                setDescriptionHasError(true);
                setDescriptionError(valid.description[0]);
            } else {
                setDescriptionHasError(false);
                setDescriptionError('');
            }

            if(valid.descriptionEn){
                setDescriptionEnHasError(true);
                setDescriptionEnError(valid.descriptionEn[0]);
            } else {
                setDescriptionEnHasError(false);
                setDescriptionEnError('');
            }

            if(valid.category){
                setCategoryHasError(true);
                setCategoryError(valid.category[0]);
            } else {
                setCategoryHasError(false);
                setCategoryError('');
            }
        }



    };

    // Función que vacia el formulario
    const resetForm = () => {

        // Vaciar campos del formulario
        setName('');
        setNameEn('');
        setDescription('');
        setDescriptionEn('');
        setCategory('');
        setIsAllowed(false);

    };

    const handleChange = (event) => {
        setIsAllowed(event.target.checked);
    };

    return (
        <div className={classes.root}>
            {
                isLoading ?
                    <div className={ classes.progress }>
                        <CircularProgress />
                    </div> :
                    <Paper className={classes.paper}>
                        <Toolbar className={classes.padding}>
                            <IconButton className={ classes.backButton } component={ NavLink } to="/food_items">
                                <ArrowBackIcon color="secondary" />
                            </IconButton>
                            <Typography className={classes.title} variant="h6" id="title">Alimento</Typography>
                        </Toolbar>
                        <div>
                            <div className={classes.padding}>
                                <TextField variant="outlined"
                                    id="name"
                                    label="Nombre"
                                    fullWidth
                                    className={ classes.textField }
                                    value={ name }
                                    onChange={ (event) => setName(event.target.value) }
                                    error={ nameHasError }
                                    helperText={ nameError }
                                />
                                <TextField variant="outlined"
                                    id="nameEn"
                                    label="Nombre (Inglés)"
                                    fullWidth
                                    className={ classes.textField }
                                    value={ nameEn }
                                    onChange={ (event) => setNameEn(event.target.value) }
                                    error={ nameEnHasError }
                                    helperText={ nameEnError }
                                />
                                <FormControl className={ classes.formControl } error={ categoryHasError }>
                                    <InputLabel id="category_label">Categoría</InputLabel>
                                    <Select
                                        labelId="category_label"
                                        id="category"
                                        MenuProps={MenuProps}
                                        fullWidth
                                        value={category}
                                        onChange={(event) => setCategory(event.target.value)}
                                    >
                                        <MenuItem value={1}>Alimento</MenuItem>
                                        <MenuItem value={2}>Bebida</MenuItem>
                                        <MenuItem value={3}>Alcohol</MenuItem>
                                    </Select>
                                    <FormHelperText>{ categoryError }</FormHelperText>
                                </FormControl>
                                <TextField variant="outlined"
                                    id="description"
                                    label="Descripción"
                                    fullWidth
                                    className={ classes.textField }
                                    value={ description }
                                    onChange={ (event) => setDescription(event.target.value) }
                                    error={ descriptionHasError }
                                    helperText={ descriptionError }
                                />
                                <TextField variant="outlined"
                                    id="descriptionEn"
                                    label="Descripción (Inglés)"
                                    fullWidth
                                    className={ classes.textField }
                                    value={ descriptionEn }
                                    onChange={ (event) => setDescriptionEn(event.target.value) }
                                    error={ descriptionEnHasError }
                                    helperText={ descriptionEnError }
                                />
                                <FormControlLabel
                                    control={<Switch checked={isAllowed} onChange={handleChange} name="is_allowed" />}
                                    label="¿Permitido?"
                                />
                                <div className={ classes.buttonWrapper }>
                                    <Button color="secondary" onClick={ handleSubmit }>Crear</Button>
                                </div>
                            </div>
                        </div>
                    </Paper>
            }
            <Snack message={ snackbarMessage } open={ snackbarOpen } handleClose={ () => setSnackbarOpen(false) } />
        </div>
    )
};

export default FoodItemCreate;
