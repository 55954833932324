import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import * as moment from 'moment';
import Snack from "../../../components/Snack/Snack";
import MaterialTable from "material-table";
import axios from 'axios';
import {API_URL, tableIcons} from "../../../config";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {Add, Edit, AssistantPhoto} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginBottom: 30
    },
    toolbar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    action: {
        marginRight: 8
    },
    progressBar: {
        width: '100%'
    }
}));

const UsersIndex = ({ history }) => {

    const classes = useStyles();

    // State de usuarios
    const [users, setUsers] = useState([]);

    // State de tabla
    const [selected, setSelected] = useState([]);

    // State de snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(true);

    // State de diálogo
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);

    useEffect( () => {

        loadUsers();

    }, []);

    // Función que carga los usuarios
    const loadUsers = async () => {

        // Mostrar indicador de carga
        setIsLoading(true);

        try {

            // Hacer request
            const response = await axios.get(`${API_URL}/users`);

            // Actualizar state
            setUsers(response.data);

        } catch(error){

            // Mostrar mensaje de error
            setSnackbarMessage('Ocurrió un error cargando los usuarios');
            setSnackbarOpen(true);

        }

        // Esconder indicador de carga
        setIsLoading(false);
    };

    // Función que se ejecuta al enviar pulsar el botón de desactivar
    const handleDeactivate = async () => {

        // Esconder el diálogo
        setIsDialogOpen(false);

        // Mostrar el indicador de carga
        setIsLoading(true);

        try {

            // Total de seleccionados
            let count = selected.length;

            // Contadores para mensaje
            let deactivatedCount = 0;

            // Por cada usuario seleccionada
            for(let i = 0; i < count; i++){

                // Cambiar estado
                await axios.delete(`${API_URL}/users/${selected[i].id}`);

                // Actualizar contador
                deactivatedCount += 1;
            }

            if(deactivatedCount > 1){
                // Mostrar mensaje
                setSnackbarMessage(`${deactivatedCount} usuarios eliminados exitosamente`);
                setSnackbarOpen(true);
            } else {
                // Mostrar mensaje
                setSnackbarMessage(`1 usuario eliminado exitosamente`);
                setSnackbarOpen(true);
            }

            setSelected([]);

            await loadUsers();

        } catch (error) {

            if(error.response.data.message){
                // Mostrar mensaje
                setSnackbarMessage(error.response.data.message);
                setSnackbarOpen(true);
            } else {
                // Mostrar mensaje
                setSnackbarMessage('Ocurrió un error eliminando los usuarios');
                setSnackbarOpen(true);
            }
        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    return (
        <div className={classes.root}>
            <MaterialTable
                title="Usuarios"
                icons={ tableIcons }
                isLoading={ isLoading }
                columns={
                    [
                        { title: 'Nombre', field: 'name', filtering: false },
                        { title: 'Correo Electrónico', field: 'email', filtering: false },
                        {
                            title: 'Creado',
                            field: 'created_at',
                            filtering: false,
                            render: rowData => {
                                return moment(rowData.created_at).format('D/MMM/YY');
                            }
                        }
                    ]
                }
                data={ users }
                actions={[
                    {
                        icon: Add,
                        tooltip: 'Agregar usuario',
                        isFreeAction: true,
                        onClick: (event) => history.push('/users/create')
                    },
                    {
                        icon: Edit,
                        tooltip: 'Editar usuario',
                        onClick: (event, rowData) => history.push(`/users/${rowData[0].id}/edit`),
                        disabled: (selected.length > 1)
                    },
                    {
                        icon: AssistantPhoto,
                        tooltip: 'Eliminar usuario(s)',
                        onClick: (event, rowData) => setIsDialogOpen(true),
                    }
                ]}
                options={{
                    selection: true,
                    filtering: true,
                    exportButton: true,
                    exportFileName: `Usuarios_Clinic_${ moment().format('YYYY_MM_DD')}`,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [10, 25, 50],
                    pageSize: 10,
                }}
                onSelectionChange={(rows) => setSelected(rows)}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} de {count}',
                        nextTooltip: 'Siguiente',
                        lastTooltip: 'Última',
                        firstTooltip: 'Primera',
                        previousTooltip: 'Anterior',
                        labelRowsPerPage: 'Filas por página:',
                        labelRowsSelect: 'filas'
                    },
                    toolbar: {
                        nRowsSelected: '{0} fila(s) seleccionada(s)',
                        searchTooltip: 'Buscar',
                        searchPlaceholder: 'Buscar',
                        exportTitle: 'Exportar',
                        exportName: 'Exportar como CSV'
                    },
                    header: {
                        actions: 'Acciones'
                    },
                    body: {
                        emptyDataSourceMessage: 'No hay registros disponibles',
                        filterRow: {
                            filterTooltip: 'Filtrar'
                        }
                    }
                }}
            />
            <Dialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">"Eliminar usuario(s)"</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Está seguro que desea desactivar o activar los usuarios seleccionados? Esta acción es permanente.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsDialogOpen(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleDeactivate} color="primary" autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
            <Snack message={ snackbarMessage } open={ snackbarOpen } handleClose={ () => setSnackbarOpen(false) } />
        </div>
    );

};

export default UsersIndex;
