import React, {useEffect, useState} from 'react';
import './App.css';
import {createMuiTheme, ThemeProvider, withStyles} from "@material-ui/core";
import {esES} from "@material-ui/core/locale";
import moment from 'moment';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import SignIn from "./containers/Auth/SignIn/SignIn";
import {connect, useSelector} from "react-redux";
import Dashboard from "./components/Dashboard/Dashboard";
import axios from 'axios';
import {API_URL} from "./config";
import {signIn} from "./store/actions/auth";
import Loading from "./containers/Loading/Loading";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';

function App({ signIn }) {

  const authState = useSelector((state) => state.authReducer);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    const checkUser = async () => {
      setIsLoading(true);

      try {
        const response = await axios.get(`${API_URL}/user`);

        signIn(response.data.user.id, response.data.user.name, response.data.role.id);

      } catch (error) {

      }

      setIsLoading(false);
    };

    checkUser();
  }, []);

  // Tema de Material UI
  const theme = createMuiTheme({
    palette: {
      primary: { main: '#1a237e' },
      secondary: { main: '#7cb342' }
    },
    typography: {
      fontSize: 14,
    },
  }, esES);

  moment.locale('es');

  const GlobalCss = withStyles({
    // @global is handled by jss-plugin-global.
    '@global': {
      // You should target [class*="MuiButton-root"] instead if you nest themes.
      '.MuiOutlinedInput-notchedOutline': {
        borderWidth: '1px',
        borderColor: '#1a237e !important'
      },
      '.MuiIconButton-colorInherit': {
        color: '#7cb342'
      },
      '.MuiCheckbox-root': {
        color: '#7cb342'
      }
    },
  })(() => null);

  return <ThemeProvider theme={ theme }>
    <MuiPickersUtilsProvider utils={MomentUtils} locale={'es'}>
      <GlobalCss />
      <BrowserRouter>
        {
          isLoading ?
              <Loading />:
              <Switch>
                {
                  authState.isLoggedIn ?
                      <Route path="/" component={ Dashboard } />:
                      null
                }
                <Route path="/login" exact component={ SignIn } />
                <Redirect from="/" to="/login" />
              </Switch>
        }
      </BrowserRouter>
    </MuiPickersUtilsProvider>
  </ThemeProvider>
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (userId, name, roleId) =>
        dispatch(signIn(userId, name, roleId))
  };
};

export default connect(null, mapDispatchToProps)(App);
