import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import validate from 'validate.js'
import Snack from "../../../components/Snack/Snack";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import {NavLink} from "react-router-dom";
import axios from "axios";
import {API_URL, APP_URL} from "../../../config";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import WarningIcon from "@material-ui/core/SvgIcon/SvgIcon";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    padding: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    title: {
        flex: '1 1 100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backButton: {
        marginRight: 8
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
    errorWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '100%'
    },
    iconError: {
        color: theme.palette.error.main
    },
}));

const UsersEdit = ({ match }) => {

    const classes = useStyles();

    // State de roles
    const [roles, setRoles] = useState([]);

    /* *** State de formulario *** */

    // State de nombre
    const [name, setName] = useState('');
    const [nameHasError, setNameHasError] = useState(false);
    const [nameError, setNameError] = useState('');

    const [email, setEmail] = useState('');
    const [emailHasError, setEmailHasError] = useState(false);
    const [emailError, setEmailError] = useState('');

    const [password, setPassword] = useState('');
    const [passwordHasError, setPasswordHasError] = useState(false);
    const [passwordError, setPasswordError] = useState('');

    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [passwordConfirmationHasError, setPasswordConfirmationHasError] = useState(false);
    const [passwordConfirmationError, setPasswordConfirmationError] = useState('');

    const [role, setRole] = useState('');
    const [roleHasError, setRoleHasError] = useState(false);
    const [roleError, setRoleError] = useState('');

    /* *** State de formulario *** */

    // State de snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(false);

    const [error, setError] = useState('');

    // ID del registro
    const id = match.params.id;

    // Reglas de validación de formulario
    const constraints = {
        name: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            }
        },
        email: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            email: {
                message: "Debe de ingresar un correo válido"
            }
        },
        role: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            inclusion: {
                within: roles.map(role => role.id),
                message: "El valor seleccionado no es válido"
            }
        },
    };

    useEffect(() => {

        const init = async () => {
            await loadRoles();
            await loadUser();
        };

        init();

    }, []);

    // Función que carga el paciente
    const loadUser = async () => {

        setIsLoading(true);

        try {

            const response = await axios.get(`${API_URL}/users/${id}`);
            const userData = response.data;

            // Actualizar stare
            setName(userData.name);
            setEmail(userData.email);
            setRole(userData.role_id);

        } catch(error){

            setError('Ocurrió un error cargando el paciente');

            // Mostrar mensaje de error
            setSnackbarMessage('Ocurrió un error cargando el paciente');
            setSnackbarOpen(true);

        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    // Función que carga los roles
    const loadRoles = async () => {

        // Mostrar indicador de carga
        setIsLoading(true);

        try {

            // Hacer request
            const response = await axios.get(
                `${API_URL}/roles`,
                {
                    params: {
                        sort: 'name',
                        direction: 'asc'
                    }
                }
            );

            // Actualizar state
            setRoles(response.data);

        } catch(error) {

            // Mostrar mensaje
            setSnackbarMessage('Ocurrió un error cargando los roles');
            setSnackbarOpen(true);

        }

        // Esconder indicador de carga
        setIsLoading(false);
    };

    const handleServerError = (error) => {

        if(error.name){
            setNameHasError(true);
            setNameError(error.name[0]);
        } else {
            setNameHasError(false);
            setNameError('');
        }

        if(error.email){
            setEmailHasError(true);
            setEmailError(error.email[0]);
        } else {
            setEmailHasError(false);
            setEmailError('');
        }

        if(error.password){
            setPasswordHasError(true);
            setPasswordError(error.password[0]);
        } else {
            setPasswordHasError(false);
            setPasswordError('');
        }

        if(error.role_id){
            setRoleHasError(true);
            setRoleError(error.role_id[0]);
        } else {
            setRoleHasError(false);
            setRoleError('');
        }

    };

    // Función que se ejecuta al enviar el formulario
    const handleSubmit = async () => {

        if(password !== ''){
            constraints.password = {
                length: {
                    minimum: 4,
                        message: "El valor debe contener al menos 4 caracteres"
                }
            };
            constraints.passwordConfirmation = {
                equality: {
                    attribute: "password",
                        message: "La contraseña no coincide"
                }
            };
        }

        // Validar campos
        let valid = validate({ name, email, password, passwordConfirmation, role }, constraints, {fullMessages: false});

        // Si la validación pasó
        if(valid === undefined){

            // Mostrar el indicador de carga
            setIsLoading(true);

            try {

                const response = await axios.put(
                    `${API_URL}/users/${id}`,
                    {
                        name,
                        email,
                        password,
                        password_confirmation: passwordConfirmation,
                        role_id: role
                    }
                );

                // Mostrar mensaje
                setSnackbarMessage('Usuario actualizado exitosamente');
                setSnackbarOpen(true);

            } catch (error) {

                switch(error.response.status){
                    case 400:
                        // Mostrar mensaje
                        setSnackbarMessage('El contenido enviado no cumplió las reglas de validación');
                        setSnackbarOpen(true);
                        handleServerError(error.response.data.errors);
                        break;
                    case 422:
                        // Mostrar mensaje
                        setSnackbarMessage('El contenido enviado no cumplió las reglas de validación');
                        setSnackbarOpen(true);
                        handleServerError(error.response.data.errors);
                        break;
                    default:
                        // Mostrar mensaje
                        setSnackbarMessage('Ocurrió un error registrando el alimento');
                        setSnackbarOpen(true);
                        break;
                }

            }

            // Esconder indicador de carga
            setIsLoading(false);

        } else {

            // Si existe un error con el campo, mostrarlo
            if(valid.name){
                setNameHasError(true);
                setNameError(valid.name[0]);
            } else {
                setNameHasError(false);
                setNameError('');
            }

            if(valid.email){
                setEmailHasError(true);
                setEmailError(valid.email[0]);
            } else {
                setEmailHasError(false);
                setEmailError('');
            }

            if(valid.password){
                setPasswordHasError(true);
                setPasswordError(valid.password[0]);
            } else {
                setPasswordHasError(false);
                setPasswordError('');
            }

            if(valid.passwordConfirmation){
                setPasswordConfirmationHasError(true);
                setPasswordConfirmationError(valid.passwordConfirmation[0]);
            } else {
                setPasswordConfirmationHasError(false);
                setPasswordConfirmationError('');
            }

            if(valid.role){
                setRoleHasError(true);
                setRoleError(valid.role[0]);
            } else {
                setRoleHasError(false);
                setRoleError('');
            }
        }



    };

    const rolesOptions = roles.map(role => (
        <MenuItem key={role.id} value={role.id}>{ role.name }</MenuItem>
    ));

    return (
        <div className={classes.root}>
            {
                isLoading ?
                    <div className={ classes.progress }>
                        <CircularProgress />
                    </div> :
                    error !== '' ?
                        <div className={ classes.errorWrapper }>
                            <WarningIcon color="primary" style={ { marginRight: 8 } }/>
                            <Typography>{ error }</Typography>
                        </div> :
                        <Paper className={classes.paper}>
                            <Toolbar className={classes.padding}>
                                <IconButton className={ classes.backButton } component={ NavLink } to="/users">
                                    <ArrowBackIcon color="secondary" />
                                </IconButton>
                                <Typography className={classes.title} variant="h6" id="title">Usuario</Typography>
                            </Toolbar>
                            <div>
                                <div className={classes.padding}>
                                    <TextField variant="outlined"
                                        id="name"
                                        label="Nombre"
                                        fullWidth
                                        className={ classes.textField }
                                        value={ name }
                                        onChange={ (event) => setName(event.target.value) }
                                        error={ nameHasError }
                                        helperText={ nameError }
                                    />
                                    <TextField variant="outlined"
                                        id="email"
                                        label="Correo electrónico"
                                        fullWidth
                                        className={ classes.textField }
                                        value={ email }
                                        onChange={ (event) => setEmail(event.target.value) }
                                        error={ emailHasError }
                                        helperText={ emailError }
                                    />
                                    <TextField variant="outlined"
                                        id="password"
                                        label="Contraseña"
                                        type="password"
                                        fullWidth
                                        className={ classes.textField }
                                        value={ password }
                                        onChange={ (event) => setPassword(event.target.value) }
                                        error={ passwordHasError }
                                        helperText={ passwordError }
                                    />
                                    <TextField variant="outlined"
                                        id="passwordConfirmation"
                                        label="Confirmar Contraseña"
                                        type="password"
                                        fullWidth
                                        className={ classes.textField }
                                        value={ passwordConfirmation }
                                        onChange={ (event) => setPasswordConfirmation(event.target.value) }
                                        error={ passwordConfirmationHasError }
                                        helperText={ passwordConfirmationError }
                                    />
                                    <FormControl className={ classes.formControl } error={ roleHasError }>
                                        <InputLabel id="role_label">Rol</InputLabel>
                                        <Select
                                            labelId="role_label"
                                            id="role"
                                            MenuProps={MenuProps}
                                            fullWidth
                                            value={role}
                                            onChange={(event) => setRole(event.target.value)}
                                        >
                                            { rolesOptions }
                                        </Select>
                                        <FormHelperText>{ roleError }</FormHelperText>
                                    </FormControl>
                                    <div className={ classes.buttonWrapper }>
                                        <Button color="secondary" onClick={ handleSubmit }>Actualizar</Button>
                                    </div>
                                </div>
                            </div>
                        </Paper>
            }
            <Snack message={ snackbarMessage } open={ snackbarOpen } handleClose={ () => setSnackbarOpen(false) } />
        </div>
    )
};

export default UsersEdit;
