import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import validate from 'validate.js'
import Snack from "../../../components/Snack/Snack";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import axios from "axios";
import {API_URL} from "../../../config";
import Autocomplete from '@material-ui/lab/Autocomplete';
import useDebounce from "../../../hooks/useDebounce";
import MenuItem from "@material-ui/core/MenuItem";
import WarningIcon from "@material-ui/core/SvgIcon/SvgIcon";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from "@material-ui/core/Grid";
import {isEmpty} from "lodash";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import {PDFDownloadLink} from "@react-pdf/renderer";
import Prescription from "../../../components/Reports/Prescription";
import PrescriptionReal from "../../../components/Reports/PrescriptionReal";
import Dialog from "@material-ui/core/Dialog/Dialog";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    padding: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    title: {
        flex: '1 1 100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    foodWrapper: {
        marginBottom: 20
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    insideProgress: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backButton: {
        marginRight: 8
    },
    textField: {
        marginBottom: theme.spacing(2),
        width: '100%'
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
    chipWrapper: {
        marginBottom: 20
    },
    chip: {
        margin: 2,
    },
    errorWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '100%'
    },
    subtitle: {
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(2)
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

validate.validators.array = (arrayItems, itemConstraints) => {
    const arrayItemErrors = arrayItems.reduce((errors, item, index) => {
        const error = validate(item, itemConstraints, {fullMessages: false});
        if (error) errors[index] = error;
        return errors
    }, {});

    return isEmpty(arrayItemErrors) ? null : { errors: arrayItemErrors }
};

const PrescriptionsCreate = ({ history, match }) => {

    const classes = useStyles();

    // ID del registro
    const patientId = match.params.id;

    /* *** State de formulario *** */

    const [patient, setPatient] = useState('');
    const [patientHasError, setPatientHasError] = useState(false);
    const [patientError, setPatientError] = useState('');

    const [selectedMedicines, setSelectedMedicines] = useState([
        {
            name: {value: '', error: ''},
            description: {value: '', error: ''},
            dosis: {value: '', error: ''},
            descriptionEn: {value: '', error: ''},
            dosisEn: {value: '', error: ''}
        }
    ]);
    const [selectedMedicinesHasError, setSelectedMedicinesHasError] = useState(false);
    const [selectedMedicinesError, setSelectedMedicinesError] = useState('');

    const [comments, setComments] = useState('15 JERINGAS CON 40 UNIDADES INTERNACIONALES DE AMINOACIDOS PARA APLICACIÓN INTRAMUSCULAR 1 CADA 2 DIAS.');
    const [commentsHasError, setCommentsHasError] = useState(false);
    const [commentsError, setCommentsError] = useState('');

    const [commentsEn, setCommentsEn] = useState('15 SYRINGES WITH 40 INTERNATIONAL UNITS OF AMINOACIDS FOR INTRAMUSCULAR APPLICATIONS 1 EVERY 2 DAYS.');
    const [commentsEnHasError, setCommentsEnHasError] = useState(false);
    const [commentsEnError, setCommentsEnError] = useState('');

    /* *** State de formulario *** */

    const [medicine, setMedicine] = useState('');
    const [medicines, setMedicines] = useState([]);
    const [patients, setPatients] = useState([]);

    // State de snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const [lang, setLang] = useState('es');

    const [loadedData, setLoadedData] = useState(null);

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(true);

    const [error, setError] = useState('');

    const [open, setOpen] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');
    const [isSearching, setIsSearching] = useState(false);

    const [isPrintDialogOpen, setIsPrintDialogOpen] = React.useState(false);

    const [ready, setReady] = useState(false);

    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    // Reglas de validación de formulario
    const constraints = {
        patient: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            }
        },
        selectedMedicines: {
            array:{
                name: {
                    presence: {
                        allowEmpty: false,
                        message: "Debe de llenar este campo"
                    }
                },
                description: {
                    presence: {
                        allowEmpty: false,
                        message: "Debe de llenar este campo"
                    }
                },
                descriptionEn: {
                    presence: {
                        allowEmpty: false,
                        message: "Debe de llenar este campo"
                    }
                },
                dosis: {
                    presence: {
                        allowEmpty: false,
                        message: "Debe de llenar este campo"
                    }
                },
                dosisEn: {
                    presence: {
                        allowEmpty: false,
                        message: "Debe de llenar este campo"
                    }
                }
            }
        }
    };

    useEffect( () => {

        const init = async () => {
            await loadMedicines();

            if(patientId)
                await loadPatient();
        };

        init();

    }, []);

    // Función que carga las dietas
    const loadMedicines = async () => {

        // Mostrar indicador de carga
        setIsLoading(true);

        try {

            // Hacer request
            const response = await axios.get(
                `${API_URL}/medicines`,
                {
                    params: {
                        active: true,
                        sort: 'name',
                        direction: 'asc'
                    }
                }
            );

            // Actualizar state
            setMedicines(response.data);

        } catch(error){

            // Mostrar mensaje de error
            setSnackbarMessage('Ocurrió un error cargando las medicinas');
            setSnackbarOpen(true);

        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    const loadPatient = async () => {

        setIsLoading(true);

        try {

            const response = await axios.get(`${API_URL}/patients/${patientId}`);
            const patientData = response.data.patient;

            // Actualizar stare
            setPatient(patientData);

        } catch(error){

            setError('Ocurrió un error cargando el paciente');

            // Mostrar mensaje de error
            setSnackbarMessage('Ocurrió un error cargando el paciente');
            setSnackbarOpen(true);

        }

        // Esconder indicador de carga
        setIsLoading(false);
    };

    const handleServerError = (error) => {

        if(error.patient_id){
            setPatientHasError(true);
            setPatientError(error.patient_id[0]);
        } else {
            setPatientHasError(false);
            setPatientError('');
        }

        if(error.comments){
            setCommentsHasError(true);
            setCommentsError(error.comments[0]);
        } else {
            setCommentsHasError(false);
            setCommentsError('');
        }

        if(error.comments_en){
            setCommentsEnHasError(true);
            setCommentsEnError(error.comments_en[0]);
        } else {
            setCommentsEnHasError(false);
            setCommentsEnError('');
        }

        if(error.medicines_ids){
            setSelectedMedicinesHasError(true);
            setSelectedMedicinesError(error.medicines_ids[0]);
        } else {
            setSelectedMedicinesHasError(false);
            setSelectedMedicinesError('');
        }

    };

    const mapMedicines = (api = false) => {

        const newMedicines = [...selectedMedicines];

        if(api){
            return newMedicines.map(medicine => ({
                medicine_id: medicine.name.value,
                description: medicine.description.value,
                dosis: medicine.dosis.value,
                description_en: medicine.descriptionEn.value,
                dosis_en: medicine.dosisEn.value
            }));
        } else {
            return newMedicines.map(medicine => ({
                name: medicine.name.value,
                description: medicine.description.value,
                dosis: medicine.dosis.value,
                descriptionEn: medicine.descriptionEn.value,
                dosisEn: medicine.dosisEn.value
            }));
        }

    };

    // Función que se ejecuta al enviar el formulario
    const handleSubmit = async () => {

        // Validar campos
        let valid = validate({ patient, selectedMedicines: mapMedicines() }, constraints, {fullMessages: false});

        // Si la validación pasó
        if(valid === undefined){

            // Mostrar el indicador de carga
            setIsLoading(true);

            try {

                const selectedMeds = mapMedicines(true);

                let requestData = {};

                if(patientId){
                    requestData = {
                        patient_id: patient.id,
                        medicines: selectedMeds
                    };
                } else {
                    requestData = {
                        patient_id: patient,
                        medicines: selectedMeds
                    };
                }

                if(comments)
                    requestData.comments = comments;

                if(commentsEn)
                    requestData.comments_en = commentsEn;

                const response = await axios.post(
                    `${API_URL}/prescriptions`,
                    requestData
                );

                await handlePrint(response.data.id);

            } catch (error) {

                switch(error.response.status){
                    case 400:
                        // Mostrar mensaje
                        setSnackbarMessage('El contenido enviado no cumplió las reglas de validación');
                        setSnackbarOpen(true);
                        handleServerError(error.response.data.errors);
                        break;
                    case 422:
                        // Mostrar mensaje
                        setSnackbarMessage('El contenido enviado no cumplió las reglas de validación');
                        setSnackbarOpen(true);
                        handleServerError(error.response.data.errors);
                        break;
                    default:
                        // Mostrar mensaje
                        setSnackbarMessage('Ocurrió un error registrando la dieta de paciente.');
                        setSnackbarOpen(true);
                        break;
                }

            }

            // Esconder indicador de carga
            setIsLoading(false);

        } else {

            if(valid.patient){
                setPatientHasError(true);
                setPatientError(valid.patient[0]);
            } else {
                setPatientHasError(false);
                setPatientError('');
            }

            if(valid.selectedMedicines){
                if(valid.selectedMedicines[0].errors){

                    let newMedicines = [...selectedMedicines];

                    let errors = valid.selectedMedicines[0].errors
                    Object.keys(errors).forEach((key, index) => {

                        if(errors[key].name)
                            newMedicines[key].name.error = errors[key].name[0];
                        else
                            newMedicines[key].name.error = '';

                        if(errors[key].description)
                            newMedicines[key].description.error = errors[key].description[0];
                        else
                            newMedicines[key].description.error = '';

                        if(errors[key].dosis)
                            newMedicines[key].dosis.error = errors[key].dosis[0];
                        else
                            newMedicines[key].dosis.error = '';

                        if(errors[key].descriptionEn)
                            newMedicines[key].descriptionEn.error = errors[key].descriptionEn[0];
                        else
                            newMedicines[key].descriptionEn.error = '';

                        if(errors[key].dosisEn)
                            newMedicines[key].dosisEn.error = errors[key].dosisEn[0];
                        else
                            newMedicines[key].dosisEn.error = '';

                    });

                    setSelectedMedicines(newMedicines);
                }
            }
        }



    };

    useEffect(
        () => {

            const searchPatients = async () => {

                // Make sure we have a value (user has entered something in input)
                if (debouncedSearchTerm) {

                    // Set isSearching state
                    setIsSearching(true);

                    try {

                        const data = {
                            active: true,
                            direction: 'asc',
                        };

                        if(isNaN(debouncedSearchTerm)) {
                            data.name = debouncedSearchTerm;
                            data.sort = 'special';
                        } else {
                            data.code = debouncedSearchTerm;
                            data.sort = 'code';
                        }

                        const response = await axios.get(
                            `${API_URL}/patients`, {
                                params: data
                            }
                        );

                        const patientsData = response.data;

                        setPatients(patientsData);

                    } catch (error) {

                        setSnackbarMessage('Ocurrió un error cargando los pacientes.');
                        setSnackbarOpen(true);

                    }

                    setIsSearching(false);

                } else {
                    setPatients([]);
                }

            };

            searchPatients();

        },
        // This is the useEffect input array
        // Our useEffect function will only execute if this value changes ...
        // ... and thanks to our hook it will only change if the original ...
        // value (searchTerm) hasn't changed for more than 500ms.
        [debouncedSearchTerm]
    );

    const addMedicine = () => {

        let currentMedicines = [...selectedMedicines];

        currentMedicines.push({
            name: {value: '', error: ''},
            description: {value: '', error: ''},
            dosis: {value: '', error: ''},
            descriptionEn: {value: '', error: ''},
            dosisEn: {value: '', error: ''}
        });

        setSelectedMedicines(currentMedicines);

    };

    const deleteMedicine = (index) => {

        const newMedicines = [...selectedMedicines];
        newMedicines.splice(index,1);
        setSelectedMedicines(newMedicines);

    };

    const handleInputChange = (value, field, index) => {

        let currentMedicines = [...selectedMedicines];
        currentMedicines[index][field].value = value;

        if(field === 'name'){
            let newMedicine = medicines.find(med => med.id === value);
            currentMedicines[index].description.value = newMedicine.description;
            currentMedicines[index].dosis.value = newMedicine.dosis;
            currentMedicines[index].descriptionEn.value = newMedicine.description_en;
            currentMedicines[index].dosisEn.value = newMedicine.dosis_en;

        }

        setSelectedMedicines(currentMedicines);

    };

    const medicinesOptions = medicines.map(medicine => (
        <MenuItem key={medicine.id} value={medicine.id}>{ medicine.name }</MenuItem>
    ));

    const handlePrint = async (id) => {

        setIsLoading(true);
        setReady(false);

        try {

            const response = await axios.get(`${API_URL}/prescriptions/${id}`);
            const prescriptionData = response.data;

            setLoadedData(prescriptionData);
            setIsPrintDialogOpen(true);

            setTimeout(() => {
                setReady(true);
            }, 1);

        } catch(error) {

            // Mostrar mensaje
            setSnackbarMessage('Ocurrió un error generando el documento');
            setSnackbarOpen(true);

        }

        setIsLoading(false);
    };

    return (
        <div className={classes.root}>
            {
                isLoading ?
                    <div className={ classes.progress }>
                        <CircularProgress />
                    </div> :
                    error !== '' ?
                        <div className={ classes.errorWrapper }>
                            <WarningIcon color="primary" style={ { marginRight: 8 } }/>
                            <Typography>{ error }</Typography>
                        </div> :
                        <Paper className={classes.paper}>
                            <Toolbar className={classes.padding}>
                                <IconButton className={ classes.backButton } onClick={() => history.goBack() }>
                                    <ArrowBackIcon color="secondary" />
                                </IconButton>
                                <Typography className={classes.title} variant="h6" id="title">Receta</Typography>
                            </Toolbar>
                            <div>
                                <div className={classes.padding}>
                                    {
                                        patientId ?
                                            <TextField variant="outlined"
                                                id="patient"
                                                label="Paciente"
                                                fullWidth
                                                className={classes.textField}
                                                value={patient.name}
                                                disabled
                                                error={patientHasError}
                                                helperText={patientError}
                                            /> :
                                            <Autocomplete
                                                id="patient"
                                                open={open}
                                                fullWidth
                                                onOpen={() => {
                                                    setOpen(true);
                                                }}
                                                onClose={() => {
                                                    setOpen(false);
                                                }}
                                                onChange={(event, value) => setPatient(value.id)}
                                                onInputChange={(event) => {
                                                    setSearchTerm(event.target.value)
                                                }}
                                                getOptionSelected={(option, value) => option.id === value.id}
                                                getOptionLabel={(option) => `${option.name} (#${option.code})`}
                                                options={patients}
                                                loading={isSearching}
                                                renderInput={(params) => (
                                                    <TextField variant="outlined"
                                                        {...params}
                                                        error={patientHasError}
                                                        helperText={patientError}
                                                        className={classes.textField}
                                                        label="Paciente"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {isSearching ? <CircularProgress color="inherit"
                                                                                                     size={20}/> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                    }
                                    <Typography variant="subtitle2" className={ classes.subtitle }>Medicinas</Typography>
                                    {
                                        selectedMedicines.map((medicine, index) => (
                                            <Paper style={{ padding: 20, marginBottom: 20 }} key={ `medicine_${index}`}>
                                                <FormControl className={ classes.formControl } error={ medicine.name.error !== '' }>
                                                    <InputLabel id={`medicine_${index}_label`}>Medicina</InputLabel>
                                                    <Select
                                                        labelId={`medicine_${index}_label`}
                                                        id={`medicine_${index}`}
                                                        value={ medicine.name.value }
                                                        onChange={ (event) => handleInputChange(event.target.value, 'name', index)}
                                                        MenuProps={MenuProps}
                                                    >
                                                        { medicinesOptions }
                                                    </Select>
                                                    <FormHelperText>{ medicine.name.error }</FormHelperText>
                                                </FormControl>
                                                <Grid container spacing={3}>
                                                    <Grid item sm={6} xs={12}>
                                                        <TextField
                                                            id={`description_${index}`}
                                                            label="Descripción"
                                                            error={ medicine.description.error !== '' }
                                                            helperText={ medicine.description.error }
                                                            value={ medicine.description.value }
                                                            onChange={ (event) => handleInputChange(event.target.value, 'description', index)}
                                                            fullWidth
                                                            className={ classes.textField }
                                                        />
                                                    </Grid>
                                                    <Grid item sm={6} xs={12}>
                                                        <TextField
                                                            id={`dosis_${index}`}
                                                            label="Dosis"
                                                            error={ medicine.dosis.error !== '' }
                                                            helperText={ medicine.dosis.error }
                                                            value={ medicine.dosis.value }
                                                            onChange={ (event) => handleInputChange(event.target.value, 'dosis', index)}
                                                            fullWidth
                                                            className={ classes.textField }
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={3}>
                                                    <Grid item sm={6} xs={12}>
                                                        <TextField
                                                            id={`description_en_${index}`}
                                                            label="Descripción (En)"
                                                            error={ medicine.descriptionEn.error !== ''}
                                                            helperText={ medicine.descriptionEn.error }
                                                            value={ medicine.descriptionEn.value }
                                                            onChange={ (event) => handleInputChange(event.target.value, 'descriptionEn', index)}
                                                            fullWidth
                                                            className={ classes.textField }
                                                        />
                                                    </Grid>
                                                    <Grid item sm={6} xs={12}>
                                                        <TextField
                                                            id={`dosis_en_${index}`}
                                                            label="Dosis (En)"
                                                            error={ medicine.dosisEn.error !== ''}
                                                            helperText={ medicine.dosisEn.error }
                                                            value={ medicine.dosisEn.value }
                                                            onChange={ (event) => handleInputChange(event.target.value, 'dosisEn', index)}
                                                            fullWidth
                                                            className={ classes.textField }
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <div className={ classes.buttonWrapper }>
                                                    <Button color="secondary" onClick={ () => deleteMedicine(index) } startIcon={<DeleteIcon />}>Eliminar</Button>
                                                </div>
                                            </Paper>
                                        ))
                                    }

                                    <div style={{ display: 'flex', alignItems:'center', justifyContent:'center', flexDirection:'row'}}>
                                        <Button color="primary" onClick={ addMedicine } startIcon={<AddIcon />} style={{ marginBottom: 30 }}>Agregar medicina</Button>
                                    </div>

                                    <TextField variant="outlined"
                                        id="comments"
                                        label="Leyenda"
                                        fullWidth
                                        multiline
                                        rows={3}
                                        className={ classes.textField }
                                        value={ comments }
                                        onChange={ (event) => setComments(event.target.value) }
                                        error={ commentsHasError }
                                        helperText={ commentsError }
                                    />
                                    <TextField variant="outlined"
                                        id="commentsEn"
                                        label="Leyenda (Inglés)"
                                        fullWidth
                                        multiline
                                        rows={3}
                                        className={ classes.textField }
                                        value={ commentsEn }
                                        onChange={ (event) => setCommentsEn(event.target.value) }
                                        error={ commentsEnHasError }
                                        helperText={ commentsEnError }
                                    />
                                    <div className={ classes.buttonWrapper }>
                                        <Button color="secondary" onClick={ handleSubmit }>Crear</Button>
                                    </div>
                                </div>
                            </div>
                        </Paper>
            }
            <Snack message={ snackbarMessage } open={ snackbarOpen } handleClose={ () => setSnackbarOpen(false) } />
            <Dialog
                open={isPrintDialogOpen}
                onClose={() => setIsPrintDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">"Imprimir Receta"</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Seleccione el lenguaje en el cual desea imprimir la receta.
                    </DialogContentText>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="lang" name="lang" value={lang} onChange={(event) => setLang(event.target.value)} row>
                            <FormControlLabel value="es" control={<Radio />} label="Español" />
                            <FormControlLabel value="en" control={<Radio />} label="Inglés" />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsPrintDialogOpen(false)} color="primary">
                        Cancelar
                    </Button>
                    {
                        ready ?
                            <PDFDownloadLink style={{textDecoration: 'none'}}
                                             document={<Prescription data={loadedData} lang={lang}/>}
                                             fileName={`Receta_${loadedData ? loadedData.prescription.id : ''}.pdf`}>
                                {
                                    ({blob, url, loading, error}) => {
                                        return <Button color="primary" autoFocus disabled={loading}>
                                            Copia
                                        </Button>
                                    }
                                }
                            </PDFDownloadLink> :
                            null
                    }
                    {
                        ready ?
                            <PDFDownloadLink style={{textDecoration: 'none'}}
                                             document={<PrescriptionReal data={loadedData} lang={lang}/>}
                                             fileName={`Receta_${loadedData ? loadedData.prescription.id : ''}.pdf`}>
                                {
                                    ({blob, url, loading, error}) => {
                                        return <Button color="primary" autoFocus disabled={loading}>
                                            Válida
                                        </Button>
                                    }
                                }
                            </PDFDownloadLink> :
                            null
                    }
                </DialogActions>
            </Dialog>
        </div>
    )
};

export default PrescriptionsCreate;
