import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import validate from 'validate.js'
import Snack from "../../../components/Snack/Snack";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import axios from "axios";
import {API_URL, PUBLIC_URL} from "../../../config";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import Autocomplete from '@material-ui/lab/Autocomplete';
import useDebounce from "../../../hooks/useDebounce";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import FormHelperText from "@material-ui/core/FormHelperText";
import {KeyboardDatePicker} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import WarningIcon from "@material-ui/core/SvgIcon/SvgIcon";
import moment from "moment";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    padding: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    title: {
        flex: '1 1 100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    foodWrapper: {
        marginBottom: 20
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    insideProgress: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backButton: {
        marginRight: 8
    },
    textField: {
        marginBottom: theme.spacing(2),
        width: '100%'
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
    chipWrapper: {
        marginBottom: 20
    },
    chip: {
        margin: 2,
    },
    errorWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '100%'
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const TreatmentsCreate = ({ history, match }) => {

    const classes = useStyles();

    // ID del registro
    const patientId = match.params.id;

    /* *** State de formulario *** */

    const [patient, setPatient] = useState('');
    const [patientHasError, setPatientHasError] = useState(false);
    const [patientError, setPatientError] = useState('');

    const [description, setDescription] = useState('');
    const [descriptionHasError, setDescriptionHasError] = useState(false);
    const [descriptionError, setDescriptionError] = useState('');

    const [weight, setWeight] = useState('');
    const [weightHasError, setWeightHasError] = useState(false);
    const [weightError, setWeightError] = useState('');

    const [torax, setTorax] = useState('');
    const [toraxHasError, setToraxHasError] = useState(false);
    const [toraxError, setToraxError] = useState('');

    const [waist, setWaist] = useState('');
    const [waistHasError, setWaistHasError] = useState(false);
    const [waistError, setWaistError] = useState('');

    const [hip, setHip] = useState('');
    const [hipHasError, setHipHasError] = useState(false);
    const [hipError, setHipError] = useState('');

    const [treatedAt, setTreatedAt] = useState(moment().format('YYYY-MM-DD'));
    const [treatedAtHasError, setTreatedAtHasError] = useState(false);
    const [treatedAtError, setTreatedAtError] = useState('');

    const [nextTreatmentAt, setNextTreatmentAt] = useState(moment().add(10, 'days').format('YYYY-MM-DD'));
    const [nextTreatmentAtHasError, setNextTreatmentAtHasError] = useState(false);
    const [nextTreatmentAtError, setNextTreatmentAtError] = useState('');

    /* *** State de formulario *** */

    const [patients, setPatients] = useState([]);

    // State de snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(false);

    const [open, setOpen] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');
    const [isSearching, setIsSearching] = useState(false);

    const [error, setError] = useState('');

    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    // Reglas de validación de formulario
    const constraints = {
        patient: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            }
        },
        description: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            }
        },
        treatedAt: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            }
        }
    };

    useEffect(() => {


        if(patientId)
            loadPatient();

    }, []);

    const loadPatient = async () => {

        setIsLoading(true);

        try {

            const response = await axios.get(`${API_URL}/patients/${patientId}`);
            const patientData = response.data.patient;

            // Actualizar stare
            setPatient(patientData);

        } catch(error){

            setError('Ocurrió un error cargando el paciente');

            // Mostrar mensaje de error
            setSnackbarMessage('Ocurrió un error cargando el paciente');
            setSnackbarOpen(true);

        }

        // Esconder indicador de carga
        setIsLoading(false);
    };

    const handleServerError = (error) => {

        if(error.patient_id){
            setPatientHasError(true);
            setPatientError(error.patient_id[0]);
        } else {
            setPatientHasError(false);
            setPatientError('');
        }

        if(error.description){
            setDescriptionHasError(true);
            setDescriptionError(error.description[0]);
        } else {
            setDescriptionHasError(false);
            setDescriptionError('');
        }

        if(error.weight){
            setWeightHasError(true);
            setWeightError(error.weight[0]);
        } else {
            setWeightHasError(false);
            setWeightError('');
        }

        if(error.torax){
            setToraxHasError(true);
            setToraxError(error.torax[0]);
        } else {
            setToraxHasError(false);
            setToraxError('');
        }

        if(error.waist){
            setWaistHasError(true);
            setWaistError(error.waist[0]);
        } else {
            setWaistHasError(false);
            setWaistError('');
        }

        if(error.hip){
            setHipHasError(true);
            setHipError(error.hip[0]);
        } else {
            setHipHasError(false);
            setHipError('');
        }

        if(error.treated_at){
            setTreatedAtHasError(true);
            setTreatedAtError(error.treated_at[0]);
        } else {
            setTreatedAtHasError(false);
            setTreatedAtError('');
        }

        if(error.next_treatment_at){
            setNextTreatmentAtHasError(true);
            setNextTreatmentAtError(error.next_treatment_at[0]);
        } else {
            setNextTreatmentAtHasError(false);
            setNextTreatmentAtError('');
        }
    };

    // Función que se ejecuta al enviar el formulario
    const handleSubmit = async () => {

        if(weight !== '')
            constraints.weight = {
                numericality: {
                    greaterThanOrEqualTo: 0,
                    message: 'El valor no es válido, debe ingresar un número mayor o igual a 0.'
                }
            };

        if(torax !== '')
            constraints.torax = {
                numericality: {
                    greaterThanOrEqualTo: 0,
                    message: 'El valor no es válido, debe ingresar un número mayor o igual a 0.'
                }
            };

        if(waist !== '')
            constraints.waist = {
                numericality: {
                    greaterThanOrEqualTo: 0,
                    message: 'El valor no es válido, debe ingresar un número mayor o igual a 0.'
                }
            };

        if(hip !== '')
            constraints.hip = {
                numericality: {
                    greaterThanOrEqualTo: 0,
                    message: 'El valor no es válido, debe ingresar un número mayor o igual a 0.'
                }
            };

        // Validar campos
        let valid = validate({ patient, description, weight, torax, waist, hip, treatedAt, nextTreatmentAt }, constraints, {fullMessages: false});

        // Si la validación pasó
        if(valid === undefined){

            // Mostrar el indicador de carga
            setIsLoading(true);

            try {

                let requestData = {};

                if(patientId){
                    requestData = {
                        patient_id: patient.id,
                        description,
                        treated_at: treatedAt
                    };
                } else {
                    requestData = {
                        patient_id: patient,
                        description,
                        treated_at: treatedAt
                    };
                }

                if(weight)
                    requestData.weight = weight;

                if(torax)
                    requestData.torax = torax;

                if(waist)
                    requestData.waist = waist;

                if(hip)
                    requestData.hip = hip;

                if(nextTreatmentAt)
                    requestData.next_treatment_at = nextTreatmentAt;

                await axios.post(
                    `${API_URL}/treatments`,
                    requestData
                );

                history.goBack();

            } catch (error) {

                switch(error.response.status){
                    case 400:
                        // Mostrar mensaje
                        setSnackbarMessage('El contenido enviado no cumplió las reglas de validación');
                        setSnackbarOpen(true);
                        handleServerError(error.response.data.errors);
                        break;
                    case 422:
                        // Mostrar mensaje
                        setSnackbarMessage('El contenido enviado no cumplió las reglas de validación');
                        setSnackbarOpen(true);
                        handleServerError(error.response.data.errors);
                        break;
                    default:
                        // Mostrar mensaje
                        setSnackbarMessage('Ocurrió un error registrando la dieta de paciente.');
                        setSnackbarOpen(true);
                        break;
                }

            }

            // Esconder indicador de carga
            setIsLoading(false);

        } else {

            if(valid.patient){
                setPatientHasError(true);
                setPatientError(valid.patient[0]);
            } else {
                setPatientHasError(false);
                setPatientError('');
            }

            if(valid.description){
                setDescriptionHasError(true);
                setDescriptionError(valid.description[0]);
            } else {
                setDescriptionHasError(false);
                setDescriptionError('');
            }

            if(valid.weight){
                setWeightHasError(true);
                setWeightError(valid.weight[0]);
            } else {
                setWeightHasError(false);
                setWeightError('');
            }

            if(valid.torax){
                setToraxHasError(true);
                setToraxError(valid.torax[0]);
            } else {
                setToraxHasError(false);
                setToraxError('');
            }

            if(valid.waist){
                setWaistHasError(true);
                setWaistError(valid.waist[0]);
            } else {
                setWaistHasError(false);
                setWaistError('');
            }

            if(valid.hip){
                setHipHasError(true);
                setHipError(valid.hip[0]);
            } else {
                setHipHasError(false);
                setHipError('');
            }

            if(valid.treatedAt){
                setTreatedAtHasError(true);
                setTreatedAtError(valid.treatedAt[0]);
            } else {
                setTreatedAtHasError(false);
                setTreatedAtError('');
            }

            if(valid.nextTreatmentAt){
                setNextTreatmentAtHasError(true);
                setNextTreatmentAtError(valid.nextTreatmentAt[0]);
            } else {
                setNextTreatmentAtHasError(false);
                setNextTreatmentAtError('');
            }

        }



    };

    useEffect(
        () => {

            const searchPatients = async () => {

                // Make sure we have a value (user has entered something in input)
                if (debouncedSearchTerm) {

                    // Set isSearching state
                    setIsSearching(true);

                    try {

                        const data = {
                            active: true,
                            direction: 'asc',
                        };

                        if(isNaN(debouncedSearchTerm)) {
                            data.name = debouncedSearchTerm;
                            data.sort = 'special';
                        } else {
                            data.id = debouncedSearchTerm;
                            data.sort = 'id';
                        }

                        const response = await axios.get(
                            `${API_URL}/patients`, {
                                params: data
                            }
                        );

                        const patientsData = response.data;

                        setPatients(patientsData);

                    } catch (error) {

                        setSnackbarMessage('Ocurrió un error cargando los pacientes.');
                        setSnackbarOpen(true);

                    }

                    setIsSearching(false);

                } else {
                    setPatients([]);
                }

            };

            searchPatients();

        },
        // This is the useEffect input array
        // Our useEffect function will only execute if this value changes ...
        // ... and thanks to our hook it will only change if the original ...
        // value (searchTerm) hasn't changed for more than 500ms.
        [debouncedSearchTerm]
    );

    const handleTreatedAt = (date) => {
        if(date !== null){
            setTreatedAt(date.format('YYYY-MM-DD'));
            date.add(10, 'days');
            setNextTreatmentAt(date.format('YYYY-MM-DD'));
        } else {
            setTreatedAt('');
        }
    };

    return (
        <div className={classes.root}>
            {
                isLoading ?
                    <div className={ classes.progress }>
                        <CircularProgress />
                    </div> :
                    error !== '' ?
                        <div className={ classes.errorWrapper }>
                            <WarningIcon color="primary" style={ { marginRight: 8 } }/>
                            <Typography>{ error }</Typography>
                        </div> :
                        <Paper className={classes.paper}>
                            <Toolbar className={classes.padding}>
                                <IconButton className={ classes.backButton } onClick={() => history.goBack() }>
                                    <ArrowBackIcon color="secondary" />
                                </IconButton>
                                <Typography className={classes.title} variant="h6" id="title">Historia Clínica</Typography>
                            </Toolbar>
                            <div>
                                <div className={classes.padding}>
                                    {
                                        patientId ?
                                            <TextField variant="outlined"
                                                id="patient"
                                                label="Paciente"
                                                fullWidth
                                                className={ classes.textField }
                                                value={ patient.name }
                                                disabled
                                                error={ patientHasError }
                                                helperText={ patientError }
                                            /> :
                                            <Autocomplete
                                                id="patient"
                                                open={ open }
                                                fullWidth
                                                onOpen={() => {
                                                    setOpen(true);
                                                }}
                                                onClose={() => {
                                                    setOpen(false);
                                                }}
                                                onChange={ (event, value) => setPatient(value.id) }
                                                onInputChange={ (event) => {setSearchTerm(event.target.value)} }
                                                getOptionSelected={(option, value) => option.id === value.id}
                                                getOptionLabel={(option) => `${option.name} (#${option.id})`}
                                                options={patients}
                                                loading={isSearching}
                                                renderInput={(params) => (
                                                    <TextField variant="outlined"
                                                        {...params}
                                                        error={ patientHasError }
                                                        helperText={ patientError }
                                                        className={ classes.textField }
                                                        label="Paciente"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {isSearching ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                    }
                                    <TextField variant="outlined"
                                        id="description"
                                        label="Tratamiento"
                                        multiline
                                        rows={3}
                                        fullWidth
                                        className={ classes.textField }
                                        value={ description }
                                        onChange={ (event) => setDescription(event.target.value) }
                                        error={ descriptionHasError }
                                        helperText={ descriptionError }
                                    />
                                    <Grid container spacing={3}>
                                        <Grid item sm={3} xs={12}>
                                            <TextField variant="outlined"
                                                       id="weight"
                                                       label="Peso"
                                                       type="number"
                                                       fullWidth
                                                       className={ classes.textField }
                                                       value={ weight }
                                                       onChange={ (event) => setWeight(event.target.value) }
                                                       error={ weightHasError }
                                                       helperText={ weightError }
                                            />
                                        </Grid>
                                        <Grid item sm={3} xs={12}>
                                            <TextField variant="outlined"
                                                id="torax"
                                                label="Torax"
                                                type="number"
                                                fullWidth
                                                className={ classes.textField }
                                                value={ torax }
                                                onChange={ (event) => setTorax(event.target.value) }
                                                error={ toraxHasError }
                                                helperText={ toraxError }
                                            />
                                        </Grid>
                                        <Grid item sm={3} xs={12}>
                                            <TextField variant="outlined"
                                                       id="waist"
                                                       label="Cintura"
                                                       type="number"
                                                       fullWidth
                                                       className={ classes.textField }
                                                       value={ waist }
                                                       onChange={ (event) => setWaist(event.target.value) }
                                                       error={ waistHasError }
                                                       helperText={ waistError }
                                            />
                                        </Grid>
                                        <Grid item sm={3} xs={12}>
                                            <TextField variant="outlined"
                                                       id="hip"
                                                       label="Cadera"
                                                       type="number"
                                                       fullWidth
                                                       className={ classes.textField }
                                                       value={ hip }
                                                       onChange={ (event) => setHip(event.target.value) }
                                                       error={ hipHasError }
                                                       helperText={ hipError }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item sm={6} xs={12}>
                                            <KeyboardDatePicker
                                                error={treatedAtHasError}
                                                invalidDateMessage={ treatedAtError }
                                                maxDateMessage={ treatedAtError }
                                                variant="inline"
                                                format="DD-MM-YYYY"
                                                style={{marginTop: 0, marginBottom: 16}}
                                                fullWidth
                                                id="treatedAt"
                                                label="Fecha de Tratamiento"
                                                value={treatedAt}
                                                onChange={(date) => handleTreatedAt(date)}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'cambiar fecha',
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <KeyboardDatePicker
                                                error={nextTreatmentAtHasError}
                                                invalidDateMessage={ nextTreatmentAtError }
                                                maxDateMessage={ nextTreatmentAtError }
                                                disablePast
                                                variant="inline"
                                                format="DD-MM-YYYY"
                                                style={{marginTop: 0, marginBottom: 16}}
                                                fullWidth
                                                id="nextTreatmentAt"
                                                label="Fecha de Próxima Cita"
                                                value={nextTreatmentAt}
                                                onChange={(date) => setNextTreatmentAt(date !== null ? date.format('YYYY-MM-DD') : '')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'cambiar fecha',
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <div className={ classes.buttonWrapper }>
                                        <Button color="secondary" onClick={ handleSubmit }>Crear</Button>
                                    </div>
                                </div>
                            </div>
                        </Paper>
            }
            <Snack message={ snackbarMessage } open={ snackbarOpen } handleClose={ () => setSnackbarOpen(false) } />
        </div>
    )
};

export default TreatmentsCreate;
