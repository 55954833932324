import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import validate from 'validate.js'
import Snack from "../../../components/Snack/Snack";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import {NavLink} from "react-router-dom";
import axios from "axios";
import {API_URL} from "../../../config";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import WarningIcon from '@material-ui/icons/Warning';
import TransferDraggableList from "../../../components/TransferDraggableList/TransferDraggableList";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    padding: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    title: {
        flex: '1 1 100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    foodWrapper: {
        marginBottom: 20
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backButton: {
        marginRight: 8
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    errorWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '100%'
    },
    iconError: {
        color: theme.palette.error.main
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
    subtitle: {
        color: theme.palette.primary.main,
        marginTop: 20
    }
}));

const DietEdit = ({ match }) => {

    const classes = useStyles();

    /* *** State de formulario *** */

    // State de nombre
    const [title, setTitle] = useState('');
    const [titleHasError, setTitleHasError] = useState(false);
    const [titleError, setTitleError] = useState('');

    const [leyend, setLeyend] = useState('');
    const [leyendHasError, setLeyendHasError] = useState(false);
    const [leyendError, setLeyendError] = useState('');

    const [leyendEn, setLeyendEn] = useState('');
    const [leyendEnHasError, setLeyendEnHasError] = useState(false);
    const [leyendEnError, setLeyendEnError] = useState('');

    /* *** State de formulario *** */

    const [allowedFoodItems, setAllowedFoodItems] = useState([]);
    const [selectedAllowedFoodItems, setSelectedAllowedFoodItems] = useState([]);

    const [allowedDrinkItems, setAllowedDrinkItems] = useState([]);
    const [selectedAllowedDrinkItems, setSelectedAllowedDrinkItems] = useState([]);

    const [allowedAlcoholItems, setAllowedAlcoholItems] = useState([]);
    const [selectedAllowedAlcoholItems, setSelectedAllowedAlcoholItems] = useState([]);

    const [forbiddenFoodItems, setForbiddenFoodItems] = useState([]);
    const [selectedForbiddenFoodItems, setSelectedForbiddenFoodItems] = useState([]);

    const [forbiddenDrinkItems, setForbiddenDrinkItems] = useState([]);
    const [selectedForbiddenDrinkItems, setSelectedForbiddenDrinkItems] = useState([]);

    const [forbiddenAlcoholItems, setForbiddenAlcoholItems] = useState([]);
    const [selectedForbiddenAlcoholItems, setSelectedForbiddenAlcoholItems] = useState([]);

    // State de snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(false);

    const [error, setError] = useState('');

    // ID del registro
    const id = match.params.id;

    // Reglas de validación de formulario
    const constraints = {
        title: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            }
        }
    };

    useEffect( () => {

        const init = async () => {
            await loadDiet();
        };

        init();

    }, []);

    const loadDiet = async () => {

        try {

            const response = await axios.get(`${API_URL}/diets/${id}`);
            const dietData = response.data;

            // Actualizar state
            setTitle(dietData.diet.title);
            setLeyend(dietData.diet.leyend);
            setLeyendEn(dietData.diet.leyend_en);
            await loadFoodItems(dietData.food_items);

        } catch(error){

            setError('Ocurrió un error cargando la dieta');

            // Mostrar mensaje de error
            setSnackbarMessage('Ocurrió un error cargando la dieta');
            setSnackbarOpen(true);

        }

        // Esconder indicador de carga
        setIsLoading(false);
    };

    // Función que carga los alimentos
    const loadFoodItems = async (foodItemIds) => {

        // Mostrar indicador de carga
        setIsLoading(true);

        try {

            // Hacer request
            const response = await axios.get(
                `${API_URL}/food_items`,
                {
                    params: {
                        active: true,
                        sort: 'name',
                        direction: 'asc'
                    }
                }
            );

            let foodItemsData = response.data;

            let allowedFoodData = foodItemsData.filter(item => item.is_allowed && item.category === 1);
            let forbiddenFoodData = foodItemsData.filter(item => !item.is_allowed && item.category === 1);
            let allowedDrinkData = foodItemsData.filter(item => item.is_allowed && item.category === 2);
            let forbiddenDrinkData = foodItemsData.filter(item => !item.is_allowed && item.category === 2);
            let allowedAlcoholData = foodItemsData.filter(item => item.is_allowed && item.category === 3);
            let forbiddenAlcoholData = foodItemsData.filter(item => !item.is_allowed && item.category === 3);

            setAllowedFoodItems(allowedFoodData.filter(item => !foodItemIds.map(it => it.id).includes(item.id)));
            setSelectedAllowedFoodItems(foodItemIds.filter(item => item.is_allowed && item.category === 1));

            setForbiddenFoodItems(forbiddenFoodData.filter(item => !foodItemIds.map(it => it.id).includes(item.id)));
            setSelectedForbiddenFoodItems(foodItemIds.filter(item => !item.is_allowed && item.category === 1));

            setAllowedDrinkItems(allowedDrinkData.filter(item => !foodItemIds.map(it => it.id).includes(item.id)));
            setSelectedAllowedDrinkItems(foodItemIds.filter(item => item.is_allowed && item.category === 2));

            setForbiddenDrinkItems(forbiddenDrinkData.filter(item => !foodItemIds.map(it => it.id).includes(item.id)));
            setSelectedForbiddenDrinkItems(foodItemIds.filter(item => !item.is_allowed && item.category === 2));

            setAllowedAlcoholItems(allowedAlcoholData.filter(item => !foodItemIds.map(it => it.id).includes(item.id)));
            setSelectedAllowedAlcoholItems(foodItemIds.filter(item => item.is_allowed && item.category === 3));

            setForbiddenAlcoholItems(forbiddenAlcoholData.filter(item => !foodItemIds.map(it => it.id).includes(item.id)));
            setSelectedForbiddenAlcoholItems(foodItemIds.filter(item => !item.is_allowed && item.category === 3));

        } catch(error){

            // Mostrar mensaje de error
            setSnackbarMessage('Ocurrió un error cargando los alimentos');
            setSnackbarOpen(true);

        }

        // Esconder indicador de carga
        setIsLoading(false);
    };

    const handleServerError = (error) => {

        if(error.title){
            setTitleHasError(true);
            setTitleError(error.title[0]);
        } else {
            setTitleHasError(false);
            setTitleError('');
        }

        if(error.leyend){
            setLeyendHasError(true);
            setLeyendError(error.leyend[0]);
        } else {
            setLeyendHasError(false);
            setLeyendError('');
        }

        if(error.leyend_en){
            setLeyendEnHasError(true);
            setLeyendEnError(error.leyend_en[0]);
        } else {
            setLeyendEnHasError(false);
            setLeyendEnError('');
        }
    };

    // Función que se ejecuta al enviar el formulario
    const handleSubmit = async () => {

        // Validar campos
        let valid = validate({ title }, constraints, {fullMessages: false});

        // Si la validación pasó
        if(valid === undefined){

            // Mostrar el indicador de carga
            setIsLoading(true);

            try {

                const allowedFoodIds = selectedAllowedFoodItems.map(foodItem => foodItem.id);
                const allowedDrinkIds = selectedAllowedDrinkItems.map(foodItem => foodItem.id);
                const allowedAlcoholIds = selectedAllowedAlcoholItems.map(foodItem => foodItem.id);
                const forbiddenFoodIds = selectedForbiddenFoodItems.map(foodItem => foodItem.id);
                const forbiddenDrinkIds = selectedForbiddenDrinkItems.map(foodItem => foodItem.id);
                const forbiddenAlcoholIds = selectedForbiddenAlcoholItems.map(foodItem => foodItem.id);

                let itemIds = [];
                itemIds = itemIds.concat(allowedFoodIds);
                itemIds = itemIds.concat(allowedDrinkIds);
                itemIds = itemIds.concat(allowedAlcoholIds);
                itemIds = itemIds.concat(forbiddenFoodIds);
                itemIds = itemIds.concat(forbiddenDrinkIds);
                itemIds = itemIds.concat(forbiddenAlcoholIds);

                await axios.put(
                    `${API_URL}/diets/${id}`,
                    {
                        title,
                        leyend,
                        leyend_en: leyendEn,
                        food_item_ids: itemIds
                    }
                );

                // Mostrar mensaje
                setSnackbarMessage('Dieta registrada exitosamente');
                setSnackbarOpen(true);

            } catch (error) {

                switch(error.response.status){
                    case 400:
                        // Mostrar mensaje
                        setSnackbarMessage('El contenido enviado no cumplió las reglas de validación');
                        setSnackbarOpen(true);
                        handleServerError(error.response.data.errors);
                        break;
                    case 422:
                        // Mostrar mensaje
                        setSnackbarMessage('El contenido enviado no cumplió las reglas de validación');
                        setSnackbarOpen(true);
                        handleServerError(error.response.data.errors);
                        break;
                    default:
                        // Mostrar mensaje
                        setSnackbarMessage('Ocurrió un error registrando la medicina');
                        setSnackbarOpen(true);
                        break;
                }

            }

            // Esconder indicador de carga
            setIsLoading(false);

        } else {

            if(valid.title){
                setTitleHasError(true);
                setTitleError(valid.title[0]);
            } else {
                setTitleHasError(false);
                setTitleError('');
            }

        }



    };

    return (
        <div className={classes.root}>
            {
                isLoading ?
                    <div className={ classes.progress }>
                        <CircularProgress />
                    </div> :
                    error !== '' ?
                        <div className={ classes.errorWrapper }>
                            <WarningIcon color="primary" style={ { marginRight: 8 } }/>
                            <Typography>{ error }</Typography>
                        </div> :
                        <Paper className={classes.paper}>
                            <Toolbar className={classes.padding}>
                                <IconButton className={ classes.backButton } component={ NavLink } to="/diets">
                                    <ArrowBackIcon color="secondary" />
                                </IconButton>
                                <Typography className={classes.title} variant="h6" id="title">Dieta</Typography>
                            </Toolbar>
                            <div>
                                <div className={classes.padding}>
                                    <TextField variant="outlined"
                                        id="title"
                                        label="Título"
                                        fullWidth
                                        className={ classes.textField }
                                        value={ title }
                                        onChange={ (event) => setTitle(event.target.value) }
                                        error={ titleHasError }
                                        helperText={ titleError }
                                    />
                                    <TextField variant="outlined"
                                               id="leyend"
                                               label="Leyenda"
                                               fullWidth
                                               multiline
                                               rows={3}
                                               className={ classes.textField }
                                               value={ leyend }
                                               onChange={ (event) => setLeyend(event.target.value) }
                                               error={ leyendHasError }
                                               helperText={ leyendError }
                                    />
                                    <TextField variant="outlined"
                                               id="leyend_en"
                                               label="Leyenda (Inglés)"
                                               fullWidth
                                               multiline
                                               rows={3}
                                               className={ classes.textField }
                                               value={ leyendEn }
                                               onChange={ (event) => setLeyendEn(event.target.value) }
                                               error={ leyendEnHasError }
                                               helperText={ leyendEnError }
                                    />
                                    <Typography variant="subtitle2" className={ classes.subtitle }>Alimentos Permitidos</Typography>
                                    <TransferDraggableList items={allowedFoodItems} selectedItems={selectedAllowedFoodItems} onSelectedChange={setSelectedAllowedFoodItems} onInitialChange={setAllowedFoodItems} />
                                    <Typography variant="subtitle2" className={ classes.subtitle }>Alimentos Prohibidos</Typography>
                                    <TransferDraggableList items={forbiddenFoodItems} selectedItems={selectedForbiddenFoodItems} onSelectedChange={setSelectedForbiddenFoodItems} onInitialChange={setForbiddenFoodItems} />
                                    <Typography variant="subtitle2" className={ classes.subtitle }>Bebidas Permitidas</Typography>
                                    <TransferDraggableList items={allowedDrinkItems} selectedItems={selectedAllowedDrinkItems} onSelectedChange={setSelectedAllowedDrinkItems} onInitialChange={setSelectedAllowedDrinkItems} />
                                    <Typography variant="subtitle2" className={ classes.subtitle }>Bebidas Prohibidas</Typography>
                                    <TransferDraggableList items={forbiddenDrinkItems} selectedItems={selectedForbiddenDrinkItems} onSelectedChange={setSelectedForbiddenDrinkItems} onInitialChange={setForbiddenDrinkItems} />
                                    <Typography variant="subtitle2" className={ classes.subtitle }>Alcoholes Permitidos</Typography>
                                    <TransferDraggableList items={allowedAlcoholItems} selectedItems={selectedAllowedAlcoholItems} onSelectedChange={setSelectedAllowedAlcoholItems} onInitialChange={setAllowedAlcoholItems} />
                                    <Typography variant="subtitle2" className={ classes.subtitle }>Alcoholes Prohibidos</Typography>
                                    <TransferDraggableList items={forbiddenAlcoholItems} selectedItems={selectedForbiddenAlcoholItems} onSelectedChange={setSelectedForbiddenAlcoholItems} onInitialChange={setForbiddenAlcoholItems} />
                                    <div className={ classes.buttonWrapper }>
                                        <Button color="secondary" onClick={ handleSubmit }>Editar</Button>
                                    </div>
                                </div>
                            </div>
                        </Paper>
            }
            <Snack message={ snackbarMessage } open={ snackbarOpen } handleClose={ () => setSnackbarOpen(false) } />
        </div>
    )
};

export default DietEdit;
