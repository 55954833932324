import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import validate from 'validate.js'
import Snack from "../../../components/Snack/Snack";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import WarningIcon from '@material-ui/icons/Warning';
import {API_URL} from "../../../config";
import ColorPicker from "material-ui-color-picker";
import InputAdornment from "@material-ui/core/InputAdornment";
import OpacityIcon from '@material-ui/icons/Opacity';
import Grid from "@material-ui/core/Grid";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    padding: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    title: {
        flex: '1 1 100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backButton: {
        marginRight: 8
    },
    errorWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '100%'
    },
    iconError: {
        color: theme.palette.error.main
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
    subtitle: {
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(2)
    }
}));

const ConfigurationsEdit = ({ match }) => {

    const classes = useStyles();

    /* *** State de formulario *** */

    // State de nombre
    const [dietContactColor, setDietContactColor] = useState('#000000');
    const [dietContactColorHasError, setDietContactColorHasError] = useState(false);
    const [dietContactColorError, setDietContactColorError] = useState('');

    const [dietInfoLabelColor, setDietInfoLabelColor] = useState('#000000');
    const [dietInfoLabelColorHasError, setDietInfoLabelColorHasError] = useState(false);
    const [dietInfoLabelColorError, setDietInfoLabelColorError] = useState('');

    const [dietInfoContentColor, setDietInfoContentColor] = useState('#FF0000');
    const [dietInfoContentColorHasError, setDietInfoContentColorHasError] = useState(false);
    const [dietInfoContentColorError, setDietInfoContentColorError] = useState('');

    const [dietNameColor, setDietNameColor] = useState('#FF0000');
    const [dietNameColorHasError, setDietNameColorHasError] = useState(false);
    const [dietNameColorError, setDietNameColorError] = useState('');

    const [dietDescriptionColor, setDietDescriptionColor] = useState('#0D47A1');
    const [dietDescriptionColorHasError, setDietDescriptionColorHasError] = useState(false);
    const [dietDescriptionColorError, setDietDescriptionColorError] = useState('');

    const [dietAllowedColumnHeaderColor, setDietAllowedColumnHeaderColor] = useState('#000000');
    const [dietAllowedColumnHeaderColorHasError, setDietAllowedColumnHeaderColorHasError] = useState(false);
    const [dietAllowedColumnHeaderColorError, setDietAllowedColumnHeaderColorError] = useState('');

    const [dietForbiddenColumnHeaderColor, setDietForbiddenColumnHeaderColor] = useState('#000000');
    const [dietForbiddenColumnHeaderColorHasError, setDietForbiddenColumnHeaderColorHasError] = useState(false);
    const [dietForbiddenColumnHeaderColorError, setDietForbiddenColumnHeaderColorError] = useState('');

    const [dietAllowedColor, setDietAllowedColor] = useState('#009688');
    const [dietAllowedColorHasError, setDietAllowedColorHasError] = useState(false);
    const [dietAllowedColorError, setDietAllowedColorError] = useState('');

    const [dietAllowedDescriptionColor, setDietAllowedDescriptionColor] = useState('#009688');
    const [dietAllowedDescriptionColorHasError, setDietAllowedDescriptionColorHasError] = useState(false);
    const [dietAllowedDescriptionColorError, setDietAllowedDescriptionColorError] = useState('');

    const [dietForbiddenColor, setDietForbiddenColor] = useState('#FF5754');
    const [dietForbiddenColorHasError, setDietForbiddenColorHasError] = useState(false);
    const [dietForbiddenColorError, setDietForbiddenColorError] = useState('');

    const [dietForbiddenDescriptionColor, setDietForbiddenDescriptionColor] = useState('#FF5754');
    const [dietForbiddenDescriptionColorHasError, setDietForbiddenDescriptionColorHasError] = useState(false);
    const [dietForbiddenDescriptionColorError, setDietForbiddenDescriptionColorError] = useState('');

    const [dietNoteLabelColor, setDietNoteLabelColor] = useState('#000000');
    const [dietNoteLabelColorHasError, setDietNoteLabelColorHasError] = useState(false);
    const [dietNoteLabelColorError, setDietNoteLabelColorError] = useState('');

    const [dietNoteContentColor, setDietNoteContentColor] = useState('#000000');
    const [dietNoteContentColorHasError, setDietNoteContentColorHasError] = useState(false);
    const [dietNoteContentColorError, setDietNoteContentColorError] = useState('');

    const [dietImportantContentColor, setDietImportantContentColor] = useState('#FF0000');
    const [dietImportantContentColorHasError, setDietImportantContentColorHasError] = useState(false);
    const [dietImportantContentColorError, setDietImportantContentColorError] = useState('');

    const [prescriptionContactColor, setPrescriptionContactColor] = useState('#000000');
    const [prescriptionContactColorHasError, setPrescriptionContactColorHasError] = useState(false);
    const [prescriptionContactColorError, setPrescriptionContactColorError] = useState('');

    const [prescriptionInfoLabelColor, setPrescriptionInfoLabelColor] = useState('#000000');
    const [prescriptionInfoLabelColorHasError, setPrescriptionInfoLabelColorHasError] = useState(false);
    const [prescriptionInfoLabelColorError, setPrescriptionInfoLabelColorError] = useState('');

    const [prescriptionInfoContentColor, setPrescriptionInfoContentColor] = useState('#000000');
    const [prescriptionInfoContentColorHasError, setPrescriptionInfoContentColorHasError] = useState(false);
    const [prescriptionInfoContentColorError, setPrescriptionInfoContentColorError] = useState('');

    const [prescriptionMedicineNameColor, setPrescriptionMedicineNameColor] = useState('#4B7454');
    const [prescriptionMedicineNameColorHasError, setPrescriptionMedicineNameColorHasError] = useState(false);
    const [prescriptionMedicineNameColorError, setPrescriptionMedicineNameColorError] = useState('');

    const [prescriptionMedicineDescriptionColor, setPrescriptionMedicineDescriptionColor] = useState('#828282');
    const [prescriptionMedicineDescriptionColorHasError, setPrescriptionMedicineDescriptionColorHasError] = useState(false);
    const [prescriptionMedicineDescriptionColorError, setPrescriptionMedicineDescriptionColorError] = useState('');

    const [prescriptionMedicineDosisColor, setPrescriptionMedicineDosisColor] = useState('#000000');
    const [prescriptionMedicineDosisColorHasError, setPrescriptionMedicineDosisColorHasError] = useState(false);
    const [prescriptionMedicineDosisColorError, setPrescriptionMedicineDosisColorError] = useState('');

    const [prescriptionCommentsColor, setPrescriptionCommentsColor] = useState('#FF0000');
    const [prescriptionCommentsColorHasError, setPrescriptionCommentsColorHasError] = useState(false);
    const [prescriptionCommentsColorError, setPrescriptionCommentsColorError] = useState('');

    const [prescriptionNotValidColor, setPrescriptionNotValidColor] = useState('#000000');
    const [prescriptionNotValidColorHasError, setPrescriptionNotValidColorHasError] = useState(false);
    const [prescriptionNotValidColorError, setPrescriptionNotValidColorError] = useState('');

    /* *** State de formulario *** */

    // State de snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(true);

    const [error, setError] = useState('');

    // ID del registro
    const id = match.params.id;

    // Reglas de validación de formulario
    const constraints = {
        dietContactColor: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            format: {
                pattern: "#([a-fA-F0-9]{6})",
                flags: "i",
                message: "El formato es incorrecto (ej. #000AAA)."
            }
        },
        dietInfoLabelColor: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            format: {
                pattern: "#([a-fA-F0-9]{6})",
                flags: "i",
                message: "El formato es incorrecto (ej. #000AAA)."
            }
        },
        dietInfoContentColor: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            format: {
                pattern: "#([a-fA-F0-9]{6})",
                flags: "i",
                message: "El formato es incorrecto (ej. #000AAA)."
            }
        },
        dietNameColor: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            format: {
                pattern: "#([a-fA-F0-9]{6})",
                flags: "i",
                message: "El formato es incorrecto (ej. #000AAA)."
            }
        },
        dietDescriptionColor: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            format: {
                pattern: "#([a-fA-F0-9]{6})",
                flags: "i",
                message: "El formato es incorrecto (ej. #000AAA)."
            }
        },
        dietAllowedColumnHeaderColor: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            format: {
                pattern: "#([a-fA-F0-9]{6})",
                flags: "i",
                message: "El formato es incorrecto (ej. #000AAA)."
            }
        },
        dietForbiddenColumnHeaderColor: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            format: {
                pattern: "#([a-fA-F0-9]{6})",
                flags: "i",
                message: "El formato es incorrecto (ej. #000AAA)."
            }
        },
        dietAllowedColor: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            format: {
                pattern: "#([a-fA-F0-9]{6})",
                flags: "i",
                message: "El formato es incorrecto (ej. #000AAA)."
            }
        },
        dietAllowedDescriptionColor: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            format: {
                pattern: "#([a-fA-F0-9]{6})",
                flags: "i",
                message: "El formato es incorrecto (ej. #000AAA)."
            }
        },
        dietForbiddenColor: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            format: {
                pattern: "#([a-fA-F0-9]{6})",
                flags: "i",
                message: "El formato es incorrecto (ej. #000AAA)."
            }
        },
        dietImportantContentColor: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            format: {
                pattern: "#([a-fA-F0-9]{6})",
                flags: "i",
                message: "El formato es incorrecto (ej. #000AAA)."
            }
        },
        dietForbiddenDescriptionColor: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            format: {
                pattern: "#([a-fA-F0-9]{6})",
                flags: "i",
                message: "El formato es incorrecto (ej. #000AAA)."
            }
        },
        dietNoteLabelColor: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            format: {
                pattern: "#([a-fA-F0-9]{6})",
                flags: "i",
                message: "El formato es incorrecto (ej. #000AAA)."
            }
        },
        prescriptionContactColor: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            format: {
                pattern: "#([a-fA-F0-9]{6})",
                flags: "i",
                message: "El formato es incorrecto (ej. #000AAA)."
            }
        },
        prescriptionInfoLabelColor: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            format: {
                pattern: "#([a-fA-F0-9]{6})",
                flags: "i",
                message: "El formato es incorrecto (ej. #000AAA)."
            }
        },
        prescriptionInfoContentColor: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            format: {
                pattern: "#([a-fA-F0-9]{6})",
                flags: "i",
                message: "El formato es incorrecto (ej. #000AAA)."
            }
        },
        prescriptionMedicineNameColor: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            format: {
                pattern: "#([a-fA-F0-9]{6})",
                flags: "i",
                message: "El formato es incorrecto (ej. #000AAA)."
            }
        },
        prescriptionMedicineDescriptionColor: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            format: {
                pattern: "#([a-fA-F0-9]{6})",
                flags: "i",
                message: "El formato es incorrecto (ej. #000AAA)."
            }
        },
        prescriptionMedicineDosisColor: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            format: {
                pattern: "#([a-fA-F0-9]{6})",
                flags: "i",
                message: "El formato es incorrecto (ej. #000AAA)."
            }
        },
        prescriptionCommentsColor: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            format: {
                pattern: "#([a-fA-F0-9]{6})",
                flags: "i",
                message: "El formato es incorrecto (ej. #000AAA)."
            }
        },
        prescriptionNotValidColor: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            format: {
                pattern: "#([a-fA-F0-9]{6})",
                flags: "i",
                message: "El formato es incorrecto (ej. #000AAA)."
            }
        }
    };

    useEffect(() => {

        const loadConfiguration = async () => {

            try {

                const response = await axios.get(`${API_URL}/configurations/1`);
                const data = response.data;

                // Actualizar state
                data.diet_contact_color && setDietContactColor(data.diet_contact_color);
                data.diet_info_label_color && setDietInfoLabelColor(data.diet_info_label_color);
                data.diet_info_content_color && setDietInfoContentColor(data.diet_info_content_color);
                data.diet_name_color && setDietNameColor(data.diet_name_color);
                data.diet_description_color && setDietDescriptionColor(data.diet_description_color);
                data.diet_allowed_column_header_color && setDietAllowedColumnHeaderColor(data.diet_allowed_column_header_color);
                data.diet_forbidden_column_header_color && setDietForbiddenColumnHeaderColor(data.diet_forbidden_column_header_color);
                data.diet_allowed_color && setDietAllowedColor(data.diet_allowed_color);
                data.diet_allowed_description_color && setDietAllowedDescriptionColor(data.diet_allowed_description_color);
                data.diet_forbidden_color && setDietForbiddenColor(data.diet_forbidden_color);
                data.diet_forbidden_description_color && setDietForbiddenDescriptionColor(data.diet_forbidden_description_color);
                data.diet_note_label_color && setDietNoteLabelColor(data.diet_note_label_color);
                data.diet_note_content_color && setDietNoteContentColor(data.diet_note_content_color);
                data.diet_important_content_color && setDietImportantContentColor(data.diet_important_content_color);
                data.prescription_contact_color && setPrescriptionContactColor(data.prescription_contact_color);
                data.prescription_info_label_color && setPrescriptionInfoLabelColor(data.prescription_info_label_color);
                data.prescription_info_content_color && setPrescriptionInfoContentColor(data.prescription_info_content_color);
                data.prescription_medicine_name_color && setPrescriptionMedicineNameColor(data.prescription_medicine_name_color);
                data.prescription_medicine_description_color && setPrescriptionMedicineDescriptionColor(data.prescription_medicine_description_color);
                data.prescription_medicine_dosis_color && setPrescriptionMedicineDosisColor(data.prescription_medicine_dosis_color);
                data.prescription_comments_color && setPrescriptionCommentsColor(data.prescription_comments_color);
                data.prescription_not_valid_color && setPrescriptionNotValidColor(data.prescription_not_valid_color);

            } catch(error){

                setError('Ocurrió un error cargando la configuración');

                // Mostrar mensaje de error
                setSnackbarMessage('Ocurrió un error cargando la configuración');
                setSnackbarOpen(true);

            }

            // Esconder indicador de carga
            setIsLoading(false);
        };

        loadConfiguration();

    }, []);

    const handleServerError = (error) => {

    };

    // Función que se ejecuta al enviar el formulario
    const handleSubmit = async () => {

        let valid = validate({
            dietContactColor,
            dietInfoLabelColor,
            dietInfoContentColor,
            dietNameColor,
            dietDescriptionColor,
            dietAllowedColumnHeaderColor,
            dietForbiddenColumnHeaderColor,
            dietAllowedColor,
            dietAllowedDescriptionColor,
            dietForbiddenColor,
            dietForbiddenDescriptionColor,
            dietNoteLabelColor,
            dietNoteContentColor,
            dietImportantContentColor,
            prescriptionContactColor,
            prescriptionInfoLabelColor,
            prescriptionInfoContentColor,
            prescriptionMedicineNameColor,
            prescriptionMedicineDescriptionColor,
            prescriptionMedicineDosisColor,
            prescriptionCommentsColor,
            prescriptionNotValidColor
        }, constraints, {fullMessages: false});

        // Si la validación pasó
        if(valid === undefined){

            // Mostrar el indicador de carga
            setIsLoading(true);

            try {

                const formData = {
                    diet_contact_color: dietContactColor,
                    diet_info_label_color: dietInfoLabelColor,
                    diet_info_content_color: dietInfoContentColor,
                    diet_name_color: dietNameColor,
                    diet_description_color: dietDescriptionColor,
                    diet_allowed_column_header_color: dietAllowedColumnHeaderColor,
                    diet_forbidden_column_header_color: dietForbiddenColumnHeaderColor,
                    diet_allowed_color: dietAllowedColor,
                    diet_allowed_description_color: dietAllowedDescriptionColor,
                    diet_forbidden_color: dietForbiddenColor,
                    diet_forbidden_description_color: dietForbiddenDescriptionColor,
                    diet_note_label_color: dietNoteLabelColor,
                    diet_note_content_color: dietNoteContentColor,
                    diet_important_content_color: dietImportantContentColor,
                    prescription_contact_color: prescriptionContactColor,
                    prescription_info_label_color: prescriptionInfoLabelColor,
                    prescription_info_content_color: prescriptionInfoContentColor,
                    prescription_medicine_name_color: prescriptionMedicineNameColor,
                    prescription_medicine_description_color: prescriptionMedicineDescriptionColor,
                    prescription_medicine_dosis_color: prescriptionMedicineDosisColor,
                    prescription_comments_color: prescriptionCommentsColor,
                    prescription_not_valid_color: prescriptionNotValidColor
                };

                const response = await axios.put(
                    `${API_URL}/configurations/1`,
                    formData
                );

                // Mostrar mensaje
                setSnackbarMessage('Configuración actualizada exitosamente');
                setSnackbarOpen(true);

            } catch (error) {

                switch(error.response.status){
                    case 400:
                        // Mostrar mensaje
                        setSnackbarMessage('El contenido enviado no cumplió las reglas de validación');
                        setSnackbarOpen(true);
                        handleServerError(error.response.data.errors);
                        break;
                    case 422:
                        // Mostrar mensaje
                        setSnackbarMessage('El contenido enviado no cumplió las reglas de validación');
                        setSnackbarOpen(true);
                        handleServerError(error.response.data.errors);
                        break;
                    case 404:
                        // Mostrar mensaje
                        setSnackbarMessage('No se encontró el registro');
                        setSnackbarOpen(true);
                        handleServerError(error.response.data.errors);
                        break;
                    default:
                        // Mostrar mensaje
                        setSnackbarMessage('Ocurrió un error actualizando la configuración');
                        setSnackbarOpen(true);
                        break;
                }

            }

            // Esconder indicador de carga
            setIsLoading(false);

        } else {

            // Si existe un error con el campo, mostrarlo
            if(valid.dietContactColor){
                setDietContactColorHasError(true);
                setDietContactColorError(valid.dietContactColor[0]);
            } else {
                setDietContactColorHasError(false);
                setDietContactColorError('');
            }

            if(valid.dietInfoLabelColor){
                setDietInfoLabelColorHasError(true);
                setDietInfoLabelColorError(valid.dietInfoLabelColor[0]);
            } else {
                setDietInfoLabelColorHasError(false);
                setDietInfoLabelColorError('');
            }

            if(valid.dietInfoContentColor){
                setDietInfoContentColorHasError(true);
                setDietInfoContentColorError(valid.dietInfoContentColor[0]);
            } else {
                setDietInfoContentColorHasError(false);
                setDietInfoContentColorError('');
            }

            if(valid.dietNameColor){
                setDietNameColorHasError(true);
                setDietNameColorError(valid.dietNameColor[0]);
            } else {
                setDietNameColorHasError(false);
                setDietNameColorError('');
            }

            if(valid.dietDescriptionColor){
                setDietDescriptionColorHasError(true);
                setDietDescriptionColorError(valid.dietDescriptionColor[0]);
            } else {
                setDietDescriptionColorHasError(false);
                setDietDescriptionColorError('');
            }

            if(valid.dietAllowedColumnHeaderColor){
                setDietAllowedColumnHeaderColorHasError(true);
                setDietAllowedColumnHeaderColorError(valid.dietAllowedColumnHeaderColor[0]);
            } else {
                setDietAllowedColumnHeaderColorHasError(false);
                setDietAllowedColumnHeaderColorError('');
            }

            if(valid.dietForbiddenColumnHeaderColor){
                setDietForbiddenColumnHeaderColorHasError(true);
                setDietForbiddenColumnHeaderColorError(valid.dietForbiddenColumnHeaderColor[0]);
            } else {
                setDietForbiddenColumnHeaderColorHasError(false);
                setDietForbiddenColumnHeaderColorError('');
            }

            if(valid.dietAllowedColor){
                setDietAllowedColorHasError(true);
                setDietAllowedColorError(valid.dietAllowedColor[0]);
            } else {
                setDietAllowedColorHasError(false);
                setDietAllowedColorError('');
            }

            if(valid.dietAllowedDescriptionColor){
                setDietAllowedDescriptionColorHasError(true);
                setDietAllowedDescriptionColorError(valid.dietAllowedDescriptionColor[0]);
            } else {
                setDietAllowedDescriptionColorHasError(false);
                setDietAllowedDescriptionColorError('');
            }

            if(valid.dietForbiddenColor){
                setDietForbiddenColorHasError(true);
                setDietForbiddenColorError(valid.dietForbiddenColor[0]);
            } else {
                setDietForbiddenColorHasError(false);
                setDietForbiddenColorError('');
            }

            if(valid.dietForbiddenDescriptionColor){
                setDietForbiddenDescriptionColorHasError(true);
                setDietForbiddenDescriptionColorError(valid.dietForbiddenDescriptionColor[0]);
            } else {
                setDietForbiddenDescriptionColorHasError(false);
                setDietForbiddenDescriptionColorError('');
            }

            if(valid.dietNoteLabelColor){
                setDietNoteLabelColorHasError(true);
                setDietNoteLabelColorError(valid.dietNoteLabelColor[0]);
            } else {
                setDietNoteLabelColorHasError(false);
                setDietNoteLabelColorError('');
            }

            if(valid.dietNoteContentColor){
                setDietNoteContentColorHasError(true);
                setDietNoteContentColorError(valid.dietNoteContentColor[0]);
            } else {
                setDietNoteContentColorHasError(false);
                setDietNoteContentColorError('');
            }

            if(valid.dietImportantContentColor){
                setDietImportantContentColorHasError(true);
                setDietImportantContentColorError(valid.dietImportantContentColor[0]);
            } else {
                setDietImportantContentColorHasError(false);
                setDietImportantContentColorError('');
            }

            if(valid.prescriptionContactColor){
                setPrescriptionContactColorHasError(true);
                setPrescriptionContactColorError(valid.prescriptionContactColor[0]);
            } else {
                setPrescriptionContactColorHasError(false);
                setPrescriptionContactColorError('');
            }

            if(valid.prescriptionInfoLabelColor){
                setPrescriptionInfoLabelColorHasError(true);
                setPrescriptionInfoLabelColorError(valid.prescriptionInfoLabelColor[0]);
            } else {
                setPrescriptionInfoLabelColorHasError(false);
                setPrescriptionInfoLabelColorError('');
            }

            if(valid.prescriptionInfoContentColor){
                setPrescriptionInfoContentColorHasError(true);
                setPrescriptionInfoContentColorError(valid.prescriptionInfoContentColor[0]);
            } else {
                setPrescriptionInfoContentColorHasError(false);
                setPrescriptionInfoContentColorError('');
            }

            if(valid.prescriptionMedicineNameColor){
                setPrescriptionMedicineNameColorHasError(true);
                setPrescriptionMedicineNameColorError(valid.prescriptionMedicineNameColor[0]);
            } else {
                setPrescriptionMedicineNameColorHasError(false);
                setPrescriptionMedicineNameColorError('');
            }

            if(valid.prescriptionMedicineDescriptionColor){
                setPrescriptionMedicineDescriptionColorHasError(true);
                setPrescriptionMedicineDescriptionColorError(valid.prescriptionMedicineDescriptionColor[0]);
            } else {
                setPrescriptionMedicineDescriptionColorHasError(false);
                setPrescriptionMedicineDescriptionColorError('');
            }

            if(valid.prescriptionMedicineDosisColor){
                setPrescriptionMedicineDosisColorHasError(true);
                setPrescriptionMedicineDosisColorError(valid.prescriptionMedicineDosisColor[0]);
            } else {
                setPrescriptionMedicineDosisColorHasError(false);
                setPrescriptionMedicineDosisColorError('');
            }

            if(valid.prescriptionCommentsColor){
                setPrescriptionCommentsColorHasError(true);
                setPrescriptionCommentsColorError(valid.prescriptionCommentsColor[0]);
            } else {
                setPrescriptionCommentsColorHasError(false);
                setPrescriptionCommentsColorError('');
            }

            if(valid.prescriptionNotValidColor){
                setPrescriptionNotValidColorHasError(true);
                setPrescriptionNotValidColorError(valid.prescriptionNotValidColor[0]);
            } else {
                setPrescriptionNotValidColorHasError(false);
                setPrescriptionNotValidColorError('');
            }
        }

    };

    return (
        <div className={classes.root}>
            {
                isLoading ?
                    <div className={ classes.progress }>
                        <CircularProgress />
                    </div> :
                    error !== '' ?
                        <div className={ classes.errorWrapper }>
                            <WarningIcon color="primary" style={ { marginRight: 8 } }/>
                            <Typography>{ error }</Typography>
                        </div> :
                        <Paper className={classes.paper}>
                            <Toolbar className={classes.padding}>
                                <Typography className={classes.title} variant="h6" id="title">Configuración</Typography>
                            </Toolbar>
                            <div>
                                <div className={classes.padding}>
                                    <Typography variant="subtitle2" className={ classes.subtitle }>Reporte de Dietas</Typography>
                                    <Grid container spacing={3}>
                                        <Grid item sm={6} xs={12}>
                                            <ColorPicker
                                                floatingLabelText="Color información de contacto"
                                                fullWidth
                                                value={dietContactColor}
                                                onChange={(color) => setDietContactColor(color)}
                                                InputProps={{
                                                    value: dietContactColor,
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <OpacityIcon style={{ color: dietContactColor }} />
                                                        </InputAdornment>,
                                                }}
                                                error={dietContactColorHasError}
                                                helperText={dietContactColorError}
                                                style={{marginTop: 20}}
                                            />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <ColorPicker
                                                floatingLabelText="Color etiqueta nombre y fecha"
                                                fullWidth
                                                value={dietInfoLabelColor}
                                                onChange={(color) => setDietInfoLabelColor(color)}
                                                InputProps={{
                                                    value: dietInfoLabelColor,
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <OpacityIcon style={{ color: dietInfoLabelColor }} />
                                                        </InputAdornment>,
                                                }}
                                                error={dietInfoLabelColorHasError}
                                                helperText={dietInfoLabelColorError}
                                                style={{marginTop: 20}}
                                            />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <ColorPicker
                                                floatingLabelText="Color contenido nombre y fecha"
                                                fullWidth
                                                value={dietInfoContentColor}
                                                onChange={(color) => setDietInfoContentColor(color)}
                                                InputProps={{
                                                    value: dietInfoContentColor,
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <OpacityIcon style={{ color: dietInfoContentColor }} />
                                                        </InputAdornment>,
                                                }}
                                                error={dietInfoContentColorHasError}
                                                helperText={dietInfoContentColorError}
                                                style={{marginTop: 20}}
                                            />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <ColorPicker
                                                floatingLabelText="Color nombre de dieta"
                                                fullWidth
                                                value={dietNameColor}
                                                onChange={(color) => setDietNameColor(color)}
                                                InputProps={{
                                                    value: dietNameColor,
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <OpacityIcon style={{ color: dietNameColor }} />
                                                        </InputAdornment>,
                                                }}
                                                error={dietNameColorHasError}
                                                helperText={dietNameColorError}
                                                style={{marginTop: 20}}
                                            />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <ColorPicker
                                                floatingLabelText="Color leyenda de dieta"
                                                fullWidth
                                                value={dietDescriptionColor}
                                                onChange={(color) => setDietDescriptionColor(color)}
                                                InputProps={{
                                                    value: dietDescriptionColor,
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <OpacityIcon style={{ color: dietDescriptionColor }} />
                                                        </InputAdornment>,
                                                }}
                                                error={dietDescriptionColorHasError}
                                                helperText={dietDescriptionColorError}
                                                style={{marginTop: 20}}
                                            />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <ColorPicker
                                                floatingLabelText="Color título permitidos"
                                                fullWidth
                                                value={dietAllowedColumnHeaderColor}
                                                onChange={(color) => setDietAllowedColumnHeaderColor(color)}
                                                InputProps={{
                                                    value: dietAllowedColumnHeaderColor,
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <OpacityIcon style={{ color: dietAllowedColumnHeaderColor }} />
                                                        </InputAdornment>,
                                                }}
                                                error={dietAllowedColumnHeaderColorHasError}
                                                helperText={dietAllowedColumnHeaderColorError}
                                                style={{marginTop: 20}}
                                            />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <ColorPicker
                                                floatingLabelText="Color alimentos permitidos"
                                                fullWidth
                                                value={dietAllowedColor}
                                                onChange={(color) => setDietAllowedColor(color)}
                                                InputProps={{
                                                    value: dietAllowedColor,
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <OpacityIcon style={{ color: dietAllowedColor }} />
                                                        </InputAdornment>,
                                                }}
                                                error={dietAllowedColorHasError}
                                                helperText={dietAllowedColorError}
                                                style={{marginTop: 20}}
                                            />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <ColorPicker
                                                floatingLabelText="Color descripción de alimentos permitidos"
                                                fullWidth
                                                value={dietAllowedDescriptionColor}
                                                onChange={(color) => setDietAllowedDescriptionColor(color)}
                                                InputProps={{
                                                    value: dietAllowedDescriptionColor,
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <OpacityIcon style={{ color: dietAllowedDescriptionColor }} />
                                                        </InputAdornment>,
                                                }}
                                                error={dietAllowedDescriptionColorHasError}
                                                helperText={dietAllowedDescriptionColorError}
                                                style={{marginTop: 20}}
                                            />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <ColorPicker
                                                floatingLabelText="Color título prohibidos"
                                                fullWidth
                                                value={dietForbiddenColumnHeaderColor}
                                                onChange={(color) => setDietForbiddenColumnHeaderColor(color)}
                                                InputProps={{
                                                    value: dietForbiddenColumnHeaderColor,
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <OpacityIcon style={{ color: dietForbiddenColumnHeaderColor }} />
                                                        </InputAdornment>,
                                                }}
                                                error={dietForbiddenColumnHeaderColorHasError}
                                                helperText={dietForbiddenColumnHeaderColorError}
                                                style={{marginTop: 20}}
                                            />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <ColorPicker
                                                floatingLabelText="Color alimentos prohibidos"
                                                fullWidth
                                                value={dietForbiddenColor}
                                                onChange={(color) => setDietForbiddenColor(color)}
                                                InputProps={{
                                                    value: dietForbiddenColor,
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <OpacityIcon style={{ color: dietForbiddenColor }} />
                                                        </InputAdornment>,
                                                }}
                                                error={dietForbiddenColorHasError}
                                                helperText={dietForbiddenColorError}
                                                style={{marginTop: 20}}
                                            />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <ColorPicker
                                                floatingLabelText="Color descripción de alimentos prohibidos"
                                                fullWidth
                                                value={dietForbiddenDescriptionColor}
                                                onChange={(color) => setDietForbiddenDescriptionColor(color)}
                                                InputProps={{
                                                    value: dietForbiddenDescriptionColor,
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <OpacityIcon style={{ color: dietForbiddenDescriptionColor }} />
                                                        </InputAdornment>,
                                                }}
                                                error={dietForbiddenDescriptionColorHasError}
                                                helperText={dietForbiddenDescriptionColorError}
                                                style={{marginTop: 20}}
                                            />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <ColorPicker
                                                floatingLabelText="Color etiqueta notas"
                                                fullWidth
                                                value={dietNoteLabelColor}
                                                onChange={(color) => setDietNoteLabelColor(color)}
                                                InputProps={{
                                                    value: dietNoteLabelColor,
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <OpacityIcon style={{ color: dietNoteLabelColor }} />
                                                        </InputAdornment>,
                                                }}
                                                error={dietNoteLabelColorHasError}
                                                helperText={dietNoteLabelColorError}
                                                style={{marginTop: 20}}
                                            />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <ColorPicker
                                                floatingLabelText="Color contenido notas"
                                                fullWidth
                                                value={dietNoteContentColor}
                                                onChange={(color) => setDietNoteContentColor(color)}
                                                InputProps={{
                                                    value: dietNoteContentColor,
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <OpacityIcon style={{ color: dietNoteContentColor }} />
                                                        </InputAdornment>,
                                                }}
                                                error={dietNoteContentColorHasError}
                                                helperText={dietNoteContentColorError}
                                                style={{marginTop: 20}}
                                            />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <ColorPicker
                                                floatingLabelText="Color nota importante"
                                                fullWidth
                                                value={dietImportantContentColor}
                                                onChange={(color) => setDietImportantContentColor(color)}
                                                InputProps={{
                                                    value: dietImportantContentColor,
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <OpacityIcon style={{ color: dietImportantContentColor }} />
                                                        </InputAdornment>,
                                                }}
                                                error={dietImportantContentColorHasError}
                                                helperText={dietImportantContentColorError}
                                                style={{marginTop: 20}}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Typography variant="subtitle2" className={ [classes.subtitle] } style={{marginTop: 30}}>Reporte de Recetas</Typography>
                                    <Grid container spacing={3}>
                                        <Grid item sm={6} xs={12}>
                                            <ColorPicker
                                                floatingLabelText="Color información de contacto"
                                                fullWidth
                                                value={prescriptionContactColor}
                                                onChange={(color) => setPrescriptionContactColor(color)}
                                                InputProps={{
                                                    value: prescriptionContactColor,
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <OpacityIcon style={{ color: prescriptionContactColor }} />
                                                        </InputAdornment>,
                                                }}
                                                error={prescriptionContactColorHasError}
                                                helperText={prescriptionContactColorError}
                                                style={{marginTop: 20}}
                                            />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <ColorPicker
                                                floatingLabelText="Color etiqueta nombre y fecha"
                                                fullWidth
                                                value={prescriptionInfoLabelColor}
                                                onChange={(color) => setPrescriptionInfoLabelColor(color)}
                                                InputProps={{
                                                    value: prescriptionInfoLabelColor,
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <OpacityIcon style={{ color: prescriptionInfoLabelColor }} />
                                                        </InputAdornment>,
                                                }}
                                                error={prescriptionInfoLabelColorHasError}
                                                helperText={prescriptionInfoLabelColorError}
                                                style={{marginTop: 20}}
                                            />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <ColorPicker
                                                floatingLabelText="Color contenido nombre y fecha"
                                                fullWidth
                                                value={prescriptionInfoContentColor}
                                                onChange={(color) => setPrescriptionInfoContentColor(color)}
                                                InputProps={{
                                                    value: prescriptionInfoContentColor,
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <OpacityIcon style={{ color: prescriptionInfoContentColor }} />
                                                        </InputAdornment>,
                                                }}
                                                error={prescriptionInfoContentColorHasError}
                                                helperText={prescriptionInfoContentColorError}
                                                style={{marginTop: 20}}
                                            />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <ColorPicker
                                                floatingLabelText="Color nombre medicina"
                                                fullWidth
                                                value={prescriptionMedicineNameColor}
                                                onChange={(color) => setPrescriptionMedicineNameColor(color)}
                                                InputProps={{
                                                    value: prescriptionMedicineNameColor,
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <OpacityIcon style={{ color: prescriptionMedicineNameColor }} />
                                                        </InputAdornment>,
                                                }}
                                                error={prescriptionMedicineNameColorHasError}
                                                helperText={prescriptionMedicineNameColorError}
                                                style={{marginTop: 20}}
                                            />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <ColorPicker
                                                floatingLabelText="Color descripción medicina"
                                                fullWidth
                                                value={prescriptionMedicineDescriptionColor}
                                                onChange={(color) => setPrescriptionMedicineDescriptionColor(color)}
                                                InputProps={{
                                                    value: prescriptionMedicineDescriptionColor,
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <OpacityIcon style={{ color: prescriptionMedicineDescriptionColor }} />
                                                        </InputAdornment>,
                                                }}
                                                error={prescriptionMedicineDescriptionColorHasError}
                                                helperText={prescriptionMedicineDescriptionColorError}
                                                style={{marginTop: 20}}
                                            />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <ColorPicker
                                                floatingLabelText="Color dosis medicina"
                                                fullWidth
                                                value={prescriptionMedicineDosisColor}
                                                onChange={(color) => setPrescriptionMedicineDosisColor(color)}
                                                InputProps={{
                                                    value: prescriptionMedicineDosisColor,
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <OpacityIcon style={{ color: prescriptionMedicineDosisColor }} />
                                                        </InputAdornment>,
                                                }}
                                                error={prescriptionMedicineDosisColorHasError}
                                                helperText={prescriptionMedicineDosisColorError}
                                                style={{marginTop: 20}}
                                            />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <ColorPicker
                                                floatingLabelText="Color comentarios"
                                                fullWidth
                                                value={prescriptionCommentsColor}
                                                onChange={(color) => setPrescriptionCommentsColor(color)}
                                                InputProps={{
                                                    value: prescriptionCommentsColor,
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <OpacityIcon style={{ color: prescriptionCommentsColor }} />
                                                        </InputAdornment>,
                                                }}
                                                error={prescriptionCommentsColorHasError}
                                                helperText={prescriptionCommentsColorError}
                                                style={{marginTop: 20}}
                                            />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <ColorPicker
                                                floatingLabelText="Color no válido"
                                                fullWidth
                                                value={prescriptionNotValidColor}
                                                onChange={(color) => setPrescriptionNotValidColor(color)}
                                                InputProps={{
                                                    value: prescriptionNotValidColor,
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <OpacityIcon style={{ color: prescriptionNotValidColor }} />
                                                        </InputAdornment>,
                                                }}
                                                error={prescriptionNotValidColorHasError}
                                                helperText={prescriptionNotValidColorError}
                                                style={{marginTop: 20}}
                                            />
                                        </Grid>
                                    </Grid>
                                    <div className={ classes.buttonWrapper }>
                                        <Button color="secondary" onClick={ handleSubmit }>Actualizar</Button>
                                    </div>
                                </div>
                            </div>
                        </Paper>
            }
            <Snack message={ snackbarMessage } open={ snackbarOpen } handleClose={ () => setSnackbarOpen(false) } />
        </div>
    )
};

export default ConfigurationsEdit;
