import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import validate from 'validate.js'
import Snack from "../../../components/Snack/Snack";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import {NavLink} from "react-router-dom";
import axios from "axios";
import {API_URL} from "../../../config";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    padding: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    title: {
        flex: '1 1 100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backButton: {
        marginRight: 8
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
}));

const CityCreate = () => {

    const classes = useStyles();

    // State de paises
    const [countries, setCountries] = useState([]);

    // State de estados
    const [states, setStates] = useState([]);

    /* *** State de formulario *** */

    // State de nombre
    const [name, setName] = useState('');
    const [nameHasError, setNameHasError] = useState(false);
    const [nameError, setNameError] = useState('');

    const [country, setCountry] = useState('');
    const [countryHasError, setCountryHasError] = useState(false);
    const [countryError, setCountryError] = useState('');

    const [state, setState] = useState('');
    const [stateHasError, setStateHasError] = useState(false);
    const [stateError, setStateError] = useState('');

    /* *** State de formulario *** */

    // State de snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(false);

    // Reglas de validación de formulario
    const constraints = {
        name: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            }
        },
        state: {
            presence: {
                allowEmpty: false,
                message: "Debe de llenar este campo"
            },
            inclusion: {
                within: states.map(state => state.id),
                message: "El valor seleccionado no es válido"
            }
        },
    };

    useEffect(() => {

        // Función que carga los paises
        const loadCountries = async () => {

            // Mostrar indicador de carga
            setIsLoading(true);

            try {

                // Hacer request
                const response = await axios.get(
                    `${API_URL}/countries`,
                    {
                        params: {
                            active: true,
                            sort: 'name',
                            direction: 'asc'
                        }
                    }
                );

                // Actualizar state
                setCountries(response.data);

            } catch(error) {

                // Mostrar mensaje
                setSnackbarMessage('Ocurrió un error cargando los países');
                setSnackbarOpen(true);

            }

            // Esconder indicador de carga
            setIsLoading(false);
        };

        // Cargar paises
        loadCountries();

    }, []);

    const loadStates = async (countryId) => {

        // Mostrar indicador de carga
        setIsLoading(true);

        try {

            // Hacer request
            const response = await axios.get(
                `${API_URL}/states`,
                {
                    params: {
                        active: true,
                        sort: 'name',
                        direction: 'asc',
                        country_id: countryId
                    }
                }
            );

            // Actualizar state
            setStates(response.data);

        } catch(error) {

            // Mostrar mensaje
            setSnackbarMessage('Ocurrió un error cargando los estados');
            setSnackbarOpen(true);

        }

        // Esconder indicador de carga
        setIsLoading(false);
    };

    const handleServerError = (error) => {

        if(error.name){
            setNameHasError(true);
            setNameError(error.name[0]);
        } else {
            setNameHasError(false);
            setNameError('');
        }

        if(error.state_id){
            setStateHasError(true);
            setStateError(error.state_id[0]);
        } else {
            setStateHasError(false);
            setStateError('');
        }
    };

    // Función que se ejecuta al enviar el formulario
    const handleSubmit = async () => {

        // Validar campos
        let valid = validate({ name, state }, constraints, {fullMessages: false});

        // Si la validación pasó
        if(valid === undefined){

            // Mostrar el indicador de carga
            setIsLoading(true);

            try {

                const response = await axios.post(
                    `${API_URL}/cities`,
                    {
                        name,
                        state_id: state
                    }
                );

                // Vaciar el formulario
                resetForm();

                // Mostrar mensaje
                setSnackbarMessage('Ciudad registrada exitosamente');
                setSnackbarOpen(true);

            } catch (error) {

                switch(error.response.status){
                    case 400:
                        // Mostrar mensaje
                        setSnackbarMessage('El contenido enviado no cumplió las reglas de validación');
                        setSnackbarOpen(true);
                        handleServerError(error.response.data.errors);
                        break;
                    case 422:
                        // Mostrar mensaje
                        setSnackbarMessage('El contenido enviado no cumplió las reglas de validación');
                        setSnackbarOpen(true);
                        handleServerError(error.response.data.errors);
                        break;
                    default:
                        // Mostrar mensaje
                        setSnackbarMessage('Ocurrió un error registrando la medicina');
                        setSnackbarOpen(true);
                        break;
                }

            }

            // Esconder indicador de carga
            setIsLoading(false);

        } else {

            // Si existe un error con el campo, mostrarlo
            if(valid.name){
                setNameHasError(true);
                setNameError(valid.name[0]);
            } else {
                setNameHasError(false);
                setNameError('');
            }

            if(valid.state){
                setStateHasError(true);
                setStateError(valid.state[0]);
            } else {
                setStateHasError(false);
                setStateError('');
            }
        }



    };

    // Función que vacia el formulario
    const resetForm = () => {

        // Vaciar campos del formulario
        setName('');
        setState('');
        setCountry('');
        setStates([]);

    };

    const handleCountryChange = (value) => {

        // Actualizar state
        setCountry(value);

        loadStates(value);
    };

    const countriesOptions = countries.map(country => (
        <MenuItem key={country.id} value={country.id}>{ country.name }</MenuItem>
    ));

    const statesOptions = states.map(state => (
        <MenuItem key={state.id} value={state.id}>{ state.name }</MenuItem>
    ));

    return (
        <div className={classes.root}>
            {
                isLoading ?
                    <div className={ classes.progress }>
                        <CircularProgress />
                    </div> :
                    <Paper className={classes.paper}>
                        <Toolbar className={classes.padding}>
                            <IconButton className={ classes.backButton } component={ NavLink } to="/cities">
                                <ArrowBackIcon color="secondary" />
                            </IconButton>
                            <Typography className={classes.title} variant="h6" id="title">Ciudad</Typography>
                        </Toolbar>
                        <div>
                            <div className={classes.padding}>
                                <TextField variant="outlined"
                                    id="name"
                                    label="Nombre"
                                    fullWidth
                                    className={ classes.textField }
                                    value={ name }
                                    onChange={ (event) => setName(event.target.value) }
                                    error={ nameHasError }
                                    helperText={ nameError }
                                />
                                <FormControl className={ classes.formControl } error={ countryHasError }>
                                    <InputLabel id="country_label">País</InputLabel>
                                    <Select
                                        labelId="country_label"
                                        id="country"
                                        MenuProps={MenuProps}
                                        fullWidth
                                        value={country}
                                        onChange={(event) => handleCountryChange(event.target.value)}
                                    >
                                        { countriesOptions }
                                    </Select>
                                    <FormHelperText>{ countryError }</FormHelperText>
                                </FormControl>
                                <FormControl className={ classes.formControl } error={ stateHasError }>
                                    <InputLabel id="state_label">Estado</InputLabel>
                                    <Select
                                        labelId="state_label"
                                        id="state"
                                        MenuProps={MenuProps}
                                        fullWidth
                                        value={state}
                                        onChange={(event) => setState(event.target.value)}
                                    >
                                        { statesOptions }
                                    </Select>
                                    <FormHelperText>{ stateError }</FormHelperText>
                                </FormControl>
                                <div className={ classes.buttonWrapper }>
                                    <Button color="secondary" onClick={ handleSubmit }>Crear</Button>
                                </div>
                            </div>
                        </div>
                    </Paper>
            }
            <Snack message={ snackbarMessage } open={ snackbarOpen } handleClose={ () => setSnackbarOpen(false) } />
        </div>
    )
};

export default CityCreate;
